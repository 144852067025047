export const API_URL = "https://whizzostageapi.appgrowthcompany.com/";
// export const API_URL = "https://whizzodevapi.appgrowthcompany.com/";
// export const API_URL = "https://b15e-2409-40f0-16d-9afe-fdb1-8ca1-e6bc-7638.ngrok-free.app/";

// export const media_url="https://lifefit.appgrowthcompany.com/api/v1//Upload/"

export const END_POINTS = {
  login: "admin/admin-login/",
  upload: "media/upload/",
  get_user: "admin/get-admin-detail/",
  logout: "admin/logout/",
  forgotPassword: "user/forgot-password/",
  verifyOtp: "user/verify-otp/",
  resetPassword: "user/reset-password/",
  changePassword: "user/change-password/",
  

  //upload media 
  uploadMedia:"media/upload/",

  // ////profile
  updateProfile: "admin/update-admin-profile/",
  //  getProfile:"getProfile",


   //manage-users
  getUsers:"admin/get-all-manage-user/",
  getUserById:"admin/get-manage-user/",
  updateUsers:"admin/update-manage-user/",
  changeUserStatus:'admin/update-manage-user-status/',
  deleteUser:"admin/delete-manage-user/",
  exportUsers:'/admin/csv-users',


  //manage purpose
  addPurpose:"admin/add-purpose/",
  getPurposes:"admin/get-all-purpose/",
  getPurposeById:"admin/get-purpose/",
  updatePurposes:"admin/update-purpose/",
  changePurposeStatus:'admin/update-purpose-status-by-id/',
  deletePurpose:"admin/delete-purpose/",

 //manage purpose
 addTestimonial:"admin/add-testimonial/",
 getTestimonials:"admin/get-all-testimonial/",
 getTestimonialById:"admin/get-testimonial/",
 updateTestimonials:"admin/update-testimonial/",
 changeTestimonialStatus:'admin/update-testimonial-status-by-id/',
 deleteTestimonial:"admin/delete-testimonial/",



//cms
getCms:"admin/get-cms-detail/",
updateCmsContact:"admin/add-contact-suport/",
updateCmsTerms:"admin/add-terms-condition/",
updateCmsPrivacy:"admin/add-privacy-policy/",
updateCmsAbout:"admin/add-about-us/",
updateCms:"admin/arabic-cms/",


//faq
getFaq:"admin/get-all-faq/",
addFaq:"admin/add-faq/",
updateFaq:"admin/faq-update/",
deleteFaq:"admin/faq-delete/",



 //subadmin roles
 addsubAdminRole:"admin/add-sub-role/",
 getSubAdminRole:"admin/get-all-sub-role/",
 deleteSubAdminRole:"admin/delete-role/",
 updateSubAdminRole:"admin/update-role/",

 //subadmin
 addSubAdmin:"admin/add-sub-admin/",
 updateSubAdminById:"admin/update-subadmin/",
 getAllSubAdmin:"admin/get-all-subadmin/",
 getSubAdminById:"admin/get-subadmin-by-id/",
 updateSubAdminStatusById:"admin/update-subadmin-status-by-id/",
 deleteSubAdminById:"admin/delete-subadmin/",


  //dashboard
  dashboardData:"admin/get-dashboard-data/",



  //ability
  addAbility:"admin/add-ability/",
  updateAbility:"admin/update-ability/",
  deleteAbility:"admin/delete-ability/",
  getAllAbility:"admin/get-all-ability/",
  getAbilityById:"admin/get-abiity-by-id/",
  importAbilityPdf:"admin/ability/pdf/",
  


  //subject 
  addSubject:"admin/add-subject/",
  getSubject:"admin/get-all-subject/",
  editSubject:"admin/update-subject/",
  deleteSubject:"admin/delete-subject/",


   //ability
   addAchievement:"admin/add-achievement/",
   updateAchievement:"admin/update-achievement/",
   deleteAchievement:"admin/delete-achievement/",
   getAllAchievement:"admin/get-all-achievement/",
   getAchievementById:"admin/get-achievement-by-id/",

   
   //notification
   getNotification:"admin/all-notifications/",
  addNotification:"admin/add/notification/",
  deleteNotification:"admin/delete-notification/",
  getUserListForNotification:"admin/users-listing-for-notification/",

  //customer support
  getAllQueries:"admin/all-queries/",
  revertQuery:"admin/revert-query/",
  deleteQuery:"admin/delete-query/",
  exportCustomerQueries:"admin/customers-queries-csv/",

  //get-dashboard-user-graph-data
  // getUserGraphData:"admin/get-dashboard-user-graph-data/",
  // getRevenueGraph:"admin/get-dashboard-revenue-graph-data/",
  // getSubscriptionGraph:"admin/get-dashboard-subscription-graph-data/",
exportAnalyics:"admin/users-graph-csv/",
  //subscription feature
  feature:"feature",

  //subscription
  subscription:"subscriptionPlan",

  //questionaire
  survey:"nutritionTracker",

  //analytics
  userGraph:"userGraph",
  totalRevenuGraph:"totalRevenuGraph",
  totalSubscriptionGraph:"totalSubscriptionGraph",
  
 

  //exports
  subscriptionExports:"subscriptionExports",
  earningExports:"earningExports",

  //notifications

  //recieved notification
  adminNotification:"adminNotification",

  exercise:"workoutRoutine",
};
