import React, { useEffect, useRef, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Dialog, DialogActions, DialogContentText, DialogTitle,  Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Undo } from "@mui/icons-material";
import WarnModal from "../../components/modals/WarnModal";
import { isDeleteAllowed, isEditAllowed } from "../../utils/permissonAllowed";
import { showError, showToast } from "../../constants/toast";
import { useGetCustomersMutation, useDeleteCustomerByIdMutation, useRevertCustomerQueryMutation, useGetCustomerCSVMutation } from "../../services/customerSupport";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Pagination } from "../../components";
import { CustomerSupport as CustomerSupportType } from "../../types/customerSupport";
import { handleDelete } from "../../utils/commonFunctions";
import { isValidInput } from "../../utils/validations";
import Loader from "../../helpers/Loader";
import LoadingBar from "react-top-loading-bar";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box component="span">
            <Typography component="span">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
const CustomerSupport = () => {
    const[dialogValue,setDialogValue]=useState(false);
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    const navigate = useNavigate();
    function createData1(
      userName: string,
      customerId: string,
      email: string,
      queries:string
    ) {
      return {
        userName,
        customerId,
        email,
        queries
      };
    }
    const label1 = { inputProps: { "aria-label": "Switch demo" } };
    const rows1 = [
      createData1(
        "Rehmaan",
        "97862378",
        "rehmaanhussiand7@gmail.com",
        "How to sponser your sites guys"
      ),
    ];
    function createData2(
        userName: string,
        customerId: string,
        email: string,
        queries:string
      ) {
        return {
          userName,
          customerId,
          email,
          queries
        };
      }
      const label2 = { inputProps: { "aria-label": "Switch demo" } };
      const rows2 = [
        createData2(
          "Abdul67",
          "12378678",
          "abdulhussian67@gmail.com",
          "How to meet u guys"
        ),
      ];
    const ref:any = useRef(null)
  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [customer, setCustomer] = useState<CustomerSupportType[]>([]);
  const [revertText,setRevertText]=useState("");
  const [getCustomer] = useGetCustomersMutation();
 const [deleteById] = useDeleteCustomerByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

const[revertQuery]=useRevertCustomerQueryMutation();
  const handleRevertQuery=async()=>{
    try{
      const body={
        answer:revertText
      }
      console.log(body,"body");
      
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody
      const response=await revertQuery({id:selectedId,body:encryptedBody}).unwrap();
      if(response.status==200)
        {
          setDialogValue(false);
          setRevertText("")
          setSelectedId(0);
          showToast("Query reverted successfully")
          getCustomerList();
        }
    }
    catch(error:any)
    {
      showError("Revert unsuccessful")
      console.log(error);
      
    }
  }
  const [getUserCsvMethod] = useGetCustomerCSVMutation();
  const handleExportCsv = async () => {
    try {
      const body={
        start:page,
        length:10000,
        search:debouncedSearchTerm.trim(),
        reverted_back:Boolean(value)
      }
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const res = await getUserCsvMethod(encryptedBody).unwrap();
      if (res?.status === 200) {
        window.open(res?.file_urls|| "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };
  const getCustomerList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const body={
        start:page,
        length:10,
        search:debouncedSearchTerm.trim(),
        reverted_back:Boolean(value)
      }
      console.log(body);
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getCustomer(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response);
        setCustomer(response?.data?.response_object || []);
        setTotalCount(response?.data?.total_records);
      } else {
        setCustomer([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };
  useEffect(()=>{
    getCustomerList();
  },[page,debouncedSearchTerm,value])
useEffect(()=>{
setDebouncedSearchTerm("");
setSearchTerm("");
},[value])
  const pageData= <form>
  <Box className="cards_header">
  <SearchBar
searchTerm={searchTerm}
setDebouncedSearchTerm={setDebouncedSearchTerm}
value={searchTerm}
onCross={() => setSearchTerm("")}
onChange={(val: any) => {
  if (isValidInput(val.target.value)) {
    setSearchTerm(val.target.value);
  }
}}
/>
<Box className="cards_header_right">
<Button className="btn btn_primary" onClick={()=>handleExportCsv()}>
  <FileDownloadIcon />Export CSV
</Button>
</Box>
</Box>
<TableContainer className="table_container">
<Box className="heading"></Box>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
  <TableRow>
    <TableCell align="center">S.No</TableCell>
    <TableCell>Customer Id</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Email</TableCell>
    <TableCell>Query</TableCell>
    {value==1 && <TableCell>Revert Message</TableCell>}
    {(isDeleteAllowed(9)||isEditAllowed(9)) &&<TableCell>Actions</TableCell>}
  </TableRow>
</TableHead>
<TableBody>
  {customer?.length ? customer.map((row:CustomerSupportType, i:number) => (
    <TableRow key={row.id}>
      <TableCell align="center">{(page-1)*10+i+1}</TableCell>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.username}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.queries}</TableCell>
      {value==1 && <TableCell>{row.answer}</TableCell>}
     {(isDeleteAllowed(9)||isEditAllowed(9)) && <TableCell>
        <Box className="table_actions">
          {isEditAllowed(9) && 
        value==0 && <Tooltip title="Revert">
            <IconButton
              onClick={() =>{ 
                setRevertText("");
                setSelectedId(row.id)
                setDialogValue(!dialogValue)}}
            >
              <Undo />
            </IconButton>
         </Tooltip>
          }
          {isDeleteAllowed(9) && 
          <Tooltip title="Delete">
            <IconButton onClick={()=>{
              setSelectedId(row.id)
              setOpen(true)}}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          }
        </Box>
      </TableCell>}
    </TableRow>
  )):<TableRow><TableCell align="center" colSpan={10}>
    No Queries Found</TableCell></TableRow>}
</TableBody>
</Table>
</TableContainer>

  </form>;
    return (
      <>
        <div className="main_loyout">
          <Loader isLoad={isLoading}/>
          <LoadingBar ref={ref} color="#7048c1" shadow={true} />
          <div className="dashboard">
            <h1 className="mn_hdng">Customer Support</h1>
          </div>
          <Dialog
            open={dialogValue}
            onClose={()=>setDialogValue(false)}
            >
          <DialogTitle>Revert Query</DialogTitle>
          <DialogContentText>
            <TextField
            hiddenLabel
            name='revert'
            className="text_field"
            style={{margin:"10px",width:"320px"}}
            value={revertText}
            multiline
            onChange={(e)=>{
              if(e.target.value==" ") return;
              setRevertText(e.target.value)}}
            />

          </DialogContentText>
          <DialogActions>
            <Button className='btn btn_primary' style={{padding:"0px",height:"40px"}} onClick={()=>setDialogValue(false)}>CANCEL</Button>
            <Button className='btn btn_primary' style={{padding:"0px",height:"40px"}} onClick={()=>{
              handleRevertQuery();
            }} >SEND</Button>
  
          </DialogActions>
        </Dialog>
          <Card className="cards">
          
            <Box className="custom_tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Queries Received" {...a11yProps(0)} />
  
                <Tab label="Reverted Queries" {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel value={value} index={0}>
               {pageData}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
              {pageData}
              </CustomTabPanel>
           
              
            </Box>
          </Card>
          <Pagination
          module={customer}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
          <WarnModal
        setOpen={setOpen}
        open={open}
        name={"query"}
        handleDelete={() => handleDelete(deleteById, selectedId, getCustomerList)}
      />
        </div>
      </>
    );
}

export default CustomerSupport