import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import WarnModal from "../../../components/modals/WarnModal";
import { Cancel, Camera } from "@mui/icons-material";
import { useFormik } from "formik";
import { showError } from "../../../constants/toast";
import { errorToast, successToast, getFromStorage, STORAGE_KEYS } from "../../../helpers";
import Loader from "../../../helpers/Loader";
import { usePutUpdateProfileMutation } from "../../../services/profile";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { UploadMedia } from "../../../utils/mediaUpload";
import * as Yup from "yup";
import { useEditUserByIdMutation, useLazyGetUsersByIdQuery } from "../../../services/user";
import { isEnglishString } from "../../../utils/validations";
import { Subject } from "../../../types/subject";
import { useGetPurposesMutation } from "../../../services/purpose";
import { PurposeResponse } from "../../../types/purpose";
const UsersForm = () => {
    const navigate = useNavigate();
    const [updateprofile] :any= useEditUserByIdMutation();
    const [error,setError]=useState(false);
    const [country_code, setPhoneCode] = useState("+91");
    const [profile_picture, setProfilePicture] = useState<any>(null);
    console.log(profile_picture);
    const [imageId, setImageId] = useState<any>(null);
    const[phoneDisplay,setPhoneDisplay]=useState("");
    const[isLoading,setIsLoading]=useState(false);
    const[selectedPurpose,setSelectedPurpose]=useState<any>(0);
    const{id}=useParams();
    const [purpose, setPurpose] = useState<PurposeResponse[]>([]);
    const [getPurpose] = useGetPurposesMutation();
    const getPurposeList = async () => {
      setIsLoading(true);
      // ref?.current?.continuousStart()
  
      try {
        const body={
          start:1,
          length:1000,
          search:"",
        }
        console.log(body);
        const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
        const response = await getPurpose(encryptedBody).unwrap();
        if (response?.status === 200) {
          console.log(response);
          setPurpose(response?.data?.response_object || []);
        } else {
          setPurpose([]);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
      setIsLoading(false);
  
    };
    const handleImageUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const files = event.target.files;
      const file = files?.length ? files[0] : null;
  
      try {
        if (!file) {
  
          formik.setFieldValue("profile_picture", "");
          setProfilePicture("");
          setImageId(null);
          return;
        }
        const allowedExtensions = ["png","jpg","jpeg"];
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        console.log("sssssssssssssssssssss",fileExtension);
        if (!allowedExtensions.includes(fileExtension||"")) {
           setIsLoading(false);
          showError("Invalid file format: only png, jpg images are allowed");
          return;
        }
        setIsLoading(true);
        const res = await UploadMedia(file);
        
        if (res?.status=== 200) {
          console.log(res?.data[0])
          formik.setFieldValue("profile_picture", res?.data[0]?.media_url);
          setProfilePicture(res?.data[0]?.media_url);
          console.log("jjjjjjjjjjjjjjj",res?.data[0]?.id);
          setImageId(res?.data[0]?.id)
        } else {
          errorToast(res?.message);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        errorToast("Error uploading image. Please try again.");
      }
      setIsLoading(false);
    };
    console.log("iiiiiiiiiiiiiiiiiiiiii",imageId);
  //console.log(user);
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        email:"",
        firstName:"",
        lastName:"",
        phone_no:"",
        profile_picture:"",
        address:"",
        country_code:"+91",
  
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .required("Email is required!")
          .matches(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            "Enter a valid email address!"
          ),
        firstName: Yup.string()
          .required("First Name is required")
          .min(2, "Minimum 2 characters are required")
          .max(80, "Maximum 60 characters are allowed"),
         lastName: Yup.string()
          .required("First Name is required")
          .min(2, "Minimum 2 characters are required")
          .max(80, "Maximum 60 characters are allowed"),
        phone_no: Yup.string()
          // .required("Phone number is required")
          .min(6, "Phone number must be at least 6 digits")
          .max(16, "Phone number must be at least 16 digits"),
        // address: Yup.string()
        //   .required("Address is required")
        //   .max(100, "Maximum 100 characters are allowed")
  
  
  
  
      }),
      onSubmit: async (values, { setSubmitting }) => {
        if (!formik.isValid) return;
        try {
         setSubmitting(true);
  
          let body:any = {
            // email: values.email,
            first_name: values?.firstName,
            last_name:values?.lastName,
            phone_no: values?.phone_no,
            country_code: country_code,
            profile_picture: imageId,
            purpose:selectedPurpose
            };
          console.log('body :', body);
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateprofile({id:id,body:encryptedBody}).unwrap();
          if (response?.status === 200) {

            successToast("User details updated successfully.");
            navigate('/manage-users')
          }
        } catch (error: any) {
          errorToast("error");
        } finally {
          setSubmitting(false);
        }
      },
  
  
    });
    
    const[getDataById]=useLazyGetUsersByIdQuery();
    const getDataByIdList=async()=>{
      try{const response=await getDataById({id}).unwrap();
      if(response.status==200)
        {
         formik.setFieldValue('firstName',response?.data?.first_name);
         formik.setFieldValue('lastName',response?.data?.last_name);
         formik.setFieldValue('email',response?.data?.email);
         formik.setFieldValue('lastName',response?.data?.last_name);
         formik.setFieldValue('phone_no',response?.data?.phone_no);
         formik.setFieldValue("profile_picture", response?.data?.profile_picture?.media_url);
         setPhoneCode(response?.data?.country_code);
         setPhoneDisplay(response?.data?.country_code+response?.data?.phone_no)
         setSelectedPurpose(response?.data?.purpose?.id)
         console.log(response?.data);
         setImageId(response?.data?.profile_picture?.id)
        }}
        catch(error){
          console.log(error);
        }
    }
    useEffect(()=>{
      if(id)
        getDataByIdList();
      getPurposeList();
    },[id])
  
    const handleChangePhone = (phone_no: any, country: any) => {
      formik.setFieldValue(
        "phone_no",
        phone_no.replace(country.dialCode, "")
      );
      setPhoneCode(country.dialCode);
      setPhoneDisplay(phone_no);
    };
    // useEffect(() => {
    //   setPhoneCode(user?.country_code || "");
    //   setProfilePicture(user?.profile_picture?.media_url || "");
    //   setPhoneDisplay(user?.country_code+user?.phone_no||"");
    // }, [user]);
  

  return (
    <div className="main_layout">
    <Loader isLoad={isLoading}/>
    {/* <div className="dashboard">
    <h1 className="mn_hdng" style={{
      fontSize: "20px",
    fontWeight: "800",
    letterSpacing: "0.5px"}}>Edit Profile</h1>
    </div> */}
    <div className="dashboard" style={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
          <h1 className="mn_hdng" style={{
      fontSize: "20px",
    fontWeight: "800",
    letterSpacing: "0.5px"}}>Edit Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-users");
            }}
          >
            Back
          </Button>
        </div>
    <Card className="cards">
      <form onSubmit={(e)=>{
        
        // if(!selectedPurpose)
        //   return;
        formik.handleSubmit(e)}}>
        <CardContent sx={{ p: 1 }}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
                <Typography className="custom_label">Image</Typography>
                {formik.values.profile_picture ? (
                  <div className="upload_image_preview">
                    <CardMedia
                      component="img"
                      image={formik.values.profile_picture}
                      alt="photo"
                    />
                    <Cancel
                      onClick={(e) => {
                        e.preventDefault();
                        setProfilePicture("");
                        formik.setFieldValue("profile_picture", "");
                      }}
                    />
                  </div>
                ) : (
                  <Box className="upload_image">
                    <label htmlFor="icon-button-file">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        onChange={handleImageUpload}
                      />
                      <Button component="span" className="upload_image_btn">
                        <img
                          src="/static/images/user_placeholder.png"
                          alt=""
                        />
                        <Camera />
                      </Button>
                    </label>
                  </Box>
                )}
              </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">First name</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="firstName"
                variant="outlined"
                fullWidth
                placeholder="First Name"
                value={formik.values.firstName}
                onChange={(e)=>{
                  if(!isEnglishString(e.target.value))
                    {
                      showError("Only alphabets allowed")
                      return
                    }
                  if(e.target.value.length<=50)
                  formik.handleChange(e);
                else
                showError("First name can't exceed 50 characters")
                }
              }
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={(formik.touched.firstName && formik.errors.firstName && typeof formik.errors.firstName === 'string') && formik.errors.firstName}

              />
             
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Last name</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="lastName"
                variant="outlined"
                fullWidth
                placeholder="Last Name"
                value={formik.values.lastName}
                onChange={(e)=>{
                  if(!isEnglishString(e.target.value))
                    {
                      showError("Only alphabets allowed")
                      return
                    }
                  if(e.target.value.length<=50)
                  formik.handleChange(e);
                else
                showError("Last name can't exceed 50 characters")
                }
              
              }
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={(formik.touched.lastName && formik.errors.lastName && typeof formik.errors.lastName === 'string') && formik.errors.lastName}

              />
             
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Email</Typography>
              <TextField
                hiddenLabel
                type="email"
                name="email"
                disabled
                variant="outlined"
                fullWidth 
                onMouseOver={(e:any)=>{
                  e.target.style.cursor = 'not-allowed';
                }}
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={(formik.touched.email && formik.errors.email && typeof formik.errors.email === 'string') && formik.errors.email}
             
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Purpose
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        sx={{backgroundColor:"white",maxHeight:'53px'}}
                        // className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedPurpose}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={(e)=>setSelectedPurpose(e.target.value)}
                      >
                        <MenuItem value={0} disabled>
                          Select
                        </MenuItem>
                      {
                        purpose?.map((data:PurposeResponse)=><MenuItem value={data.id}>
                        {data.name}
                      </MenuItem>)
                      }
                      </Select>
                    </FormControl>
                    {error && !selectedPurpose && (
<Typography variant="caption" color="error">
Purpose is required
</Typography>
)}
                  </Grid>
            {/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Address</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="address"
                variant="outlined"
                fullWidth
                placeholder="Address"
                value={formik.values.address}
                onChange={(e)=>{
                  if(e.target.value.length<=100)
                  formik.handleChange(e);
                else
                showError("Address cant exceed 100 characters")

                }}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={(formik.touched.address && formik.errors.address && typeof formik.errors.address === 'string') && formik.errors.address}
              /> */}
              {/* {formik.touched.address && formik.errors.address && (
                <Typography variant="caption" color="error">
                  {formik.errors.address}
                </Typography>
              )} */}
            {/* </Grid> */}
          <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Phone number</Typography>
              <PhoneInput
country={"us"}
placeholder="0 (000) 000-000"
enableSearch={true}
inputStyle={{ width: "100%" }}
value={phoneDisplay}
disabled
// onChange={(value) => formik.setFieldValue("phone_no", value)}
onChange={(phone_no, country) =>
handleChangePhone(phone_no, country)
}
onBlur={formik.handleBlur}
// isValid={!(formik.touched.phone_no && formik.errors.phone_no)}
/>
{formik.touched.phone_no && formik.errors.phone_no && (
<Typography variant="caption" color="error">
Phone number is required
</Typography>
)}

            </Grid> 
          </Grid>
          <div className="form_btn">
            <Button size="large" type="submit" className="btn btn_primary" onClick={()=>{
              setError(true);
            }}>
              Save
            </Button>
          </div>
        </CardContent>
      </form>
    </Card>
  </div>

);
};
export default UsersForm;
