//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    status: number;
    message: string;
};
type GetAllRoleResponse = {
    role: any;
    count: number;
  };
type RoleResponse={
    _id:string,
    name:string,
    image:string,
    isBlocked:string
}
type GetTokenParams = {
    // limit?: number;
    // page?: number;
    // query?: string;
  };

type EncryptedBody = {
  hash: string;
  sek: string;
} | null
export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getRoles:builder.query<
CommonResponseType & {data:any},
    GetTokenParams>({
      
        // query:({page,limit,query})=>{
        query:()=>{
          let url = END_POINTS.getSubAdminRole;
        // const queryParams = [];
        // if (page) {
        //   queryParams.push(`page=${page}`);
        // }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        // if (queryParams.length > 0) {
        //   url += `?${queryParams.join('&')}`;
        // }
          return{
            url:url,
            method:'GET',}
        }
}),

addRole: builder.mutation<
CommonResponseType & { data: RoleResponse },
CommonBody
>({
query: (body) => ({
  url: END_POINTS.addsubAdminRole,
  method: "POST",
  body,
})
}),

deleteRoleById:builder.mutation<
CommonResponseType & {data:RoleResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteSubAdminRole}${id}/`,
        method:'DELETE'
    })
}),
updateRoleById:builder.mutation<
CommonResponseType & {data:RoleResponse},
{id:string,body:any}
>({
    query:({id,body})=>({
        url:`${END_POINTS.updateSubAdminRole}${id}/`,
        method:'PUT',
        body
    })
}),
})
})

export const{
 useLazyGetRolesQuery,
 useAddRoleMutation,
 useDeleteRoleByIdMutation,
 useUpdateRoleByIdMutation
}=userApi;
