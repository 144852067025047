import { Padding } from '@mui/icons-material';
import React from 'react';

function PageNotFound() {
  const containerStyle = {
    display: 'block',
    textAlign: 'center',
    backgroundColor: '#8B62E6',
    color: 'white',
    padding:"10%"
  };

  const headingStyle = {
    
    fontSize: '2rem',
    backgroundColor:"8B62E6",
    marginBottom: '1rem',
  };

  return (
    //@ts-ignore
    <div style={containerStyle}>
      <h2 style={headingStyle}>404 - Page Not Found 😮</h2>
      <div><p>This page doesn't exist. Please check the URL or navigate to a different page.</p></div>
    </div>
  );
}

export default PageNotFound;
