import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useRef, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";
import { showError, showToast } from "../../constants/toast";
import { useAddSubAdminMutation, useEditSubAdminByIdMutation, useLazyGetSubAdminByIdQuery } from "../../services/subadmin";
import { useDeleteRoleByIdMutation, useAddRoleMutation, useLazyGetRolesQuery } from "../../services/subadminroles";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { UploadMedia } from "../../utils/mediaUpload";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { errorToast } from "../../helpers/toast";
import Loader from "../../helpers/Loader";
import PhoneInput from "react-phone-input-2";
import { isEnglishString, isValidEmail } from "../../utils/validations";
import LoadingBar from "react-top-loading-bar";
import { ModeEdit } from "@mui/icons-material";
import EditSubAdminRole from "../../components/editSubAdminRole";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span">
          <Typography component="span" >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Role {
  name: string;
  enabled: boolean;
}
const AddSubAdmin = () => {
  const ref:any = useRef(null)

  const[imageId,setImageId]=useState(0);
  const{id}=useParams();
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const[newRole,setNewRole]=useState("");
  const [rolesData, setRolesData] = useState<Role[]>([]);
  const[error,setError]=useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [value, setValue] = useState<number>(id?1:0);
  const[name,setName]=useState("");
  const[email,setEmail]=useState("");
  const[isLoading,setIsLoading]=useState(false);
  const[selectedRole,setSelectedRole]=useState<any>("");
  const[phone,setPhone]=useState("");
  const[phoneDisplay,setPhoneDisplay]=useState("");
  const[phoneCode,setPhoneCode]=useState("");
  const[open,setOpen]=useState(false);
  const[openEditRole,setOpenEditRole]=useState(false);
  const handleCloseEditRole=()=>{
    setOpenEditRole(false);
  }
  const[selectedId,setSelectedId]=useState(0);
  const[deleteById]=useDeleteRoleByIdMutation();
  const[addRole]=useAddRoleMutation();
  const[addSubAdmin]=useAddSubAdminMutation();
  const[selectedRoleData,setSelectedRoleData]=useState({
    selectedRoleName:"",
    selectedRoleId:0
  })
  const [permissions,setPermissions]=useState([
    {
     module:1,
      label:"Manage Users",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },{
      module:2,
      label:"Manage Testimonials",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },{
     module:3,
      label:"Manage Purpose",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },{
      module:4,
      label:"Manage Abilities",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },{
      module:5,
      label:"Manage Achievement",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },{
   module:6,
      label:"Manage Subscription",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },{
      module:7,
      label:"Manage Payment",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },{
      module:9,
      label:"Notifications Management",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },
    {
     module:10,
      label:"Manage CMS",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },
    {module:11,
      label:"Manage Customer Support",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },
    {
      module:12,
      label:"Analytics",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    },
    {module:13,
      label:"Manage FAQ",
      can_add_edit:false,
      can_view:false,
      can_be_delete:false,
    }
    
  
   ])
  // console.log(phone,phoneDisplay,phoneCode);
  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };
  const [editSubAdminById]=useEditSubAdminByIdMutation();
  const[getSubAdminById]=useLazyGetSubAdminByIdQuery();

  const getSubAdminByIdData=async()=>{
    try{
      setIsLoading(true);
    const response=await getSubAdminById({id:id}).unwrap();
    if(response.status==200)
      {
        console.log("asdsaas",response);
        setName(response?.data?.name);
        setEmail(response?.data?.email);
        setPhone(response?.data?.phone_no);
        setPhoneCode(response?.data?.country_code);
        setPhoneDisplay(response?.data?.country_code+response?.data?.phone_no);
        setImage(response?.data?.profile_picture?.media_url);
        setImageId(response?.data?.profile_picture?.id);
        console.log(response?.data?.subadminRole?._id);
        setSelectedRole(response?.data?.sub_role?.id);
        setPermissions((prevData:any)=>{
          return prevData.map((data:any,i:number)=>{
            console.log("TEEST",response?.data?.role_permission?.[i]?.module);
            console.log("TEEST",data?.label);
            const temp=response?.data?.role_permission?.find((data2:any)=>data2.module?.trim()?.toLowerCase()==data.label?.trim()?.toLowerCase());
            if(temp)
            {
              return {
                id:temp?.id,
                label:temp?.module,
                module:data?.module,
                can_add_edit:temp?.can_add_edit,
                can_be_delete:temp?.can_be_delete,
                can_view:temp?.can_view,
              }
            }
            return data;
          })
        })
      }
    }
    catch(error:any){
      showError(error.data.message);
    }
    setIsLoading(false);

  }
  const handleRoleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };
//   const handleStatusChange=async(index:number)=>{
//     try {
//       const body = {
//       isBlocked:!rolesData[index]?.isBlocked
//       };
//       console.log(body);
//       const encryptedData=generateEncryptedKeyBody(body);
//       const response = await rolesDataStatus({ id: `${rolesData[index]._id}` , body:encryptedData}).unwrap();
//       showToast(response?.message || "Role Updated Successfully");
//       setRolesData((prevData:any)=>{
//         let temp=[...prevData];
//         temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
//         return temp;
//       });
//     } catch (error: any) {
//       showError(error?.data?.message || "");
// //      console.log(error);
//     }
//   }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if(!file)
      {
        showError("Invalid file")
          setIsLoading(false);
       
        return;
      }
      const allowedExtensions = ["png","jpg","jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss",fileExtension);
      if (!allowedExtensions.includes(fileExtension||"")) {
        setIsLoading(false);
        showError("Invalid file format: only png, jpg images are allowed");
        return;
      }
    const res = await UploadMedia(file);
    if (res?.status=== 200) {
      console.log(res?.data[0])
      setImage(res?.data[0]?.media_url);
      console.log("jjjjjjjjjjjjjjj",res?.data[0]?.id);
      setImageId(res?.data[0]?.id)
      showToast("Image uploaded successfully");
    } else {
      errorToast(res?.message);
    }
    setIsLoading(false);

  };
  const handleChangePhone = (phone: any, country: any) => {
    setPhone(phone.replace(country.dialCode, ""));
    setPhoneCode(country.dialCode);
    setPhoneDisplay(phone);
  };
 
  const[getRoles]=useLazyGetRolesQuery();

  const getRolesList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const response = await getRoles({
        // page: page,
        // limit: 10,
        // query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.status === 200) {
        console.log("ROLE",response);
        setRolesData(response?.data|| []);
        // setTotalCount(response?.data?.count);
      } else {
        setRolesData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };
  const handleAddUpdateSubAdmin=async()=>{
try{
  if(!isValidEmail(email))
    {
      showError("Please enter valid email address")
      return;
    }
    if(parseInt(phone)==0)
      {
        showError("Please enter valid phone number")
        return;
      }
  const body={
    profile_picture:imageId?imageId:null,
    sub_role:selectedRole,
    name:name,
    email:email,
    phone_no:phone,
    country_code:phoneCode.includes("+")?phoneCode:"+"+phoneCode,
    role_permission:permissions
  }
  console.log('body :', body);
  const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
  const response=(id ?await editSubAdminById({id:id,body:encryptedBody}).unwrap() :await addSubAdmin(encryptedBody).unwrap());
  if(response.status==200)
  {
    showToast("Sub-Admin "+(id?"updated":"added" )+" succesfully");
    navigate('/manage-subadmin');
}
}
catch(error:any)
{
  showError(error.data.message);
}
  }
  

  const handleAddRole = async(event: React.FormEvent) => {
    event.preventDefault();
    if(newRole=="")
    {
      showError("Role name is required");
      return;
    }
    if(newRole.length>60)
      {
        showError("Role name cant exceed 60 characters");
      }
    
    const body={
      role_name:newRole,
    }
    const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
    try{
    const response=await addRole(encryptedBody).unwrap();
    if(response.status==200)
    {
      showToast("Role added successfully");
      getRolesList();
      setPermissions((prevData:any)=>{
        return prevData.map((row:any)=>{
          return {...row,can_view:false,can_be_delete:false,can_add_edit:false}
        })
      })
      setNewRole("");  
    }
    }
    catch(error:any){
      showError(error.data.message);
    }
  
  };

  React.useEffect(()=>{
    if(id)
    getSubAdminByIdData();
  },[])
  React.useEffect(()=>{
getRolesList();
  },[])

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
    <Loader isLoad={isLoading}/>
    <LoadingBar color="#7048c1" ref={ref} shadow={true}  />

      <>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{id?"Edit":"Add"} Sub-Admin</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Box className="flx_sc">
               {!id && <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Add Role" {...a11yProps(0)} />
                  <Tab label={id?"Edit":"Add" +" Sub-admin"} {...a11yProps(1)} />
                </Tabs>}
              </Box>
              <CustomTabPanel value={value} index={0} >
              {
              <form onSubmit={handleAddRole}>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Add Role
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          value={newRole}
                          fullWidth
                          placeholder="Add Role"
                          onChange={(event) =>{
                            if(event.target.value==" ") return;
                            if(event.target.value.length<=60)
                            handleRoleDataChange(event as any)
                          if(event.target.value.length>60)
                            showError("Role name cant exceed 60 characters");
                          }
                          }
                        />
                      </Grid>
                    </Grid>
 <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                      >
                        Add
                      </Button>
                    </div>
                  </CardContent>
                </form>}
                {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell>Enabled</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rolesData.map((role, index) => (
                        <TableRow key={index}>
                          <TableCell>{role.name}</TableCell>
                          <TableCell>
                            <Switch
                              checked={role.enabled}
                              onChange={() => handleToggle(index)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}

<Grid item xs={12}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="mn_hdng">S.No.</TableCell>
                          <TableCell className="mn_hdng">Role</TableCell>
                          {/* <TableCell className="mn_hdng">Status</TableCell> */}
                          <TableCell className="mn_hdng">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      {rolesData?.length ? (
                        rolesData?.map((row:any, i:any) => (
                          <TableBody>
                            <TableRow>
                              <TableCell className="mn_hdng">{i+1}</TableCell>
                              <TableCell>{row.role_name}</TableCell>
                              {/* <TableCell>
                                <Switch
                                  checked={!row.isBlocked}
                                  // checked={row.enabled}
                                  onChange={()=>handleStatusChange(i)}
                                  // onChange={() => handleToggle(index)}
                                />
                              </TableCell> */}
                              <TableCell>
                              <Box className="table_actions">
                                <Tooltip title="Edit">
                                  <IconButton onClick={()=>{
                                  setSelectedRoleData({selectedRoleId:row.id,selectedRoleName:row.role_name})
                                  setOpenEditRole(true);
                                  }}>
                                    <ModeEdit/>
                                  </IconButton>
                                </Tooltip>
                               <Tooltip title="Delete">
                                  <IconButton onClick={()=>{
                                    setSelectedRole("");
                                    setSelectedId(row.id);
                                    setOpen(true);
                                  }}>
                                    <DeleteIcon />
                                  </IconButton>
                               </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      ) : (
                        <TableBody
                          sx={{
                            padding: "20px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <TableRow

                          // sx={{ padding: "20px", textAlign: "center" }}
                          >
                            <TableCell
                              colSpan={5}
                              sx={{ padding: "20px", textAlign: "center" }}
                            >
                              No Roles Added
                            </TableCell>{" "}
                            {/* No Roles Added */}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <form>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                            setImageId(0);
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Full Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          value={name}
                          onChange=
                          // {(e)=>{
                          //   if(e.target.value.length<=50)
                          //   setName(e.target.value)
                          //   if(e.target.value.length>50)
                          //     showError("Sub-Admin name cant exceed 50 characters")
                          // }}
                          {
                            (e)=>{
                      
                              if( e.target.value==" ") return;
                              if(!isEnglishString(e.target.value))
                                {
                                  return
                                }
                              if(e.target.value.length<=50)
                                setName(e.target.value)
                            else
                            showError("Sub-Admin name cant exceed 50 characters")
                            }}
                          variant="outlined"
                          fullWidth
                          placeholder="Name"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">Email</Typography>
                        <TextField
                          hiddenLabel
                          required
                          type={"email"}
                          value={email}
                          onChange={(e)=>
                           { 
                            if(e.target.value.length<=50)
                            setEmail(e.target.value)
                            if(e.target.value.length>50)
                            showError("Email cant exceed 50 characters")
                          }
                          
                          }
                          name="message"
                          variant="outlined"
                          fullWidth
                          placeholder="Email"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Phone Number
                        </Typography>
                        <PhoneInput
  country={"us"}
  placeholder="0 (000) 000-000"
  enableSearch={true}
  inputStyle={{ width: "100%" }}
  value={phoneDisplay}
  // onChange={(value) => formik.setFieldValue("phone", value)}
  onChange={(phone, country) =>
    handleChangePhone(phone, country)
  }
  isValid={!(error && !phone)}
/>
{error && !phone && (
  <Typography variant="caption" color="error">
    Phone number is required
  </Typography>
)}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Select Role
                        </Typography>

                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className="select_div"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRole}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(e)=>setSelectedRole(e.target.value)}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            {rolesData.map((data:any)=>{
                              return <MenuItem value={data.id}>{data.role_name}</MenuItem>
                            })}   
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TableContainer className="table_container">
                          <Box className="heading"></Box>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell className="mn_hdng">
                                  Module
                                </TableCell>
                                <TableCell className="mn_hdng">
                                  Add/Edit
                                </TableCell>
                                <TableCell className="mn_hdng">
                                  View
                                  </TableCell>
                                <TableCell className="mn_hdng">
                                  Delete
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {permissions.map((row:any, i:number) => (
                                <TableRow>
                                  <TableCell className="mn_hdng">
                                    {row.label}
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} 
                                    disabled={row.label=="Analytics"}
                                    checked={row.can_add_edit} onChange={()=>{
if(row.can_add_edit==false && row.can_view==false)
  {
    setPermissions((prevData)=>{
      return prevData.map((row:any,index:number)=>{
        if(index==i)
          {
            return {...row,can_view:true}
          }
          return row;
      })
    })
  }
                                      setPermissions((prevData:any)=>{
                                        return prevData.map((row:any,index:number)=>{
                                          if(i==index)
                                          {
                                              return {...row,can_add_edit:!row.can_add_edit}
                                          }
                                          return row;

                                        })
                                      });
                                    }}/>
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} 
                                    onChange={()=>{

                                    if(row.can_view==true && (row.can_add_edit==true||row.can_be_delete==true))
                                    {
                                      setPermissions((prevData:any)=>{
                                        return prevData.map((row:any,index:number)=>{
                                          if(index==i)
                                          {
                                            return {...row,can_be_delete:false,can_add_edit:false}
                                          }
                                          else
                                            return row;
                                        });
                                      })
                                    }
                                      setPermissions((prevData:any)=>{
                                        return prevData.map((row:any,index:number)=>{
                                          if(index==i)
                                            {
                                              return {...row,can_view:!row.can_view}
                                            }
                                            return row;
                                        })
                                      })
                                    }}
                                    checked={row.can_view}/>
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} 
                                    onChange={()=>{
                                      if(row.can_be_delete==false && row.can_view==false)
                                        {
                                          setPermissions((prevData)=>{
                                            return prevData.map((row:any,index:number)=>{
                                              if(index==i)
                                                {
                                                  return {...row,can_view:true}
                                                }
                                                return row;
                                            })
                                          })
                                        }
                                      setPermissions((prevData)=>{
                                        return prevData.map((row:any,index:number)=>{
                                          if(index==i)
                                            {
                                              return {...row,can_be_delete:!row.can_be_delete}
                                            }
                                            return row;
                                        })
                                      })
                                      
                                    }}  
                                    disabled={row.label=="Analytics"}
                                    checked={row.can_be_delete} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={(e)=>{
                          e.preventDefault();
                          // if(!imageId)
                          //   {
                          //     showError("Image is required")
                          //     return;
                          //   }
                          if(name=="")
                            {
                              showError("Full name is required")
                              return;
                            }
                            if(name.length>50)
                              {
                                showError("Full name cant exceed 50");
                                return;
                              }
                            if(email=="")
                              {
                                showError("Email is required")
                                return;
                              }
                              if(!isValidEmail(email))
                                {
                                  showError("Enter valid email");
                                  return;
                                }
                              if(phone=="")
                                {
                                  showError("Phone is required")
                                  return;
                                }
                                if(phone.length<6 || phone.length>16)
                                  {
                                    showError("Invalid phone")
                                    return;
                                  }
                                if(selectedRole=="")
                                  {
                                    showError("Selection of role is required")
                                    return;
                                  }
                          handleAddUpdateSubAdmin();
                        }}
                      >
                        {id?"Update":"Save"}
                      </Button>
                    </div>
                  </CardContent>
                </form>
              </CustomTabPanel>
            </Box>
          </Card>
        </div>
      </>
      <EditSubAdminRole getRolesList={getRolesList} open={openEditRole} handleClose={handleCloseEditRole} selectedRoleData={selectedRoleData} setSelectedRoleData={setSelectedRoleData}/>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={"role"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getRolesList)}
      />
    </>
  );
};

export default AddSubAdmin;
