export const permissionsEnum:any={
    0:"Manage Users",
    1:"Manage Testimonials",
    2:"Manage Purpose",
    3:"Manage Abilities",
    4:"Manage Achievement",
    5:"Manage Subscription",
    6:"Manage Payment",
    7:"Notifications Management",
    8:"Manage CMS",
    9:"Manage Customer Support",
    10:"Analytics",
    11:"Manage FAQ"
}