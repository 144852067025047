import React from 'react'
import MainContainer from '../../layout/MainContainer'
import { Box, Button, Card, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { Undo, Visibility } from '@mui/icons-material'
import SearchBar from '../../components/SearchBar'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
const Payment = () => {
  const navigate=useNavigate();
    function createData(
        orderId: string,
        transactionId: string,
        paymentrecieved: string,
        customername:string,
        email:string,
        phone:string,
      ) {
        return {
          orderId,
          transactionId,
          paymentrecieved,
          customername,
          email,
          phone
        };
      }
      const label = { inputProps: { "aria-label": "Switch demo" } };
      const rows = [
        createData(
         "123123",
         "12345",
         "Credit Card",
         "abdulhussain",
         "abdulhussain@gmail.com",
         "+9123123123"
        ),
      ];
  return (
   <> 
<div className="main_loyout">
    <div className="dashboard">
              <h1 className="mn_hdng">Manage Payment</h1>
            </div>
         <Card className="cards">
                    <form>
                    <Box className="cards_header">
                    <SearchBar
             searchTerm={""}
             setDebouncedSearchTerm={()=>{}}
             value={""}
             onCross={() => {}}
             onChange={()=>{}}
           />
                <Box className="cards_header_right">
                  <Button className="btn btn_primary">
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
                      
                      <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Order Id</TableCell>
                      <TableCell>Transaction Id</TableCell>
                      <TableCell>Payment Recieved</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow key={row.orderId}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.orderId}</TableCell>
                        <TableCell>{row.transactionId}</TableCell>
                        <TableCell>{row.paymentrecieved}</TableCell>
                        <TableCell>{row.customername}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.phone}</TableCell>
                        <TableCell>
                          <Box className="table_actions">
                          <Tooltip title="View">
                              <IconButton onClick={()=>{navigate('/payment/details/id')}}>
                                <Visibility/>
                              </IconButton>
                          </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
                    
                    </form>
        </Card>
</div>
   </>
  )
}

export default Payment