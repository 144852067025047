//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { NotificationResponse } from "../types/Notification";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
};
type NotificationResponse=any;
type GetAllNotificationsResponse = {
    // Notification: NotificationResponse[];
    response_object: NotificationResponse[];
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const NotificationApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
    addNotifications:builder.mutation<
CommonResponseType & {data:GetAllNotificationsResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.addNotification;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getNotifications:builder.mutation<
CommonResponseType & {data:GetAllNotificationsResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getNotification;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getUsersForNotification:builder.mutation<
CommonResponseType & {data:any},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getUserListForNotification;
          return{
            url:url,
            method:'GET',
            // body
          }
        }
}),
// getNotificationById:builder.query<CommonResponseType & {data:NotificationResponse},
// {id:string|undefined}>({
//     query:({id})=>({
//         url:`${END_POINTS.getNotificationById}${id}/`,
//         method:"GET",
//     })
// }),

// changeNotificationStatus: builder.mutation<
// CommonResponseType & { data: NotificationResponse },
// { id: string , body: encryptedBody}
// >({
// query: ({ id ,body}) => ({
//   url: `${END_POINTS.changeNotificationStatus}${id}/`,
//   method: "PUT",
//   body
// }),
// }),

// editNotificationById: builder.mutation<
//       CommonResponseType & { data: NotificationResponse },
//       { id: string; body: encryptedBody }
//     >({
//       query: ({ id, body }) => ({
//         url: `${END_POINTS.updateNotifications}${id}/`,
//         method: "PUT",
//         body,
//       }),
//     }),

deleteNotificationById:builder.mutation<
CommonResponseType & {data:NotificationResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteNotification}${id}/`,
        method:'DELETE'
    })
}),
// getUsers:builder.mutation<
// CommonResponseType & {data:any},
//    encryptedBody>({
//       query:(body)=>{
//           let url = END_POINTS.getUserListForNotification;
//           return{
//             url:url,
//             method:'POST',
//             body
//           }
//         }
// }),
// getNotificationCSV: builder.query<CommonResponseType & { data: string }, {
//     search:string;
//   }>({
//     query: ({search}) => {
//       let url = END_POINTS.exportNotifications;
//       const queryParams = [];
//       if (search) {
//         queryParams.push(`search=${search}`);
//       }
//       if (queryParams.length > 0) {
//         url += `?${queryParams.join('&')}`;
//       }
//      return{ 
//       url:url,
//       method: "GET",}
//     },
//   }),

})
})

export const{
    useAddNotificationsMutation,
    useGetNotificationsMutation,
    useDeleteNotificationByIdMutation,
    useGetUsersForNotificationMutation
    // useLazyGetNotificationByIdQuery,
    // useChangeNotificationStatusMutation,
    // useEditNotificationByIdMutation,
    // useLazyGetNotificationCSVQuery,
}=NotificationApi;
