//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";
// import { FaqResponse } from "../types/Faq";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
};
type FaqResponse=any;
type GetAllFaqsResponse = {
    // Faq: FaqResponse[];
    response_object: FaqResponse[]
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const FaqApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
    addFaqs:builder.mutation<
CommonResponseType & {data:GetAllFaqsResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.addFaq;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getFaqs:builder.mutation<
CommonResponseType & {data:GetAllFaqsResponse},
 CommonBody>({
      query:(body)=>{
          let url = END_POINTS.getFaq;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
editFaqById: builder.mutation<
      CommonResponseType & { data: FaqResponse },
      { id: string; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateFaq}${id}/`,
        method: "PUT",
        body,
      }),
    }),

deleteFaqById:builder.mutation<
CommonResponseType & {data:FaqResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteFaq}${id}/`,
        method:'DELETE'
    })
}),

})
})

export const{
    useAddFaqsMutation,
    useGetFaqsMutation,
    useEditFaqByIdMutation,
    useDeleteFaqByIdMutation,
    // useLazyGetFaqCSVQuery,
}=FaqApi;
