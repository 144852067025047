import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import { showError, showToast } from "../../../constants/toast";
import { errorToast, successToast } from "../../../helpers";
import { useAppDispatch } from "../../../hooks/store";
import { temporaryToken } from "../../../reducers/authSlice";
import { usePostVerifyOtpMutation, useForgotPasswordMutation } from "../../../services/auth";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";

const VerifyOtp = () => {
    const otpStyle = {
        width: "50px",
        height: "50px",
        "box-sizing": "border-box",
        margin: "0 auto",
      };
      const location = useLocation();
      const dispatch = useAppDispatch();
      const { state } = location;
      console.log("state: ", state);
      const navigate = useNavigate();
    
      const [otp, setOtp] = useState("");
      const [countDown, setCountDown] = useState<number>(59);
    
      const [optVerificationMutation, { isLoading }] = usePostVerifyOtpMutation();
      const [ForgotPasswordMutation, ForgotPasswordData] =
        useForgotPasswordMutation();
    
      const [error, setError] = useState<boolean>(false);
    
      const handleSubmit = async (e:any) => {
        e.preventDefault();
        if(otp?.length!=4)
          {
            showError("Please enter one time password")
            return;
          }
        if (otp?.length === 4) {
          setError(false);
    
          const body = {
            email: state?.email,
            otp: otp,
          };
       
          // if (state?.password) {
          //   try {
          //     let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          //     const response =
          //       await optVerificationMutation(encryptedBody).unwrap();
          //     setToStorage(
          //       STORAGE_KEYS.tempToken,
          //       JSON.stringify(response?.data?.token)
          //     );
          //     dispatch(
          //       setCredentials({
          //         user: response?.data,
          //         token: "",
          //       })
          //     );
          //     dispatch(
          //       temporaryToken({
          //         tempToken: response?.data?.token || null,
          //       })
          //     );
          //     navigate("/profile-setup", { state: state, replace: true });
          //   } catch (error: any) {
          //     errorToast(error?.data?.message || "");
          //   }
          // } else {
            try {
              let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
              const response =await optVerificationMutation(encryptedBody).unwrap();
              // const response =await optVerificationMutation(body).unwrap();
              if (response?.status === 200) {
                dispatch(
                  temporaryToken({ tempToken: response?.data?.token })
                );
                showToast("OTP verified successfully");
                navigate("/resetPassword", { replace: true, state: state  });
              }
            } catch (error: any) {
              if (error?.data?.message) {
                errorToast(error?.data?.message || "");
              }
            }
          // }
        } else {
          setError(true);
        }
      };
    
      const handleResendOtp = async (e:any) => {
        e.preventDefault();
        let body = {
          email: state?.email,
        };
        try {
          // let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          // const response = await ForgotPasswordMutation(encryptedBody).unwrap();
          const response = await ForgotPasswordMutation(body).unwrap();
          if (response?.status === 200) {
            successToast("OTP Resent Successfully");
            setCountDown(59);
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
          console.log(error);
        }
      };
    
      useEffect(() => {
        if (countDown > 0) {
          setTimeout(() => {
            setCountDown(countDown - 1);
          }, 1000);
        } else {
          setCountDown(0);
        }
      }, [countDown]);
    
  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
        <figure className='logo_cntnr' style={{textAlign:"center"}}>
        <img src='/static/images/Whizzo.png' style={{padding:"2px",width:"70%",height:"70%"}} alt='Whizzo Admin Panel'/>
    
  </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/forgotpassword")}
              sx={{ p: 0, position: "absolute", left: 0, color:"#FFF" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Verify OTP
            </Typography>
          </Box>
          <form>
            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn-primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Verify OTP
              </Button>
            </Box>
            {countDown === 0 ? (
            <Box
              sx={{
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography
                className="anchor_link"
                sx={{ cursor: "pointer",color:"#9988C5",fontWeight:"700" }}
                onClick={handleResendOtp}
              >
                Resend OTP
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                // className="anchor_link"
                sx={{
                  color: "white",
                  pt: 2,
                  display: "flex",
                  justifyContent: "center",
                  position:"relative",
                  marginLeft:"-40px"
                }}
              >
                The verification code will expire in{" "}
                {countDown < 10 ? (
                  <p style={{ margin: 0, color: "white", right: 35 ,position:"absolute"}}>
                    00 : 0{countDown}
                  </p>
                ) : (
                  <p style={{ margin: 0, color: "white", right: 35,position:"absolute" }}>
                    00 : {countDown}
                  </p>
                )}
              </Typography>
            </Box>
          )}
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default VerifyOtp