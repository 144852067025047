//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";
// import { SubjectResponse } from "../types/Subject";
// import { SubjectResponse } from "../types/Subject";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
};
type SubjectResponse=any;
type GetAllSubjectsResponse = {
    // Subject: SubjectResponse[];
    response_object: SubjectResponse[];
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const SubjectApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
    addSubjects:builder.mutation<
CommonResponseType & {data:GetAllSubjectsResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.addSubject;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getSubjects:builder.mutation<
CommonResponseType & {data:GetAllSubjectsResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getSubject;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),


editSubjectById: builder.mutation<
      CommonResponseType & { data: SubjectResponse },
      { id: string; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editSubject}${id}/`,
        method: "PUT",
        body,
      }),
    }),

deleteSubjectById:builder.mutation<
CommonResponseType & {data:SubjectResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteSubject}${id}/`,
        method:'DELETE'
    })
}),
// getSubjectCSV: builder.query<CommonResponseType & { data: string }, {
//     search:string;
//   }>({
//     query: ({search}) => {
//       let url = END_POINTS.exportSubjects;
//       const queryParams = [];
//       if (search) {
//         queryParams.push(`search=${search}`);
//       }
//       if (queryParams.length > 0) {
//         url += `?${queryParams.join('&')}`;
//       }
//      return{ 
//       url:url,
//       method: "GET",}
//     },
//   }),

})
})

export const{
    useAddSubjectsMutation,
    useGetSubjectsMutation,
    useEditSubjectByIdMutation,
    useDeleteSubjectByIdMutation,
    // useLazyGetSubjectCSVQuery,
}=SubjectApi;
