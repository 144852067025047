import React, { useEffect, useRef, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tooltip,
} from "@mui/material";
import { redirect, useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import { useEditUserByIdMutation, useDeleteUserByIdMutation, useGetUsersMutation, useChangeUserStatusMutation, useLazyGetUserCSVQuery } from "../../services/user";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import WarnModal from "../../components/modals/WarnModal";
import Pagination from "../../components/Pagination";
import { isValidInput } from "../../utils/validations";
import Loader from "../../helpers/Loader";
import { handleDelete } from "../../utils/commonFunctions";
import LoadingBar from "react-top-loading-bar";
import secureLocalStorage from "react-secure-storage";
import { permissionsEnum } from "../../helpers/constants/permission";
import { ADMIN_ROLE_ID } from "../../helpers/constants/role";
import { RolePermission } from "../../types/useAuth";
import { isDeleteAllowed, isEditAllowed } from "../../utils/permissonAllowed";
const ManageUsers = () => {
  const navigate = useNavigate();
  function createData(
    picture:string,
    firstname: string,
    lastname: string,
    userName: string,
    customerId: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      picture,
      firstname,
      lastname,
      userName,
      customerId,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "https://randomuser.me/api/portraits/men/49.jpg",
      "Abdul",
      "Hussian",
      "Abdul67",
      "12378678",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];
  const user = useAuth();
  const ref:any = useRef(null)

  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState<UserResponse[]>([]);

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getUsers]:any = useGetUsersMutation();
  const [updateUserStatus]:any = useChangeUserStatusMutation();
  const [deleteById] = useDeleteUserByIdMutation();
  // const [getUserCsvMethod] = useLazyGetUserCSVQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };


  
  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getUsersList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const body={
        start:page,
        length:10,
        search:debouncedSearchTerm.trim(),
      }
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getUsers(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response);
        setUsers(response?.data?.response_object|| []);
        setTotalCount(response?.data?.total_records);
      } else {
        setUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };
  const [getUserCsvMethod] = useLazyGetUserCSVQuery();
  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({ search: searchTerm }).unwrap();
      if (res?.status === 200) {
        window.open(res?.file_urls|| "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        is_active:!users[index]?.is_active
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateUserStatus({ id: `${users[index].id}` , body:encryptedData}).unwrap();
      if(response?.status==200)
      {
        showToast(users[index]?.is_active?"User disabled successfully":"User enabled successfully");
      setUsers((prevData:any)=>{
        let temp=[...prevData];
        temp[index]={...temp[index],is_active:!temp[index].is_active};
        return temp;
      });}
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getUsersList();
  }, [debouncedSearchTerm, page]);
  console.log(users);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);





  return (
    <>
    <Loader isLoad={isLoading}/>
    <LoadingBar color="#7048c1" ref={ref} shadow={true}  />

      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Users</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Picture</TableCell>
                  <TableCell>Customer Id</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Phone No.</TableCell>
                  <TableCell>Email</TableCell>

                  <TableCell>Account Status</TableCell>

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.length?users.map((row:any, i:number) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{(page-1)*10+i+1}</TableCell>
                    <TableCell><img style={{width:"50px",height:"50px",borderRadius:"50%"}} src={row.profile_picture?.media_url?row.profile_picture?.media_url: "/static/images/user_placeholder.png"}/></TableCell>

                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.first_name?row.first_name:"-"}</TableCell>
                    <TableCell>{row.last_name?row.last_name:"-"}</TableCell>

                    <TableCell>{row.phone_no?(row?.country_code+row.phone_no):"-"}</TableCell>
                    <TableCell>{row.email?row.email:"-"}</TableCell>

                    <TableCell>
                      <Switch {...label} 
                      checked={row?.is_active} 
                      size="small"
                      disabled={isEditAllowed(0)?false:true}
                      onChange={()=>handleStatusChange(i)}
                      />
                    </TableCell>

                    <TableCell>
                      <Box className="table_actions">
                       <Tooltip title="View">
                          <IconButton
                            onClick={() => navigate("/manage-users/details/"+row?.id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                       </Tooltip>
                       {isEditAllowed(0) && 
                      <Tooltip title="Edit">
                         <IconButton
                            onClick={() =>
                              navigate("/manage-users/usersform/"+row?.id)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                      </Tooltip>
                        }
                        {isDeleteAllowed(0) && 
                       <Tooltip title="Delete">
                          <IconButton onClick={()=>{
                            setOpen(true);
                            setSelectedId(row.id)}}>
                            <DeleteIcon />
                          </IconButton>
                       </Tooltip>
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                )):<TableRow><TableCell align={"center"} colSpan={9}>No Users Found</TableCell></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <WarnModal
        setOpen={setOpen}
        open={open}
        name={"user"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getUsersList)}
      />
       <Pagination
          module={users}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

export default ManageUsers;
