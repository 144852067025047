//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { CustomerResponse } from "../types/Customer";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
    file_urls:any
};
type CustomerResponse=any;
type GetAllCustomersResponse = {
    // Customer: CustomerResponse[];
    response_object: CustomerResponse[];
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const CustomerApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
//     addCustomers:builder.mutation<
// CommonResponseType & {data:GetAllCustomersResponse},
//    encryptedBody>({
//       query:(body)=>{
//           let url = END_POINTS.addCustomer;
//           return{
//             url:url,
//             method:'POST',
//             body
//           }
//         }
// }),
getCustomers:builder.mutation<
CommonResponseType & {data:GetAllCustomersResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getAllQueries;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
// getCustomerById:builder.query<CommonResponseType & {data:CustomerResponse},
// {id:string|undefined}>({
//     query:({id})=>({
//         url:`${END_POINTS.getCustomerById}${id}/`,
//         method:"GET",
//     })
// }),

revertCustomerQuery: builder.mutation<
CommonResponseType & { data: CustomerResponse },
{ id: number ,body:encryptedBody}
>({
query: ({ id,body }) => ({
  url: `${END_POINTS.revertQuery}${id}/`,
  method: "POST",
  body
}),
}),

// editCustomerById: builder.mutation<
//       CommonResponseType & { data: CustomerResponse },
//       { id: string; body: encryptedBody }
//     >({
//       query: ({ id, body }) => ({
//         url: `${END_POINTS.updateCustomers}${id}/`,
//         method: "PUT",
//         body,
//       }),
//     }),

deleteCustomerById:builder.mutation<
CommonResponseType & {data:CustomerResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteQuery}${id}/`,
        method:'DELETE'
    })
}),
getCustomerCSV: builder.mutation<CommonResponseType & { data: string },any>({
    query: (body) => {
      let url = END_POINTS.exportCustomerQueries;
      const queryParams = [];
      // if (search) {
      //   queryParams.push(`search=${search}`);
      // }
      // if (queryParams.length > 0) {
      //   url += `?${queryParams.join('&')}`;
      // }
     return{ 
      url:url,
      method: "POST",
      body
    }
    },
  }),

})
})

export const{
    useGetCustomersMutation,
    useDeleteCustomerByIdMutation,
    useRevertCustomerQueryMutation,
    useGetCustomerCSVMutation
}=CustomerApi;
