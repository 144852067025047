import { CardContent, Grid, Typography, TextField, Button, Modal, Card } from '@mui/material';
import React from 'react'
import { showError, showToast } from '../constants/toast';
import { useUpdateRoleByIdMutation } from '../services/subadminroles';
import { generateEncryptedKeyBody } from '../utils/crypto';
import { CommonBody } from '../types/General';

const EditSubAdminRole = ({open,handleClose,selectedRoleData,setSelectedRoleData,getRolesList}:any) => {
const [updateData]=useUpdateRoleByIdMutation();    
const handleUpdateRole=async(e:any)=>{
e.preventDefault();
const body={
    role_name:selectedRoleData?.selectedRoleName,
}
try{
const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
const response=await  updateData({id:selectedRoleData?.selectedRoleId,body:encryptedBody}).unwrap();
if(response.status==200)
{
showToast("Role updated successfully")
handleClose();
getRolesList();
}
}
catch(error:any)
{
    showError("Role not updated")
    console.log(error);
    
}
    }
  return (
   <Modal
   open={open}
   onClose={handleClose}
   >
    <Card style={{marginInline:"35%",marginTop:"15%",padding:"1%"}}>
        <form onSubmit={handleUpdateRole}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    Edit Role Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    variant="outlined"
                    value={selectedRoleData?.selectedRoleName}
                    fullWidth
                    placeholder="Edit Role"
                    onChange={(event) =>{
                      if(event.target.value==" ") return;
                      if(event.target.value.length<=60)
                      setSelectedRoleData((prevData:any)=>({...prevData,selectedRoleName:event.target.value}))
                    if(event.target.value.length>60)
                      showError("Role name cant exceed 60 characters");
                    }
                    }
                  />
                </Grid>
              </Grid>
          <div className="form_btn" style={{display:"flex",justifyContent:"end"}}>
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  
                  style={{marginRight:"15px",minWidth:"90px"}}
                >
                  Edit
                </Button>
                <Button
                  size="large"
                  onClick={()=>{
                    handleClose();
                    setSelectedRoleData((prevData:any)=>({selectedRoleId:0,selectedRoleName:""}))
                  }}
                  style={{minWidth:"90px"}}
                  className="btn btn_primary"
                >
                  Cancel
                </Button>
              </div>
            </CardContent>
          </form>
    </Card>

    </Modal>
  )
}

export default EditSubAdminRole