import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "../app/store";
import { API_URL } from "../helpers/constants/urls";
import { resetAuth } from "../reducers/authSlice";
import { generateSEKAndHash } from "./crypto";
import { showError } from "../constants/toast";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  // baseUrl: "https://25d8-2409-40f0-1018-12c1-c953-4669-9fdf-6144.ngrok-free.app/admin/admin-login/",
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    const { tempToken } = (getState() as RootState).auth;

    const encryptData = generateSEKAndHash(token||tempToken||null);

    if (encryptData) {
      headers.set("hash", encryptData?.hash);
      headers.set("sek", encryptData?.sek);
      headers.set("Device","WEB");
      headers.set("appkey",new Date().toISOString())
        // headers.set("authorization",`Bearer ${token}`)l

    }
    console.log(headers);

    return headers;
  },


  // prepareHeaders: (headers, { getState }) => {
  //   const { token } = (getState() as RootState).auth;
  //   const { tempToken } = (getState() as RootState).auth;
  //   console.log(token,"tokennnnnnnnn");
    
  //   if (token) {
  //     headers.set("authorization", `Bearer ${token}`);
  //   } else if (tempToken) {
  //     headers.set("authorization", `Bearer ${tempToken}`);
  //   }
  //   headers.set("Device","WEB");
  //   // headers.set("Hash", `${encryptedData?.hash}`);
  //   // headers.set("Sek", ` ${encryptedData?.sek}`);
  // // headers.set("Device","WEB"); 
  //   return headers;
  // },

});

const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs | any,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // if (args.body) {
  //   const encryptedData = generateEncryptedKeyBody(args.body);
  //   if (encryptedData) {
  //     args.body = encryptedData;
  //   } else {
  //     return {
  //       error: {
  //         status: 400,
  //         data: { detail: "Failed to encrypt request body" },
  //       },
  //     };
  //   }
  // }
  const result = await baseQuery(args, api, extraOptions);
  //   if(result.error?.status === 400) {
  //     const errors = Object.values(result?.error?.data || {});
  //     console.log({ errors });
  //     if (errors?.length > 1 && errors[1] === 400) {
  //       return result;
  //     }
  //     if (errors?.length) {
  //       const error = errors[0] as any;
  //       if (error?.length) {
  //         console.log(error[0]);
  //       }
  //     }
  //   }
  if (result?.error?.status === 401) {
    api.dispatch(resetAuth());
    // showError("CUSTOM ERROR")
    if (window) {
      window.location.replace("/");
    }
  }
  if(
    result.error &&
    result.error.status === 401 &&
    (api.getState() as RootState).auth.user
  ){
    api.dispatch(resetAuth());
  }

  return result;
};

const emptySplitApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR"],
  endpoints: () => ({}),
});

export default emptySplitApi;