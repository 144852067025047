import React, { useEffect, useRef, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import { useEditSubAdminByIdMutation, useDeleteSubAdminByIdMutation, useGetSubAdminsMutation, useEditSubAdminStatusByIdMutation } from "../../services/subadmin";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import Loader from "../../helpers/Loader";
import LoadingBar from "react-top-loading-bar";
import { Visibility } from "@mui/icons-material";

const ManageSubAdmin = () => {
  const navigate = useNavigate();

  function createData(Name: string,Image:string, Email: string, Phone: string) {
    return { Name, Image ,Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin","/static/images/user_placeholder.png", "subadmin@gmail.com", "+971056734321")];
  const userData = useAuth();
  const[isLoading,setIsLoading]=useState(false);
  const ref:any = useRef(null)

  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [subAdmins, setSubAdmins] = useState<any>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getSubAdmins] = useGetSubAdminsMutation();
  const [updateAdminStatus]:any = useEditSubAdminStatusByIdMutation();
  const [deleteById] = useDeleteSubAdminByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getSubAdminsList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const body={
        start:page,
        length:10,
        search:debouncedSearchTerm.trim(),
      }
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getSubAdmins(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log("SUB",response);
        setSubAdmins(response?.data?.response_object || []);
        setTotalCount(response?.data?.total_records);
      } else {
        setSubAdmins([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };

 
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        is_active:!subAdmins[index]?.is_active
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateAdminStatus({ id: `${subAdmins[index].id}` , body:encryptedData}).unwrap();

      // showToast(response?.message || "User Updated Successfully");
      showToast(subAdmins[index]?.is_active?"Sub-Admin disabled successfully":"Sub-Admin enabled successfully");
      
      setSubAdmins((prevData:any)=>{
        let temp=[...prevData];
        temp[index]={...temp[index],is_active:!temp[index].is_active};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getSubAdminsList();
  }, [debouncedSearchTerm, page]);
  console.log(subAdmins);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);

  return (
    <>
      <div className="main_loyout">
        <Loader isLoad={isLoading}/>
        <LoadingBar color="#7048c1" ref={ref} shadow={true}  />

        <div className="dashboard">
          
          <h1 className="mn_hdng"> Manage Sub-Admin</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
            <Button 
            className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
                Add Sub-Admin
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subAdmins?.length?subAdmins.map((row:any, i:number) => (
                  <TableRow key={i}>
                    <TableCell align="center">{(page-1)*10+i+1}</TableCell>
                    <TableCell><img style={{width:"50px",height:"50px",borderRadius:"50%"}} 
                    src={row?.profile_picture?.media_url?row?.profile_picture?.media_url:"/static/images/user_placeholder.png"}/>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row?.sub_role?.role_name}</TableCell>

                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone_no}</TableCell>
                    <TableCell>
                    <Switch {...label} 
                      checked={row.is_active} 
                      size="small" 
                      onChange={()=>handleStatusChange(i)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => navigate("detail/"+row?.id)}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                       <Tooltip title="Edit">
                          <IconButton
                            onClick={() => navigate("/manage-subAdmin/edit/"+row?.id)}
                          >
                            <ModeEditIcon />
                          </IconButton>
                       </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={()=>{
                            setOpen(true);
                            setSelectedId(row.id)}}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                )):<TableRow>
                  <TableCell align={"center"}colSpan={8}>
                    No Sub-Admin Found
                    </TableCell>
                    </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={subAdmins}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
         <WarnModal
        setOpen={setOpen}
        open={open}
        name={"subadmin"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getSubAdminsList)}
      />
      </div>
    </>
  );
};

export default ManageSubAdmin;
