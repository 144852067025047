import {Routes,Route, createBrowserRouter, createRoutesFromElements, RouterProvider, redirect} from "react-router-dom";
import Pages from "./pages"
import UserDetails from './pages/users/details';
import UsersForm from './pages/users/form/usersForm';
import MainContainer from "./layout/MainContainer";
import Features from "./features";
import secureLocalStorage from "react-secure-storage";
import { ADMIN_ROLE_ID } from "./helpers/constants/role";
import { RolePermission } from "./types/useAuth";
import { permissionsEnum } from "./helpers/constants/permission";
const Routing = () => {

  const ManageLoaderView = async (permissionNumber:number) => {
    const role = secureLocalStorage?.getItem("role");
    const permissions = secureLocalStorage?.getItem("permissions");
  
    if (role === ADMIN_ROLE_ID) {
      return null; // Admins can access the page
    }
  
    if (permissions && permissions !== "undefined") {
      let parsedPermissions;
      try {
        parsedPermissions = JSON.parse(permissions as string);
      } catch (error) {
        console.error("Failed to parse permissions:", error);
        return redirect('/dashboard');
      }
  
      const hasViewPermission = parsedPermissions.some((data2: RolePermission) => 
        (data2?.module === permissionsEnum[permissionNumber]) && data2?.can_view
      );
  
      if (!hasViewPermission) {
        return redirect('/dashboard');
      }
    } else {
      return redirect('/dashboard');
    }
  
    return null;
  };
  const ManageLoaderEditCreate = async (permissionNumber:number) => {
    const role = secureLocalStorage?.getItem("role");
    const permissions = secureLocalStorage?.getItem("permissions");
  
    if (role === ADMIN_ROLE_ID) {
      return null; // Admins can access the page
    }
  
    if (permissions && permissions !== "undefined") {
      let parsedPermissions;
      try {
        parsedPermissions = JSON.parse(permissions as string);
      } catch (error) {
        console.error("Failed to parse permissions:", error);
        return redirect('/dashboard');
      }
  
      const hasViewPermission = parsedPermissions.some((data2: RolePermission) => 
        (data2?.module === permissionsEnum[permissionNumber]) && data2?.can_add_edit
      );
  
      if (!hasViewPermission) {
        return redirect('/dashboard');
      }
    } else {
      return redirect('/dashboard');
    }
  
    return null;
  };
  // const ManageLoaderView = async (permissionNumber:number) => {
  //   const role = secureLocalStorage?.getItem("role");
  //   const permissions = secureLocalStorage?.getItem("permissions");
  //   console.log(role,ADMIN_ROLE_ID);
  //   if (role === ADMIN_ROLE_ID) {
  //     return null; // Admins can access the page
  //   }
  
  //   if (permissions !== "undefined") {
  //     const parsedPermissions = JSON?.parse(permissions as string || "");
  //     const hasViewPermission = parsedPermissions?.some((data2: RolePermission) => 
  //       (data2?.module === permissionsEnum?.[permissionNumber]) && data2?.can_view
  //     );
  
  //     if (!hasViewPermission) {
  //       return redirect('/dashboard');
  //     }
  //   } else {
  //     return redirect('/dashboard');
  //   }
  
  //   return null;
  // };
  // const ManageLoaderEditCreate = async (permissionNumber:number) => {
  //   const role = secureLocalStorage.getItem("role");
  //   const permissions = secureLocalStorage.getItem("permissions");
  
  //   if (role === ADMIN_ROLE_ID) {
  //     return null; // Admins can access the page
  //   }
  
  //   if (permissions !== "undefined") {
  //     const parsedPermissions = JSON.parse(permissions as string || "");
  //     const hasViewPermission = parsedPermissions.some((data2: RolePermission) => 
  //       (data2?.module === permissionsEnum[permissionNumber]) && data2?.can_add_edit
  //     );
  
  //     if (!hasViewPermission) {
  //       return redirect('/dashboard');
  //     }
  //   } else {
  //     return redirect('/dashboard');
  //   }
  
  //   return null;
  // };
  const ManageLoaderSubAdmin = async () => {
    const role = secureLocalStorage.getItem("role");
    if (role === ADMIN_ROLE_ID) {
      return null; // Admins can access the page
    }
    
   throw redirect('/dashboard');
  };
  const modernRouter=createBrowserRouter(
    [
    {
      //initial route
      path:'/',
      children:[
        //login
        {
          index:true,
          element:<Pages.LoginPage/>
        },
        //forgot password
        {
          path:'forgotpassword',
          element:<Pages.ForgotPasswordPage/>,
        },
        //verify password
        {
          path:'verifyotp',
          element:<Pages.VerifyOtp/>
        },
        //reset password
        {
          path:'resetpassword',
          element:<Pages.ResetPasswordPage/>,
        },
        //change password
        {
          path:'changepassword',
          element:<Pages.ChangePassword/>,
        },
        //all pages with topbar and sidebar
        {
          // path:'/',
          element:<MainContainer/>,
          children:[

            //dashboard
            {
            index:true,
            path:'dashboard',
            element:<Pages.DashboardPage/>,
            },

            //profile
            {
              path:'profile',
              element:<Pages.Profile/>,
            },
            //manage user
            {
              path:"manage-users",
              loader:()=>ManageLoaderView(0),
              children:[
                {
                  index:true,
                  element:<Pages.ManageUsers/>,
                },
                {
                  path:'details/:id',
                  element:<UserDetails />
                },
                {
                  path:'usersform/:id',
                  loader:()=>ManageLoaderEditCreate(0),
                  element:<UsersForm />
                },

              ]
            },
            //manage sub-admin
            {
              path:"manage-subadmin",
              loader:ManageLoaderSubAdmin,
              children:[
                {
                  index:true,
                  element:<Pages.ManageSubAdmin/>,
                },
                {
                  path:'add',
                  element:<Pages.AddSubAdmin />
                },
                {
                  path:'edit/:id',
                  element:<Pages.AddSubAdmin />
                },
                {
                  path:"detail/:id",
                  element:<Pages.SubAdminDetail/>
                }

              ]
            },
            //manage cms
            {
              path:'cms',
              element:<Pages.ManageCms/>,
              loader:()=>ManageLoaderView(8),
            },
            //manage faq
            {
              path:'faq',
              element:<Pages.ManageFaq/>,
              loader:()=>ManageLoaderView(11),
            },

            //manage-notification
            {
              path:'manage-notifications',
              loader:()=>ManageLoaderView(7),
              children:[
                {
                  index:true,
                  element:<Pages.ManageNotifications />
                },
                {
                  path:'add',
                  loader:()=>ManageLoaderEditCreate(6),
                  element:<Pages.AddNotification />
                }
              ]
            },
            //recieved-notification
            {
              path:'recieved-notifications',
              loader:()=>ManageLoaderView(7),
              element:<Pages.RecievedNotifications/>
            },
            //analytics
            {
              path:'analytics',
              element:<Pages.Analytics/>,
              loader:()=>ManageLoaderView(10),
            },
            //customer support
            {
              path:'customer-support',
              element:<Pages.CustomerSupport/>,
              loader:()=>ManageLoaderView(9),
            },
            //manage payment
            {
              path:'payment',
              loader:()=>ManageLoaderView(6),
              children:[
                {
                  index:true,
                  element:<Pages.Payment/>
                },
                {
                  path:'details/:id',
                  element:<Pages.PaymentDetail/>
                }
              ]
            },
            //manage subscription
            {
              path:"manage-subscription",
              loader:()=>ManageLoaderView(5),
              children:[
                {
                  index:true,
                  element:<Pages.ManageSubscription/>,
                },
                {
                  path:'add',
                  element:<Pages.Addsubscription />
                },
                {
                  path:'edit/:id',
                  element:<Pages.Addsubscription />
                },
                {
                  path:'details/:id',
                  element:<Pages.SubscriptionDetails />
                },

              ]
            },
             //manage testimonials
            {
              path:"manage-testimonials",
              loader:()=>ManageLoaderView(1),
              children:[
                {
                  index:true,
                  element:<Pages.ManageTestimonials/>,
                },
                {
                  path:'add',
                  loader:()=>ManageLoaderEditCreate(1),
                  
                  element:<Pages.AddTestimonial />
                },
                {
                  path:'edit/:id',
                  loader:()=>ManageLoaderEditCreate(1),
                  element:<Pages.AddTestimonial />
                },
                {
                  path:'details/:id',
                  element:<Pages.TestimonialDetail />
                },

              ]
            },
            //manage purposes
            {
              path:"manage-purposes",
              loader:()=>ManageLoaderView(2),
              children:[
                {
                  index:true,
                  element:<Pages.ManagePurposes/>,
                },
                {
                  path:'add',
                  loader:()=>ManageLoaderEditCreate(2),
                  element:<Pages.AddPurpose />
                },
                {
                  path:'edit/:id',
                  loader:()=>ManageLoaderEditCreate(2),
                  element:<Pages.AddPurpose />
                },
                {
                  path:'details/:id',
                  element:<Pages.TestimonialDetail />
                },

              ]
            },
            //manage ability
            {
              path:'manage-ability',
              element:<Pages.ManageAbility/>,
              loader:()=>ManageLoaderView(3),
            },
            //manage achievement
            {
              path:'manage-achievement',
              loader:()=>ManageLoaderView(4),
              children:[
                {
                  index:true,
                  element:<Pages.ManageAchievement/>
                },
                {
                  path:'addSubject',
                  loader:()=>ManageLoaderEditCreate(4),
                  element:<Pages.AddSubject/>
                }
              ]
            },
            //404 page
            {
              path:"*",
              element:<Features.PageNotFound/>
            },
          ]
        },
      ]
    }
  ]);

  //this below router is not used anymore
  const router=createBrowserRouter(createRoutesFromElements(
    <Route path='/'>
    <Route index element={<Pages.LoginPage/>}/>
    <Route path='forgotpassword' element={<Pages.ForgotPasswordPage/>}/>
    <Route path='verifyotp' element={<Pages.VerifyOtp/>}/>
    <Route path='resetpassword' element={<Pages.ResetPasswordPage/>}/>
    <Route path="changepassword" element={<Pages.ChangePassword />} />



    <Route path='/' element={<MainContainer/>}>
    
    <Route index  path='dashboard' element={<Pages.DashboardPage/>}/>
    
    
    <Route path="profile" element={<Pages.Profile />} />        
    

    <Route path="manage-users">
    <Route index element={<Pages.ManageUsers />}/>
        <Route path="details/:id" element={<UserDetails />} />
        <Route path="usersform/:id" element={<UsersForm />} />
        </Route>


    <Route path="manage-subadmin" >
        <Route index element={<Pages.ManageSubAdmin />}/>
        <Route path="add" element={<Pages.AddSubAdmin />} />
        <Route path="edit/:id" element={<Pages.AddSubAdmin />} />
        </Route>


    <Route path="cms" element={<Pages.ManageCms/>} />

    <Route path="faq" element={<Pages.ManageFaq/>} />


    <Route path="manage-notifications">
        <Route index element={<Pages.ManageNotifications />}/>
        <Route path="add" element={<Pages.AddNotification />}/>
        </Route>


    <Route path="analytics" element={<Pages.Analytics/>}/>


    <Route path="customer-support" element={<Pages.CustomerSupport/>}/>



    <Route path="payment">
      <Route index element={<Pages.Payment/>}/>
      <Route path='details/:id' element={<Pages.PaymentDetail/>}/>
    </Route>


      <Route path="manage-subscription">
        <Route index element={<Pages.ManageSubscription />} />
        <Route path="add" element={<Pages.Addsubscription />}/>
        <Route path="edit/:id" element={<Pages.Addsubscription />}/>
        <Route path="details/:id" element={<Pages.SubscriptionDetails />} />
        </Route>


<Route path='manage-testimonials'>
    <Route index element={<Pages.ManageTestimonials/>}/>
    <Route path='add' element={<Pages.AddTestimonial/>}/>
    <Route path='edit/:id' element={<Pages.AddTestimonial/>}/>
    <Route path='details/:id' element={<Pages.TestimonialDetail/>}/>
</Route>

<Route path='manage-purposes'>
    <Route index element={<Pages.ManagePurposes/>}/>
    <Route path='edit/:id' element={<Pages.AddPurpose/>}/>
    <Route path='add' element={<Pages.AddPurpose/>}/>

</Route>

<Route path='manage-ability' element={<Pages.ManageAbility/>}/>

<Route path={'manage-achievement'}>
  <Route index element={<Pages.ManageAchievement/>}/>
  <Route  path='addSubject' element={<Pages.AddSubject/>}/>

</Route>

    </Route>
    </Route>
  ));
  return (
  <RouterProvider router={modernRouter}/>
  )
}

export default Routing;