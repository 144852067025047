import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import { Rating } from 'react-simple-star-rating';
import { useLazyGetSubAdminByIdQuery } from '../../services/subadmin';
import { RolePermission, SubAdmin } from '../../types/subadmin';
import { Close, Done } from '@mui/icons-material';
const SubAdminDetail = () => {
  const{id}=useParams();
  const [getDataById]=useLazyGetSubAdminByIdQuery();
  const[SubAdminData,setSubAdminData]=useState<SubAdmin|null>();
const navigate = useNavigate();
    const label = { inputProps: { "aria-label": "Switch demo" } };
    const getDataByIdList=async()=>{
      try{const response=await getDataById({id}).unwrap();
      if(response.status==200)
        {
         setSubAdminData(response?.data);
         console.log(response?.data);
        }}
        catch(error){
          console.log(error);
        }
    }
    useEffect(()=>{
      if(id)
        getDataByIdList();
    },[id])
    return (
      <>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Sub-Admin Details</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                // navigate("../"); it is working
                navigate("/manage-subAdmin"); 

              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
           
          <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img src={SubAdminData?.profile_picture?.media_url?SubAdminData?.profile_picture?.media_url:"/static/images/user_placeholder.png"} alt="" />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Name</Typography>
                  <Typography component="p">{SubAdminData?.name ?SubAdminData?.name:"-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p">{SubAdminData?.phone_no?(SubAdminData?.country_code+" "+SubAdminData?.phone_no):"-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p">{SubAdminData?.email?SubAdminData?.email:"-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Sub-Admin Status</Typography>
                  <Typography component="p">{SubAdminData?.is_active?"Active":"InActive"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    Role
                  </Typography>
                  <Typography component="p">{SubAdminData?.sub_role?.role_name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                        <TableContainer className="table_container">
                          <Box className="heading"></Box>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell className="mn_hdng">
                                  Module
                                </TableCell>
                                <TableCell className="mn_hdng">
                                  Add/Edit
                                </TableCell>
                                <TableCell className="mn_hdng">
                                  View
                                  </TableCell>
                                <TableCell className="mn_hdng">
                                  Delete
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {SubAdminData?.role_permission?.filter((data)=>data.can_view).length ?SubAdminData?.role_permission?.filter((data)=>data.can_view).map((row:RolePermission, i:number) => (
                                <TableRow>
                                  <TableCell className="mn_hdng">
                                    {row.module}
                                  </TableCell>
                                  <TableCell>
                                  {row.can_add_edit? <Done color='success'/>
                                    :<Close color='error'/> }
                                  </TableCell>
                                  <TableCell>
                                  {row.can_view? <Done color='success'/>
                                    :<Close color='error'/> }
                                  </TableCell>
                                  <TableCell>
                                   {row.can_be_delete? <Done color='success'/>
                                    :<Close color='error'/> }
                                  </TableCell>
                                </TableRow>
                              )):<TableRow >
                              <TableCell align='center' colSpan={4} className="mn_hdng">
                                No Permission given
                              </TableCell></TableRow>}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Date of Birth</Typography>
                  <Typography component="p">25/12/1999</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Business Email</Typography>
                  <Typography component="p">abdul@yopmail.com</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Website</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Tax Number</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Address</Typography>
                  <Typography component="p">Mohali,Punjab</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Zip code</Typography>
                  <Typography component="p">116059</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Credit Score</Typography>
                  <Typography component="p">500</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography component="h5">Bio</Typography>
                  <Typography component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
          
          </Card>
        </div>
      </>
    );
  };
  
export default SubAdminDetail