//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import AddSubAdmin from "../pages/manageSubAdmin/add";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    status: number;
    message: string;
};
type GetAllSubAdminsResponse = {
    response_object: any;
    total_records: number;
  };
  
type GetTokenParams = {
    limit?: number;
    page?: number;
    query?: string;
  };
// type EditSubAdminById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type EditSubAdminById = {
  hash: string;
  sek: string;
} | null
export const SubAdminApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({

getSubAdmins:builder.mutation<
CommonResponseType & {data:GetAllSubAdminsResponse},
    CommonBody>({
        query:(body)=>{
          return{
            url:END_POINTS.getAllSubAdmin,
            method:'POST',
            body                
        }
        }
}),
getSubAdminById:builder.query<CommonResponseType & {data:any},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.getSubAdminById}${id}/`,
        method:"GET",
    })
}),
editSubAdminById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string; body: EditSubAdminById }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateSubAdminById}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    editSubAdminStatusById: builder.mutation<
    CommonResponseType & { data: any },
    { id: string; body: EditSubAdminById }
  >({
    query: ({ id, body }) => ({
      url: `${END_POINTS.updateSubAdminStatusById}${id}/`,
      method: "PUT",
      body,
    }),
  }),
deleteSubAdminById:builder.mutation<
CommonResponseType & {data:any},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteSubAdminById}${id}/`,
        method:'DELETE'
    })
}),

addSubAdmin:builder.mutation<CommonResponseType & {data:any},CommonBody>({
    query:(body)=>({
        url:`${END_POINTS.addSubAdmin}`,
        method:'POST',
        body
    })
})
})
})

export const{
    useGetSubAdminsMutation,
    useLazyGetSubAdminByIdQuery,
    useEditSubAdminByIdMutation,
    useDeleteSubAdminByIdMutation,
    useAddSubAdminMutation,
    useEditSubAdminStatusByIdMutation,
}=SubAdminApi;
