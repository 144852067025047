import React, { useRef, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import { useFormik } from "formik";
import { showToast, showError } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import { useLazyGetCmsQuery, useUpdateCmsAboutMutation, useUpdateCmsContactMutation, useUpdateCmsMutation, useUpdateCmsPrivacyMutation, useUpdateCmsTermsMutation } from "../../services/cms";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import * as Yup from "yup";
import Loader from "../../helpers/Loader";
import LoadingBar from "react-top-loading-bar";
import { isEditAllowed } from "../../utils/permissonAllowed";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span">
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const user=useAuth();
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [aboutUs, setAboutUs] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [privacyPolicy_ar, setPrivacyPolicy_ar] = useState<string>("");
  const [aboutUs_ar, setAboutUs_ar] = useState<string>("");
  const [termsAndConditions_ar, setTermsAndConditions_ar] = useState<string>("");
  const[email,setEmail]=React.useState("");
  const[phoneDisplay,setPhoneDisplay]=React.useState("");
  const[phone,setPhone]=React.useState("");
  const[isLoading,setIsLoading]=useState(false);

  const [value, setValue] = React.useState(0);
  const [phoneCode, setPhoneCode] = useState("+91");
  
  const[getCms]=useLazyGetCmsQuery();
  const ref:any = useRef(null)

  const[cmsAbout]=useUpdateCmsMutation();
  const[cmsContact]=useUpdateCmsMutation();
  const[cmsPrivacy]=useUpdateCmsMutation();
  const[cmsTerms]=useUpdateCmsMutation();
  // const[cmsAbout]=useUpdateCmsAboutMutation();
  // const[cmsContact]=useUpdateCmsContactMutation();
  // const[cmsPrivacy]=useUpdateCmsPrivacyMutation();
  // const[cmsTerms]=useUpdateCmsTermsMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
        
        
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      updateCmsContact();
    }
  });
  const getCmsData=async()=>{ 
    setIsLoading(true);
    ref?.current?.continuousStart()

    try{
    const response=await getCms({}).unwrap();
    if(response.status==200)
    { 
      setPrivacyPolicy(response?.data?.privacy_policy?.privacy_policy);
      setTermsAndConditions(response?.data?.terms_condition?.terms_condition);
      setAboutUs(response?.data?.about_us?.about_us);
      setPrivacyPolicy_ar(response?.data?.privacy_policy?.privacy_policy_ar);
      setTermsAndConditions_ar(response?.data?.terms_condition?.terms_condition_ar);
      setAboutUs_ar(response?.data?.about_us?.about_us_ar);
      formik.setFieldValue("email", response.data?.contact_support?.email);
      formik.setFieldValue("phone", response?.data?.contact_support?.phone_no);
      setPhoneCode(response?.data?.contact_support?.country_code);
      setPhoneDisplay(response?.data?.contact_support?.country_code+response?.data?.contact_support?.phone_no);
      console.log("CMS FETCH SUCCESS");
    }
    setIsLoading(false);
  }
  catch(error)
  {
    console.log(error);
  }
  ref?.current?.complete();

}
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneDisplay(country?.dailcode+phone);
    setPhoneCode(country?.dialCode);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const updateCmsContact=async()=>{
    try{
      if(parseInt(formik.values.phone)==0)
        {
          showError("Invalid phone number")
          return ;
        }

      const body={
        email:formik.values.email,
        phone_no: formik.values.phone,
        country_code:(phoneCode.includes("+") ? "" : "+") + phoneCode,
      }
      console.log('body',body);
      const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
      const response=await cmsContact(encryptedData).unwrap();
      if(response.status==200)
      {
        showToast("Contact details updated sucessfully");
        console.log("CMS Contact UPDATE SUCCESS");
      }
    }
    catch(error:any){
      showError(error?.data?.message);
      console.log(error);
    }
  }

  const updateCmsPrivacy=async()=>{
    try{
      const body={
        privacy_policy:privacyPolicy,
        privacy_policy_ar:privacyPolicy_ar,
      }
      console.log('body',body);
      const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
      const response=await cmsPrivacy(encryptedData).unwrap();
      if(response.status==200)
      {
        showToast("Privacy Policy updated sucessfully");
      }
    }
    catch(error:any){
      showError(error?.data?.message);
      console.log(error);
    }
  }

  const updateCmsTerms=async()=>{
    try{
      const body={
        terms_condition:termsAndConditions,
        terms_condition_ar:termsAndConditions_ar,
      }
      console.log('body',body);
      const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
      const response=await cmsTerms(encryptedData).unwrap();
      if(response.status==200)
      {
        showToast("Term & Conditions updated sucessfully");
        console.log("CMS UPDATE SUCCESS");
      }
    }
    catch(error:any){
      showError(error?.data?.message);
      console.log(error);
    }
  }

  const updateCmsAbout=async()=>{
    try{
      const body={
        about_us:aboutUs,
        about_us_ar:aboutUs_ar,
      }
      console.log('body',body);
      const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
      const response=await cmsAbout(encryptedData).unwrap();
      if(response.status==200)
      {
        showToast("About Us details updated sucessfully");
        console.log("CMS UPDATE SUCCESS");
      }
    }
    catch(error:any){
      showError(error?.data?.message);
      console.log(error);
    }
  }
  React.useEffect(()=>{
    getCmsData();
  },[])


  return (
    <>
      <div className="main_loyout">
        <Loader isLoad={isLoading}/>      
        <LoadingBar color="#7048c1" ref={ref} shadow={true}  />

        <div className="dashboard">
          <h1 className="mn_hdng">Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              {/* <Tab label="About Us" {...a11yProps(1)} /> */}

              <Tab label="Privacy Policy" {...a11yProps(1)} />
              <Tab label="Terms and Conditions" {...a11yProps(2)} />
              <Tab label="About Us" {...a11yProps(3)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      value={formik.values.email}
                      onChange={
                        (e)=>{
                          if(e.target.value==" ") return;
                          if(e.target.value.length<=50)
                            formik.handleChange(e)
                          else
                          showError("Email can't exceed 50 characters")
                        }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.email && formik.errors.email}
                      placeholder="Email"
                      disabled={!isEditAllowed(8)}
                      onMouseOver={(e:any)=>{
                        e.target.style.cursor = !isEditAllowed(8)?'not-allowed':"";
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      disabled={!isEditAllowed(8)}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      value={phoneDisplay}
                      onChange={handleChangePhone}
                      inputStyle={{ width: "100%" }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
         {isEditAllowed(8) && <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>}
                </div>
              </form>
            </CustomTabPanel>
         
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={(e)=>{
                e.preventDefault();
                updateCmsPrivacy();
                }}>
               <Grid container spacing={2}>
               <Grid item lg={6} md={6} sm={6} xs={12}>
               <Typography className="custom_label">Privacy Policy </Typography>
                    <EditText
                      content={privacyPolicy}
                      setContent={setPrivacyPolicy}
                    />
                  </Grid>
               <Grid item lg={6} md={6} sm={6} xs={12}>
               <Typography className="custom_label">Privacy Policy (Arabic)</Typography>
                    <EditText
                      content={privacyPolicy_ar}
                      setContent={setPrivacyPolicy_ar}
                    />
                  </Grid>
               </Grid>
                <div className="form_btn">
                  {isEditAllowed(8) && <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
            <form onSubmit={(e)=>{
                e.preventDefault();
                updateCmsTerms();
                }}>
                   <Grid container spacing={2}>
               <Grid item lg={6} md={6} sm={6} xs={12}>
               <Typography className="custom_label">Terms and Conditions </Typography>
               <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                  </Grid>
               <Grid item lg={6} md={6} sm={6} xs={12}>
               <Typography className="custom_label">Terms and Conditions (Arabic)</Typography>
               <EditText
                  content={termsAndConditions_ar}
                  setContent={setTermsAndConditions_ar}
                />
                  </Grid>
               </Grid>
                
                <div className="form_btn">
                  {isEditAllowed(8) && <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>}
                </div>
              </form>
            </CustomTabPanel>
               <CustomTabPanel value={value} index={3}>
               <form onSubmit={(e)=>{
                e.preventDefault();
                updateCmsAbout();
                }}>
 <Grid container spacing={2}>
               <Grid item lg={6} md={6} sm={6} xs={12}>
               <Typography className="custom_label">About us </Typography>
               <EditText content={aboutUs} setContent={setAboutUs} />
                  </Grid>
               <Grid item lg={6} md={6} sm={6} xs={12}>
               <Typography className="custom_label">About us (Arabic)</Typography>
               <EditText content={aboutUs_ar} setContent={setAboutUs_ar} />
                  </Grid>
               </Grid>
              

                <div className="form_btn">
                  {isEditAllowed(8) && <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>}
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </>
  );
};

export default ManageCms;
