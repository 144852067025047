import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Yup from "yup";
import { useFormik } from "formik";
import { successToast, errorToast } from "../../../helpers";
import { useResetPasswordMutation } from "../../../services/auth";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation();
  const location = useLocation();
  const { state } = location;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleshowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      newpassword: Yup.string()
        .label("new Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
          'Password must be at least 8 characters long and include 1 uppercase, 1 lowercase, and 1 special character.'
        )
        .required("New password is required."),

      confirmpassword: Yup.string()
        .oneOf([Yup.ref("newpassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        email:state.email,
        new_password: formik.values.newpassword,
      };
      console.log("RESET",body)

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response:any = await resetPassword(encryptedBody).unwrap();
        // const response:any = await resetPassword(body).unwrap();
        if (response?.status === 200) {
          navigate("/");
          successToast("Password reset successfully" || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
    m={0}
    className='auth_logo'
    sx={{display:"flex",justifyContent:"center"}}
    >
  <figure className='logo_cntnr' style={{textAlign:"center"}}>
        <img src='/static/images/Whizzo.png' style={{padding:"2px",width:"70%",height:"70%"}} alt='Whizzo Admin Panel'/>
    
  </figure>
    </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/forgotpassword")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
              <ArrowBackIcon style={{color:"white"}}/>
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Reset Password
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                sx={{ m: 0 }}
                hiddenLabel
                placeholder="Password"
                fullWidth
                margin="normal"
                name="newpassword"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.newpassword&&formik.errors.newpassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{setShowPassword(prevShowPassword=>!prevShowPassword)}}
                        onMouseDown={(e:any)=>{e.preventDefault();}}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Confirm Password</Typography>
              <TextField
                sx={{ m: 0 }}
                hiddenLabel
                placeholder="Confirm Password"
                fullWidth
                margin="normal"
                name="confirmpassword"
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.confirmpassword&&formik.errors.confirmpassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{setShowConfirmPassword(prevShowConfirmPassword=>!prevShowConfirmPassword)}}
                        onMouseDown={(e:any)=>{e.preventDefault();}}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn-primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Send
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  )
}

export default ResetPasswordPage