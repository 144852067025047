//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import { UserResponse } from "../types/User";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
};
type GetAllUsersResponse = {
  response_object:UserResponse[];
  total_records: number;
  };
  

// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getUsers:builder.mutation<
CommonResponseType & {data:GetAllUsersResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getUsers;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getUsersById:builder.query<CommonResponseType & {data:UserResponse},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.getUserById}${id}/`,
        method:"GET",
    })
}),

changeUserStatus: builder.mutation<
CommonResponseType & { data: UserResponse },
{ id: string , body: encryptedBody}
>({
query: ({ id,body }) => ({
  url: `${END_POINTS.changeUserStatus}${id}/`,
  method: "PUT",
  body
}),
}),

editUserById: builder.mutation<
      CommonResponseType & { data: UserResponse },
      { id: string; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateUsers}${id}/`,
        method: "PUT",
        body,
      }),
    }),

deleteUserById:builder.mutation<
CommonResponseType & {data:UserResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteUser}${id}/`,
        method:'DELETE'
    })
}),
getUserCSV: builder.query<any & { data: any }, {
    search:string;
  }>({
    query: ({search}) => {
      let url =`${END_POINTS.exportUsers}/?search=${search}`;
      const queryParams = [];
      // if (search) {
      //   queryParams.push(`search=${search}`);
      // }
      // if (queryParams.length > 0) {
      //   url += `?${queryParams.join('&')}`;
      // }
     return{ 
      url:url,
      method: "GET",}
    },
  }),

})
})

export const{
    useGetUsersMutation,
    useLazyGetUsersByIdQuery,
    useChangeUserStatusMutation,
    useEditUserByIdMutation,
    useDeleteUserByIdMutation,
    useLazyGetUserCSVQuery,
}=userApi;
