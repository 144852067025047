import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";

import { Rating } from 'react-simple-star-rating';
import { TestimonialResponse } from '../../types/testimonial';
import { useLazyGetTestimonialByIdQuery } from '../../services/testimonials';
const TestimonialDetail = () => {
  const{id}=useParams();
  const [getDataById]=useLazyGetTestimonialByIdQuery();
  const[testimonialData,setTestimonialData]=useState<TestimonialResponse|null>();
const navigate = useNavigate();
    const label = { inputProps: { "aria-label": "Switch demo" } };
    const getDataByIdList=async()=>{
      try{const response=await getDataById({id}).unwrap();
      if(response.status==200)
        {
         setTestimonialData(response?.data);
         console.log(response?.data);
        }}
        catch(error){
          console.log(error);
        }
    }
    useEffect(()=>{
      if(id)
        getDataByIdList();
    },[id])
    return (
      <>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Testimonial Details</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-testimonials");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
           
          <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img src={testimonialData?.profile_picture?.media_url?testimonialData?.profile_picture?.media_url:"/static/images/user_placeholder.png"} alt="" />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">First Name</Typography>
                  {(testimonialData?.first_name as string)?.length>20?
                  <Tooltip title={testimonialData?.first_name}><Typography component="p">{testimonialData?.first_name?.slice(0,20)+"..."}</Typography></Tooltip>
                  :<Typography component="p">{testimonialData?.first_name}</Typography>}
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">First Name (Arabic)</Typography>
                  {/* <Typography component="p">{testimonialData?.first_name_ar}</Typography> */}
                  {(testimonialData?.first_name as string)?.length>20?
                  <Tooltip title={testimonialData?.first_name_ar}><Typography component="p">{testimonialData?.first_name_ar?.slice(0,20)+"..."}</Typography></Tooltip>
                  :<Typography component="p">{testimonialData?.first_name_ar}</Typography>}
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Last Name</Typography>
                  {/* <Typography component="p">{testimonialData?.last_name}</Typography> */}
                  {(testimonialData?.last_name as string)?.length>20?
                  <Tooltip title={testimonialData?.last_name}><Typography component="p">{testimonialData?.last_name?.slice(0,20)+"..."}</Typography></Tooltip>
                  :<Typography component="p">{testimonialData?.last_name}</Typography>}
                </Box>
              
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
              <Box>
                  <Typography component="h5">Last Name (Arabic)</Typography>
                  {/* <Typography component="p">{testimonialData?.last_name_ar}</Typography> */}
                  {(testimonialData?.last_name_ar as string)?.length>20?
                  <Tooltip title={testimonialData?.last_name_ar}><Typography component="p">{testimonialData?.last_name_ar?.slice(0,20)+"..."}</Typography></Tooltip>
                  :<Typography component="p">{testimonialData?.last_name_ar}</Typography>}
                </Box>
              </Grid>

              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p">{testimonialData?.country_code+" "+testimonialData?.phone_no}</Typography>
                </Box>
              </Grid> */}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p">{testimonialData?.email}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Testimonial Status</Typography>
                  <Typography component="p">{testimonialData?.is_active?"Active":"InActive"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Rating</Typography>
                    <Rating
                    initialValue={testimonialData?.rating}
                    iconsCount={5}
                    size={23}
                    allowHover={false}
                    readonly
                    />
                    
                </Box>
              </Grid>
              <Grid item lg={12} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Message</Typography>
                  <Typography component="p">{testimonialData?.message}</Typography>
                </Box>
              </Grid>
              <Grid item lg={12} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Message (Arabic)</Typography>
                  <Typography component="p">{testimonialData?.message_ar}</Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    Position within the Company
                  </Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Date of Birth</Typography>
                  <Typography component="p">25/12/1999</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Business Email</Typography>
                  <Typography component="p">abdul@yopmail.com</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Website</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Tax Number</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Address</Typography>
                  <Typography component="p">Mohali,Punjab</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Zip code</Typography>
                  <Typography component="p">116059</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Credit Score</Typography>
                  <Typography component="p">500</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography component="h5">Bio</Typography>
                  <Typography component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
          
          </Card>
        </div>
      </>
    );
  };
  
export default TestimonialDetail