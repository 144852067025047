import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import { successToast, errorToast } from "../../../helpers";
import { usePostChangePasswordMutation } from "../../../services/auth";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";

const ChangePassword = () => {
  const navigate = useNavigate();
  const[changePassword,{isLoading}]=usePostChangePasswordMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [cnfmPassword, setCnfmPassword] = useState<boolean>(false);
const formik=useFormik({
  initialValues:{
    oldPassword:"",
    newPassword:"",
    confirmPassword:""
  },
  validationSchema:Yup.object({
    oldPassword:Yup.string()
    // .min(6,"Minimum 6 character are required")
    .required("Current password is required"),
    newPassword:Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
      'Password must be at least 8 characters long and include 1 uppercase, 1 lowercase, and 1 special character.'
    )
    .notOneOf([Yup.ref('oldPassword'),null],"New password can't be same as old password"),
    confirmPassword:Yup.string()
    .oneOf([Yup.ref('newPassword')],'Passwords should match')
    .required('Confirm password is required')
  }),
  onSubmit:async(values)=>{
  formik.setSubmitting(true);
  let body={
    old_password:values.oldPassword,
    new_password:values.newPassword
  }

  try{
    let encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
    const response=await changePassword(encryptedBody).unwrap();
    // const response=await changePassword(body).unwrap();
    if(response?.status===200)
      {
        successToast("Password changed successfully"||"");
        navigate('/dashboard');
      }

  }
  catch(error:any)
  {
    console.log(error);
    errorToast(error?.data?.message||"")
  }
  formik.setSubmitting(false);
  }
})
  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
    m={0}
    className='auth_logo'
    sx={{display:"flex",justifyContent:"center"}}
    >
  <figure className='logo_cntnr' style={{textAlign:"center"}}>
        <img src='/static/images/Whizzo.png' style={{padding:"2px",width:"70%",height:"70%"}} alt='Whizzo Admin Panel'/>
    
  </figure>
    </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/dashboard")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
             <ArrowBackIcon style={{color:"white"}}/>
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Change Password
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_auth">Old Password</Typography>
              <TextField
                // className="text_field"
                hiddenLabel
                placeholder="Old Password"
                fullWidth
                id="oldPassword"
                name="oldPassword"
                type={oldPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword}
                helperText={
                  formik.touched.oldPassword && formik.errors.oldPassword
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setOldPassword(!oldPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {oldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_auth">New Password</Typography>
              <TextField
                // className="text_field"
                placeholder="New Password"
                fullWidth
                id="newPassword"
                name="newPassword"
                type={cnfmPassword ? "text" : "password"}  
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setCnfmPassword(!cnfmPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {cnfmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_auth">Confirm Password</Typography>
              <TextField
                // className="text_field"
                placeholder="Confirm Password"
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                helperText={
                  formik.touched.confirmPassword && formik.errors.confirmPassword
                }
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn-primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default ChangePassword;
