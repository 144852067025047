import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { isDeleteAllowed } from "../../utils/permissonAllowed";

const RecievedNotifications = () => {
  function createData(
    CustomerName: string,
    CustomerUsername: string,
    CustomerEmail: string,
    title: string,
    message: string
  ) {
    return { CustomerName, CustomerUsername, CustomerEmail, title, message };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "abdulhussian67@gmail.com",
      "Title",
      "Message"
    ),
  ];

  return (
    <>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Recieved Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
             searchTerm={""}
             setDebouncedSearchTerm={()=>{}}
             value={""}
             onCross={() => {}}
             onChange={()=>{}}
           />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                 {isDeleteAllowed(7) &&  <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row.CustomerName}</TableCell>
                    <TableCell>{row.CustomerEmail}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    {isDeleteAllowed(7) && <TableCell>
                      <Box className="table_actions">
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </>
  );
};

export default RecievedNotifications;
