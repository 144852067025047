import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationsActive from "@mui/icons-material/NotificationsActive";
import CategoryIcon from "@mui/icons-material/Category";
import { Approval, AssignmentInd, EmojiEvents, Headphones, Payment, PrecisionManufacturing, Subscriptions } from "@mui/icons-material";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Analytics } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ChatIcon from "@mui/icons-material/Chat";
import TodayIcon from "@mui/icons-material/Today";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import StyleIcon from "@mui/icons-material/Style";
import { SIDEBAR_WIDTH } from "../constants";

import QuizIcon from "@mui/icons-material/Quiz";
import ReviewsIcon from "@mui/icons-material/Reviews";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SettingsIcon from "@mui/icons-material/Settings";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PaidIcon from "@mui/icons-material/Paid";
import Quiz from "@mui/icons-material/Quiz";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { PropsWithoutRef } from "react";
import useAuth from "../hooks/useAuth";
import { RolePermission, UseAuth } from "../types/useAuth";
import { ADMIN_ROLE_ID } from "../helpers/constants/role";
  interface Props {
    mobileOpen: boolean;
    setMobileOpen:(arg0:boolean)=>void;

    handleSidebarToggle: () => void;
  }
  
  export default function Sidebar({
    mobileOpen,
    setMobileOpen,
    handleSidebarToggle,
  }: PropsWithoutRef<Props>) {
    const location = useLocation();
    const navigate = useNavigate();
    const user:UseAuth=useAuth();
    console.log("USER:LL",user);
    type SidebarProp={
      icon:any,
      name:string,
      route:string
    }
    const sideBarData:SidebarProp[]=[
      {
        icon:<DashboardIcon/>,
        name:'Dashboard',
        route:"/dashboard",
      },
      {
        icon:<PeopleIcon/>,
        name:'Manage Users',
        route:"/manage-users",
      },
      {
        icon: <AssignmentInd/>,
        name:'Manage Testimonials',
        route:"/manage-testimonials",
      },
      {
        icon:<Approval/>,
        name:'Manage Purpose',
        route:"/manage-purposes",
      },
      {
        icon:<PrecisionManufacturing/>,
        name:'Manage Abilities',
        route:"/manage-ability",
      },
      {
        icon: <EmojiEvents/>,
        name:'Manage Achievement',
        route:"/manage-achievement",
      },
      {
        icon:<Subscriptions/>,
        name:'Manage Subscription',
        route:"/manage-subscription",
      },
      {
        icon: <Payment/>,
        name:'Manage Payment',
        route:"/payment",
      },
      {
        icon: <PeopleIcon/>,
        name:'Manage Sub-Admin',
        route:"/manage-subAdmin",
      },
      {
        icon:<NotificationsActive/>,
        name:'Notifications Management',
        route:"/manage-notifications",
      },
      {
        icon:<SummarizeTwoToneIcon/>,
        name:'Manage CMS',
        route:"/cms",
      },
      {
        icon:<Headphones/>,
        name:'Manage Customer Support',
        route:"/customer-support",
      },
      {
        icon:<Analytics/>,
        name:'Analytics',
        route:"/analytics",
      },
      {
        icon:<Quiz/>,
        name:'Manage FAQ',
        route:"/faq",
      },
    ]
  //   const drawer = (
  //     <>
  //       <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
  //         <figure>
  //           <img
  //             src="/static/images/Whizzo.png"
  //             alt=""
  //             style={{borderRadius:"50%"}}
  //             onClick={() => navigate("/dashboard")}
  //           />
  //         </figure>
  //         <IconButton
  //           sx={{
  //             position: "absolute",
  //             top: "10px",
  //             right: "10px",
  //             color: "white",
  //             display: { lg: "none" },
  //           }}
  //           onClick={handleSidebarToggle}
  //         >
  //           <CloseIcon />
  //         </IconButton>
  //       </Toolbar>
  
  //       <List sx={{ flexGrow: 1 }} className="sidebr-lst">
          
          
  //         <ListItemButton
  //           className="lst-itm"
  //           selected={location.pathname === "/dashboard"}
  //           onClick={() => {
  //             setMobileOpen(false);navigate("/dashboard")}}
  //         >
  //           <ListItemIcon>
  //             <DashboardIcon />
  //           </ListItemIcon>
  //           <ListItemText className="lstitm-txt" primary="Dashboard" />
  //         </ListItemButton>




  //         {/* users */}
  //         <ListItemButton
  //           className="lst-itm"
  //           selected={location.pathname === "/manage-users"}
  //           onClick={() => {
  //             setMobileOpen(false);navigate("/manage-users")}}
          
  //         >
  //           <ListItemIcon>
  //             <PeopleIcon />
  //           </ListItemIcon>
  //           <ListItemText className="lstitm-txt" primary="Manage Users" />
  //         </ListItemButton>

  //         <ListItemButton
  //           className="lst-itm"
  //           selected={location.pathname === "/manage-testimonials"}
  //           onClick={() => {
  //             setMobileOpen(false);navigate("/manage-testimonials")}}
  //         >
  //           <ListItemIcon>
  //             <AssignmentInd />
  //           </ListItemIcon>
  //           <ListItemText className="lstitm-txt" primary="Manage Testimonials" />
  //         </ListItemButton>

  //         <ListItemButton
  //           className="lst-itm"
  //           selected={location.pathname === "/manage-purposes"}
  //           onClick={() => {
  //             setMobileOpen(false);navigate("/manage-purposes")}}
  //         >
          
  //           <ListItemIcon>
  //             <Approval />
  //           </ListItemIcon>
  //           <ListItemText className="lstitm-txt" primary="Manage Purposes" />
  //         </ListItemButton>

  //         <ListItemButton
  //           className="lst-itm"
  //           selected={location.pathname === "/manage-ability"}
  //           onClick={() => {
  //             setMobileOpen(false);navigate("/manage-ability")}}
  //         >
          
  //           <ListItemIcon>
  //             <PrecisionManufacturing/>
  //           </ListItemIcon>
  //           <ListItemText className="lstitm-txt" primary="Manage Ability" />
  //         </ListItemButton>

  //         <ListItemButton
  //           className="lst-itm"
  //           selected={location.pathname === "/manage-achievement"}
  //           onClick={() =>{
  //             setMobileOpen(false);navigate("/manage-achievement")}}
          
  //         >
  //           <ListItemIcon>
  //             <EmojiEvents />
  //           </ListItemIcon>
  //           <ListItemText className="lstitm-txt" primary="Manage Achievement" />
  //         </ListItemButton>


  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-subscription"}
  //         onClick={() => {
  //           setMobileOpen(false);navigate("/manage-subscription")}}
  //       >
  //         <ListItemIcon>
  //           <Subscriptions />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Subscription" />
  //       </ListItemButton>



  //  {/* manage payment */}
  //  <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/payment"}
  //         onClick={() => {
  //           setMobileOpen(false);navigate("/payment")}}
  //       >
  //         <ListItemIcon>
  //           <Payment />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Payment" />
  //       </ListItemButton>

  // {/* sub-admin */}
  // <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-subAdmin"}
  //         onClick={() => {
  //           setMobileOpen(false);navigate("/manage-subAdmin")}}
  //       >
  //         <ListItemIcon>
  //           <PeopleIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Sub-Admin" />
  //       </ListItemButton>
       
  //          {/* manage notification */}
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-notifications"}
  //         onClick={() => {
  //           setMobileOpen(false);navigate("/manage-notifications")}}
  //       >
  //         <ListItemIcon>
  //           <NotificationsActive />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Notifications" />
  //       </ListItemButton>

  //          {/*cms*/}
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/cms"}
  //         onClick={() => {
  //           setMobileOpen(false);navigate("/cms")}}
  //       >
  //         <ListItemIcon>
  //           <SummarizeTwoToneIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage CMS" />
  //       </ListItemButton>

  //          {/* customer support */}
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/customer-support"}
  //         onClick={() => {
  //           setMobileOpen(false);navigate("/customer-support")}}
  //       >
  //         <ListItemIcon>
  //           <Headphones />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Customer Support" />
  //       </ListItemButton>
        
  //          {/*analytics */}
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/analytics"}
  //         onClick={() => {
  //           setMobileOpen(false);navigate("/analytics")}}
  //       >
  //         <ListItemIcon>
  //           <Analytics />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Analytics" />
  //       </ListItemButton>

  //          {/* faq */}
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/faq"}
  //         onClick={() => {
  //           setMobileOpen(false);navigate("/faq")}}
  //       >
  //         <ListItemIcon>
  //           <Quiz />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage FAQs" />
  //       </ListItemButton>
          
          
  //       </List>
  //     </>
  //   );
  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure>
          <img
            src="/static/images/Whizzo.png"
            alt=""
            style={{borderRadius:"50%"}}
            onClick={() => navigate("/dashboard")}
          />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        {
          sideBarData?.map((data:SidebarProp,index:number)=>(
          ((data?.name=='Dashboard')||(user?.role==ADMIN_ROLE_ID)||(user?.role_permission?.find((data2:RolePermission)=>(data2?.module==data?.name && data2?.can_view))))&&
          (<ListItemButton
          className="lst-itm"
          selected={location.pathname.includes(data.route)}
          onClick={() => {
            setMobileOpen(false);navigate(data.route)}}
        >
          <ListItemIcon>
            {data.icon}
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary={data.name} />
        </ListItemButton>)
      ))
}
      </List>
    </>
  );
    return (
      <Box
        component="nav"
        sx={{ flexShrink: { lg: 0 } }}
        className="sidebar-main-div"
      >
        <Drawer
          className="sidebar_drawer"
          variant="temporary"
          open={mobileOpen}
          onClose={handleSidebarToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: SIDEBAR_WIDTH,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          className="sidebar_drawer"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              width: SIDEBAR_WIDTH,
              boxSizing: "border-box",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    );
  }
  