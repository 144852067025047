import { ReactNode, useEffect, useRef, useState } from "react";
import { Container, Box } from "@mui/material";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { SIDEBAR_WIDTH } from "../constants";
// import "./Layout.scss";
import { Outlet, useNavigate } from "react-router-dom";
// import useAuth from "../hooks/useAuth";
import { getFromStorage, STORAGE_KEYS } from "../helpers";
// import { useAppDispatch } from "../hooks/store";
// import { setCredentials } from "../reducers/authSlice";
// import { useLazyGetUserQuery } from "../services/auth";
import Loader from "../helpers/Loader";
import { toast } from "react-toastify";
import { useAppDispatch } from "../hooks/store";
import useAuth from "../hooks/useAuth";
import { setCredentials } from "../reducers/authSlice";
import { useLazyGetUserQuery } from "../services/auth";
import LoadingBar from "react-top-loading-bar";
import secureLocalStorage from "react-secure-storage";
// import { firebaseCloudMessaging } from "../utils/firebase";

function MainContainer(){
  const ref:any = useRef(null)
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isLoading,setIsLoading]=useState(false)
  const [getUser] = useLazyGetUserQuery();
  const handleSidebarToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const user=useAuth();
  const getUserDetails = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()
    const token = getFromStorage(STORAGE_KEYS.token);

    // if (token) {
      try {
        const result = await getUser({}).unwrap();
        if (result?.status === 200 && token) {
          secureLocalStorage.setItem("permissions",JSON.stringify(result?.data?.role_permission));
          secureLocalStorage.setItem("role",result?.data?.role);
          dispatch(
            setCredentials({
              user: result?.data,
              token: token,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
      ref?.current?.complete();
    setIsLoading(false);

    // }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);

    // if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
      getUserDetails();
    // }
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Loader isLoad={isLoading}/>
      <LoadingBar color="#7048c1" ref={ref} shadow={true}  />
      <Topbar handleSidebarToggle={handleSidebarToggle} />
    <Sidebar
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        handleSidebarToggle={handleSidebarToggle}
      />
      <Box
        className="dashboard_main mn-lyout"
        component="main"
        sx={{
          marginLeft: "auto",
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
        }}
      >
        <div className="sub-layout" style={{ paddingTop: 100 }}>
          <Outlet/>
        </div>
      </Box>
    </Box>
  );
}
export default MainContainer;
