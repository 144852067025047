import LoginPage from "./auth/login";
import ForgotPasswordPage from "./auth/forgotPassword"
import DashboardPage from "./dashboard";
import VerifyOtp from "./auth/otp";
import ResetPasswordPage from "./auth/resetPassword";
import ManageUsers from "./users";
import Profile from "./profile";
import ChangePassword from "./auth/changePassword";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCms from "./manageCms";
import ManageFaq from "./manageFaq";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import Analytics from "./analytics";
import CustomerSupport from "./customerSupport";
import Payment from "./managePayment";
import PaymentDetail from "./managePayment/details";
import ManageSubscription from "./manageSubScription";
import SubscriptionDetails from "./manageSubScription/details";
import Addsubscription from "./manageSubScription/add";
import ManageTestimonials from "./manageTestimonials";
import ManagePurposes from "./managePurposes";
import AddPurpose from "./managePurposes/add";
import AddTestimonial from "./manageTestimonials/add";
import ManageAbility from "./manageAbilities";
import ManageAchievement from "./manage-achievements";
import AddSubject from "./manage-achievements/addSubject";
import RecievedNotifications from "./manageNotification/receivedNotification";
import SubAdminDetail from "./manageSubAdmin/detail";
import TestimonialDetail from "./manageTestimonials/detail";
const Page={
    LoginPage,
    ForgotPasswordPage,
    DashboardPage,
    VerifyOtp,
    ResetPasswordPage,
    ManageUsers,
    Profile,
    ChangePassword,
    ManageSubAdmin,
    AddSubAdmin,
    ManageCms,
    ManageFaq,
    ManageNotifications,
    AddNotification,
    Analytics,
    CustomerSupport,
    Payment,
    RecievedNotifications,
    PaymentDetail,
    ManageSubscription,
    SubscriptionDetails,
    Addsubscription,
    ManagePurposes,
    AddPurpose,

    ManageTestimonials,
    AddTestimonial,
    TestimonialDetail,
    ManageAbility,
    ManageAchievement,
    AddSubject,
    SubAdminDetail
}

export default Page;