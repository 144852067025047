import React, { useEffect, useRef, useState } from 'react'
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { Approval } from '@mui/icons-material';
import { showError, showToast } from '../../constants/toast';
import useAuth from '../../hooks/useAuth';
import { useEditUserByIdMutation, useDeleteUserByIdMutation } from '../../services/user';
import { CommonBody } from '../../types/General';
import { UserResponse } from '../../types/User';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import { useChangePurposeStatusMutation, useDeletePurposeByIdMutation, useGetPurposesMutation } from '../../services/purpose';
import { isValidInput } from '../../utils/validations';
import { Pagination } from '../../components';
import WarnModal from '../../components/modals/WarnModal';
import { handleDelete } from '../../utils/commonFunctions';
import { PurposeResponse } from '../../types/purpose';
import Loader from '../../helpers/Loader';
import LoadingBar from 'react-top-loading-bar';
import { isDeleteAllowed, isEditAllowed } from '../../utils/permissonAllowed';
const ManagePurposes = () => {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const user = useAuth();
  const ref:any = useRef(null)

  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [purpose, setPurpose] = useState<PurposeResponse[]>([]);

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getPurpose] = useGetPurposesMutation();
  const [updatePurposetatus]:any = useChangePurposeStatusMutation();
  const [deleteById] = useDeletePurposeByIdMutation();
  // const [getUserCsvMethod] = useLazyGetUserCSVQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Purpose"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getPurposeList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const body={
        start:page,
        length:10,
        search:debouncedSearchTerm.trim(),
      }
      console.log(body);
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getPurpose(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response);
        setPurpose(response?.data?.response_object || []);
        setTotalCount(response?.data?.total_records);
      } else {
        setPurpose([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };

  // const handleExportCsv = async () => {
  //   try {
  //     const res = await getUserCsvMethod({ search: searchTerm }).unwrap();
  //     if (res?.statusCode === 200) {
  //       window.open(res?.data || "");
  //     }
  //   } catch (error: any) {
  //     showError(error?.message);
  //   }
  // };
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        is_active:!purpose[index]?.is_active
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updatePurposetatus({ id: `${purpose[index].id}` , body:encryptedData}).unwrap();
      showToast(purpose[index]?.is_active?"Purpose disabled successfully":"Purpose enabled successfully");
      // showToast(response?.message || "User Updated Successfully");
      setPurpose(prevData=>{
        let temp=[...prevData];
        temp[index]={...temp[index],is_active:!temp[index].is_active};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getPurposeList();
  }, [debouncedSearchTerm, page]);
  console.log(purpose);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);




  return (
    <>
      <div className="main_loyout">
        <Loader isLoad={isLoading}/>
        <LoadingBar color="#7048c1" ref={ref} shadow={true}  />

        <div className="dashboard">
          <h1 className="mn_hdng">Manage Purpose</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          {isEditAllowed(2) &&   <Box className="cards_header_right">
              <Button className="btn btn_primary"
              onClick={()=>navigate('/manage-purposes/add')}
              >
               Add Purpose
              </Button>
            </Box>}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  {/* <TableCell>Picture</TableCell> */}
                  {/* <TableCell>Customer Id</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Phone No.</TableCell>
                  <TableCell>Email</TableCell> */}
                  <TableCell>Purpose Name</TableCell>
                  {/* <TableCell>Purpose Detail</TableCell> */}
                  <TableCell>Status</TableCell>

                  { (isEditAllowed(2) || isDeleteAllowed(2)) && <TableCell>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {purpose?.length?purpose.map((row, i) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{(page-1)*10+i + 1}</TableCell>
                    {/* <TableCell><img style={{width:"70px",height:"70px",borderRadius:"50%"}} src={row.picture}/></TableCell> */}

                    {/* <TableCell>{row.customerId}</TableCell>
                    <TableCell>{row.firstname}</TableCell>
                    <TableCell>{row.lastname}</TableCell>

                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell> */}
                    <TableCell>{row.name}</TableCell>
                    {/* <TableCell>{row.detail}</TableCell> */}

                    <TableCell>
                      <Switch {...label} 
                      checked={row.is_active} 
                      size="small" 
                      disabled={isEditAllowed(2)?false:true}
                      onChange={()=>handleStatusChange(i)}
                      />
                    </TableCell>
                    { (isEditAllowed(2) || isDeleteAllowed(2)) &&
                    <TableCell>
                     <Box className="table_actions">
                        {/* <IconButton
                          onClick={() => navigate("/manage-testimonials/details/id")}
                        >
                          <VisibilityIcon />
                        </IconButton> */}
                       {isEditAllowed(2) && 
                      <Tooltip title="Edit">
                         <IconButton
                            onClick={() =>
                              navigate("/manage-purposes/edit/"+row.id)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                      </Tooltip>
                        }
                        {isDeleteAllowed(2) && 
                       <Tooltip title="Delete">
                          <IconButton onClick={() =>{
                            setSelectedId(row.id);
                            console.log("ssssssssssssssssssss",row?.id);
                            setOpen(true);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                       </Tooltip>
                        }
                      </Box>
                    </TableCell>}
                  </TableRow>
                )):<TableRow>
                <TableCell align={"center"}colSpan={5}>
                  No Purpose Found
                  </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={purpose}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
         <WarnModal
        setOpen={setOpen}
        open={open}
        name={"purpose"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getPurposeList)}
      />
      </div>
    </>
  );
};

export default ManagePurposes