import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useAddNotificationsMutation, useGetUsersForNotificationMutation } from "../../services/notification";
import { showError, showToast } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../helpers/Loader";

const AddNotification = () => {
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState<string>("All");
  const[isLoading,setIsLoading]=useState(false);
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };
  const [selectAll,setSelectAll]=useState(false);
  const [inputValue,setInputValue]=useState("");
  const[users,setUsers]=useState<any>([]);
  const [getUsers]=useGetUsersForNotificationMutation();
  const getUsersList = async () => {
    setIsLoading(true);

    try {
      const body={
        // start:1,
        // length:100000,
        // search:"",
      }
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getUsers(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response);
        setUsers(response?.data|| []);
      } else {
        setUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);

  };
  useEffect(()=>{
    getUsersList();

  },[])
const [notificationData,setNotificationData]=useState({
  notification_title:"",
  notification_message:"",
  notification_title_ar:"",
  notification_message_ar:"",
})
const [addNotification]=useAddNotificationsMutation();
const addNotificationData=async(e:any)=>{
  e.preventDefault();
  if(notificationData?.notification_title.trim()=="")
    {
      showError("Notification title is required")
      return;
    }
  if(notificationData?.notification_message.trim()=="")
    {
      showError("Notification message is required")
      return;
    }
  if(notificationData?.notification_title_ar.trim()=="")
    {
      showError("Notification title is required")
      return;
    }
  if(notificationData?.notification_message_ar.trim()=="")
    {
      showError("Notification message is required")
      return;
    }
    if((value?.length==0 && !selectAll) )
      {
        showError("Please select users to send notification")
        return
      }
  const emails=value.map((data:any)=>data?.email);
const body={
    ...notificationData,
    notification_type:2,
    notification_for:emails,
}
const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
  try{
const response=await addNotification(encryptedBody).unwrap();
if(response?.status==200)
  {
    showToast("Notification sent successfully")
    navigate("/manage-notifications");
    
  }
  }
  catch(error:any)
  {
    showError("Notification not sent")
    console.log(error);
    
  }
}
// const filterOptions = createFilterOptions({
//   stringify: (option:any) => `${option.first_name} ${option.last_name} ${option.email}`,
// });
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
const [value,setValue]=useState<any>([]);
// const icon = <span />;
// const checkedIcon = <span />;
const filterOptions = useMemo(() => createFilterOptions({
  stringify: (option:any) => `${option.first_name} ${option.last_name} ${option.email}`,
}), []);

const handleInputChange = (event:any, newInputValue:any) => {
  setInputValue(newInputValue);
  console.log('Input Value:', newInputValue);
};

  return (
    <>
      <>
        <div className="main_loyout">
          <Loader isLoad={isLoading}/>
          <div className="dashboard">
            <h1 className="mn_hdng">Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={addNotificationData}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      value={notificationData?.notification_title}
                      onChange={(e)=>{
                        if(e.target.value==" ") return;
                        if(e.target.value.length<=50)
                          setNotificationData((prev)=>({...prev,notification_title:e.target.value}))
                        if(e.target.value.length>50)
                          showError("Notification title can't exceed 50 characters")

                      }}
                      placeholder="Title"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Title (Arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      value={notificationData?.notification_title_ar}
                      onChange={(e)=>{
                        if(e.target.value==" ") return;
                        if(e.target.value.length<=50)
                          setNotificationData((prev)=>({...prev,notification_title_ar:e.target.value}))
                        if(e.target.value.length>50)
                          showError("Notification title (Arabic) can't exceed 50 characters")

                      }}
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Message
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      value={notificationData?.notification_message}
                      onChange={(e)=>{
                        if(e.target.value==" ") return;
                        if(e.target.value.length<=500)
                          setNotificationData((prev)=>({...prev,notification_message:e.target.value}))
                        if(e.target.value.length>500)
                          showError("Notification message can't exceed 500 characters")

                      }}
                      multiline
                      className="text_field"
                      placeholder="Message"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Message (Arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                            multiline
                            value={notificationData?.notification_message_ar}
                      onChange={(e)=>{
                        if(e.target.value==" ") return;
                        if(e.target.value.length<=500)
                          setNotificationData((prev)=>({...prev,notification_message_ar:e.target.value}))
                        if(e.target.value.length>500)
                          showError("Notification message (Arabic) can't exceed 500 characters")

                      }}
                      className="text_field"
                      placeholder="Message"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Receiver
                    </Typography>
                    {/* <FormControl sx={{ width: "100%" }}>
                      <NativeSelect
                        fullWidth
                        defaultValue={0}
                        variant="outlined"
                        inputProps={{
                          name: "action",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={0}>Select</option>
                        <option value={10}>IOS</option>
                        <option value={20}>Android</option>
                        <option value={30}>All</option>
                      </NativeSelect>
                    </FormControl> */}
                    {/* <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        // className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={receiver}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleReceiverChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="User 1">User 1</MenuItem>
                        <MenuItem value="User 2">User 2</MenuItem>
                        <MenuItem value="User 3">User 3</MenuItem>
                      </Select>
                    </FormControl> */}
                  
                {/* <Autocomplete
        
      multiple
      id="checkboxes-tags-demo"
      options={users}
      onChange={(e,value)=>{
        console.log(value);
        setValue(value);
      }}
      value={value}
      disabled={selectAll}
      disableCloseOnSelect
      // filterOptions={filterOptions}
      getOptionLabel={(option:any) => (option.first_name)}
      renderOption={(props, option, { selected }) => {
        const { ...optionProps } = props;
        return (
          <li key={option.id} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}

            />
            {(option.first_name+" "+option.last_name+" ("+option.email+")")}
          </li>
        );
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);}}
     className="autocomplete"
      style={{ width: 550,border:"none" }}
      renderInput={(params) => (
        <TextField {...params} 
        style={{border:"none"}}  
        sx={{border:"none"}}  
        placeholder="Select users" />
      )}
    /> */}
      
{/*    
 <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={users}
        onChange={(event, newValue) => {
          console.log('Selected Value:', newValue);
          setValue(newValue);
        }}
        onInputChange={handleInputChange}
        value={value}
        inputValue={inputValue}
        disabled={selectAll}
        disableCloseOnSelect
        getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.email})`}
        filterOptions={filterOptions}
        renderOption={(props, option, { selected }) => (
          <li key={option.id} {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option.first_name} ${option.last_name} (${option.email})`}
          </li>
        )}
        className="autocomplete"
        style={{ width: 550, border: "none" }}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ border: "none" }}
            sx={{ border: "none" }}
            placeholder="Select users"
          />
        )}
      /> */}
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={users}
        onChange={(event, newValue) => {
          console.log('Selected Value:', newValue);
          setValue(newValue);
        }}
        onInputChange={handleInputChange}
        value={value}
        inputValue={inputValue}
        disabled={selectAll}
        disableCloseOnSelect
        getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.email})`}
        filterOptions={filterOptions}
        renderOption={(props, option, { selected }) => (
          <li key={option.id} {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option.first_name} ${option.last_name} (${option.email})`}
          </li>
        )}
        className="autocomplete"
        style={{ width: 550, border: "none" }}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ border: "none" }}
            sx={{ border: "none" }}
            placeholder="Select users"
          />
        )}
      />
       <Typography  style={{display:"flex",alignItems:"center"}}>
                    <Checkbox id="all" checked={selectAll} onChange={()=>{
                      setSelectAll(!selectAll)
                      setValue([]);
                      }}/>  <label htmlFor="all">Select All</label>
                    </Typography>

    </Grid>
    </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </>
    </>
  );
};

export default AddNotification;
