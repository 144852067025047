import React, { useEffect, useRef, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { AssignmentInd } from "@mui/icons-material";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import { useGetPurposesMutation, useChangePurposeStatusMutation, useDeletePurposeByIdMutation } from "../../services/purpose";
import { CommonBody } from "../../types/General";
import { PurposeResponse } from "../../types/purpose";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useChangeTestimonialStatusMutation, useDeleteTestimonialByIdMutation, useGetTestimonialsMutation } from "../../services/testimonials";
import { TestimonialResponse } from "../../types/testimonial";
import { isValidInput } from "../../utils/validations";
import Loader from "../../helpers/Loader";
import WarnModal from "../../components/modals/WarnModal";
import { Pagination } from "../../components";
import { handleDelete } from "../../utils/commonFunctions";
import LoadingBar from "react-top-loading-bar";
import { isDeleteAllowed, isEditAllowed } from "../../utils/permissonAllowed";

const ManageTestimonials = () => {
  const navigate = useNavigate();
  function createData(
    picture:string,
    firstname: string,
    lastname: string,
    email: string,
    message:string,
  ) {
    return {
      picture,
      firstname,
      lastname,
      email,
      message
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "https://randomuser.me/api/portraits/men/49.jpg",
      "Abdul",
      "Hussian",
      "abdulhussian67@gmail.com",
      'very great site',
    ),
  ];
  const user = useAuth();
  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [testimonial, setTestimonial] = useState<TestimonialResponse[]>([]);
  const ref:any = useRef(null)

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getAllData] = useGetTestimonialsMutation();
  const [updateDataStatus]:any = useChangeTestimonialStatusMutation();
  const [deleteById] = useDeleteTestimonialByIdMutation();
  // const [getUserCsvMethod] = useLazyGetUserCSVQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Purpose"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getTestimonialList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const body={
        start:page,
        length:10,
        search:debouncedSearchTerm.trim(),
      }
      console.log(body);
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getAllData(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response);
        setTestimonial(response?.data?.response_object || []);
        setTotalCount(response?.data?.total_records);
      } else {
        setTestimonial([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };

  // const handleExportCsv = async () => {
  //   try {
  //     const res = await getUserCsvMethod({ search: searchTerm }).unwrap();
  //     if (res?.statusCode === 200) {
  //       window.open(res?.data || "");
  //     }
  //   } catch (error: any) {
  //     showError(error?.message);
  //   }
  // };
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        is_active:!testimonial[index]?.is_active
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateDataStatus({ id: `${testimonial[index].id}` , body:encryptedData}).unwrap();
      if(response?.status==200)
      { showToast(testimonial[index]?.is_active?"Testimonial disabled successfully":"Testimonial enabled successfully");
      setTestimonial(prevData=>{
        let temp=[...prevData];
        temp[index]={...temp[index],is_active:!temp[index].is_active};
        return temp;
      });}
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getTestimonialList();
  }, [debouncedSearchTerm, page]);
  console.log(testimonial);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);


  return (
    <>
      <div className="main_loyout">
        <Loader isLoad={isLoading}/>
        <LoadingBar color="#7048c1" ref={ref} shadow={true}  />

        <div className="dashboard">
          <h1 className="mn_hdng">Manage Testimonials</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            {isEditAllowed(1) && <Box className="cards_header_right">
              <Button className="btn btn_primary"
              onClick={()=>navigate('/manage-testimonials/add')}
              >
                Add Testimonial
              </Button>
            </Box>}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Picture</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {testimonial?.length?testimonial.map((row:TestimonialResponse, i:number) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell><img style={{width:"50px",height:"50px",borderRadius:"50%"}} src={row?.profile_picture?.media_url?row.profile_picture.media_url:"static/images/user_placeholder.png"}/></TableCell>

                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.email}</TableCell>

                    <TableCell>
                      <Switch {...label} 
                      checked={row.is_active}
                      disabled={isEditAllowed(1)?false:true}
                      onChange={()=>handleStatusChange(i)}
                      size="small" />
                    </TableCell>

                    <TableCell>
                      <Box className="table_actions">
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => navigate("/manage-testimonials/details/"+row.id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                       {isEditAllowed(1) &&  
                       
                      <Tooltip title="Edit">
                         <IconButton
                            onClick={() =>
                              navigate("/manage-testimonials/edit/"+row.id)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                      </Tooltip>

                        }
                       {isDeleteAllowed(1) && 
                    <Tooltip title="Delete">
                         <IconButton onClick={()=>{
                            setSelectedId(row?.id);
                            setOpen(true);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                    </Tooltip>
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                )):<TableRow>
                <TableCell align={"center"}colSpan={7}>
                  No Testimonial Found
                  </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={testimonial}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
         <WarnModal
        setOpen={setOpen}
        open={open}
        name={"testimonial"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getTestimonialList)}
      />
      </div>
    </>
  );
};

export default ManageTestimonials