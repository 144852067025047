import React from 'react'
import MainContainer from '../../layout/MainContainer'
import { Card, Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const PaymentDetail = () => {
  const navigate=useNavigate();
  return (
   <> 
    <div className="main_loyout">
    <div className="dashboard">
            <h1 className="mn_hdng">Payment Details</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/payment");
              }}
            >
              Back
            </Button>
          </div>
        <Card className="cards">
                  
        <Grid item xs={10} className="view_box_list">
            <Grid container spacing={6} style={{padding:"30px"}}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box> 
                  <Typography component="h5">Order Id</Typography>
                  <Typography component="p">123123</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Transaction Id</Typography>
                  <Typography component="p">12345</Typography>
                </Box>
              </Grid><Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Payment Recieved</Typography>
                  <Typography component="p">Credit Card</Typography>
                </Box>
              </Grid><Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Customer Name</Typography>
                  <Typography component="p">Abdul Hussain</Typography>
                </Box>
              </Grid><Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p">abdulhussain@gmail.com</Typography>
                </Box>
              </Grid><Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone Number</Typography>
                  <Typography component="p">+9123123123</Typography>
                </Box>
              </Grid>
              
             
             
            </Grid>
          </Grid>
                     
        <Grid container spacing={1}>
</Grid>
                   
             
       </Card>
</div></>
  )
}

export default PaymentDetail