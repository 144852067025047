import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showError, showToast } from "../../constants/toast";
import { errorToast, getFromStorage, STORAGE_KEYS, successToast } from "../../helpers";
import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";
import { getToken, setCredentials } from "../../reducers/authSlice";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { UploadMedia } from "../../utils/mediaUpload";
import { usePutUpdateProfileMutation } from "../../services/profile";
import Loader from "../../helpers/Loader";
import { Camera, Cancel } from "@mui/icons-material";
import { isNumber, isEnglishString } from "../../utils/validations";

const Profile = () => {
  const user:any = useAuth();
  console.log("GGGGGGGGGGGGGGGGGGGGGGGGGGG",user);
    const token=useSelector(getToken);
    const dispatch=useAppDispatch();
    const navigate = useNavigate();
    const [updateprofile] :any= usePutUpdateProfileMutation();
    const [country_code, setPhoneCode] = useState("+91");
    const [profile_picture, setProfilePicture] = useState<any>(null);
    console.log(profile_picture);
    const [imageId, setImageId] = useState(null);
    const[phoneDisplay,setPhoneDisplay]=useState("");
    const[isLoading,setIsLoading]=useState(false);
    const handleImageUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const files = event.target.files;
      const file = files?.length ? files[0] : null;
  
      try {
        if (!file) {
  
          formik.setFieldValue("profile_picture", "");
          setProfilePicture("");
          setImageId(null);
          return;
        }
        const allowedExtensions = ["png","jpg","jpeg"];
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        console.log("sssssssssssssssssssss",fileExtension);
        if (!allowedExtensions.includes(fileExtension||"")) {
           setIsLoading(false);
          showError("Invalid file format: only png, jpg images are allowed");
          return;
        }
        setIsLoading(true);
        const res = await UploadMedia(file);
  //      console.log(res,'lklk');
        
        if (res?.status=== 200) {
          console.log(res?.data[0])
          formik.setFieldValue("profile_picture", res?.data[0]?.media_url);
          setProfilePicture(res?.data[0]?.media_url);
          console.log("jjjjjjjjjjjjjjj",res?.data[0]?.id);
          setImageId(res?.data[0]?.id)
          showToast("Profile picture uploaded successfully")
        } else {
          errorToast(res?.message);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        errorToast("Error uploading image. Please try again.");
      }
      setIsLoading(false);
    };
    console.log("iiiiiiiiiiiiiiiiiiiiii",imageId);
  //console.log(user);
  //  console.log("ussser", user);
    const formik:any = useFormik({
      enableReinitialize: true,
      initialValues: {
        email: user?.email || "",
        name: user?.name || "",
        phone_no: user?.phone_no || "",
        profile_picture: user?.profile_picture?.media_url || "",
        address:user?.address||"",
        country_code: country_code||"+91",
  
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .required("Email is required!")
          .matches(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            "Enter a valid email address!"
          ),
        name: Yup.string()
          .required("First Name is required")
          .min(2, "Minimum 2 characters are required")
          .max(80, "Maximum 60 characters are allowed"),
        phone_no: Yup.string()
          .required("Phone number is required")
          .min(6, "Phone number must be at least 6 digits")
          .max(16, "Phone number must be at least 16 digits"),
        // address: Yup.string()
        //   .required("Address is required")
        //   .max(100, "Maximum 100 characters are alloweds")
  
  
  
  
      }),
      onSubmit: async (values, { setSubmitting }) => {
        if (!formik.isValid) return;
        try {
         setSubmitting(true);
  
          let body: {
            // email: string;
            name: string;
            country_code: string;
            address:string;
            phone_no: string;
            profile_picture?: any;
          } = {
            // email: values.email,
            name: values?.name||user?.name,
            address: values?.address||user?.address,
            phone_no: values?.phone_no||user?.phone_no,
            country_code: values?.country_code||user?.country_code,
            profile_picture: imageId||user?.profile_picture?.id
          };
  //        console.log(body, "body")
          // if (values.profile_picture) {
          //   body.profile_picture = values.profile_picture;
          // }
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateprofile(encryptedBody).unwrap();
          if (response?.status === 200) {
            dispatch(
              setCredentials({
                user:{...user,name:values.name,profile_picture:{media_url:profile_picture},phone_no:values.phone_no,country_code:values.country_code},
                token:getFromStorage(STORAGE_KEYS.token)
              })
            )
            successToast("Profile Updated Successfully.");
            const token = getFromStorage(STORAGE_KEYS.token);
            navigate("/dashboard");
          }
        } catch (error: any) {
          errorToast("error");
        } finally {
          setSubmitting(false);
        }
      },
  
  
    });
  
  
    const handleChangePhone = (phone_no: any, country: any) => {
      formik.setFieldValue(
        "phone_no",
        phone_no.replace(country.dialCode, "")
      );
      setPhoneCode(country.dialCode);
      setPhoneDisplay(phone_no);
    };
    useEffect(() => {
      setPhoneCode(user?.country_code || "");
      setProfilePicture(user?.profile_picture?.media_url || "");
      setPhoneDisplay(user?.country_code+user?.phone_no||"");
    }, [user]);
  

  return (
    <div className="main_layout">
    <Loader isLoad={isLoading}/>
    <div className="dashboard">
    <h1 className="mn_hdng">Edit Profile</h1>
    </div>
    <Card className="cards">
      <form onSubmit={formik.handleSubmit}>
        <CardContent sx={{ p: 1 }}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
                <Typography className="custom_label">Image</Typography>
                {formik.values.profile_picture ? (
                  <div className="upload_image_preview">
                    <CardMedia
                      component="img"
                      image={formik.values.profile_picture}
                      alt="photo"
                    />
                    <Cancel
                      onClick={(e) => {
                        e.preventDefault();
                        setProfilePicture("");
                        formik.setFieldValue("profile_picture", "");
                      }}
                    />
                  </div>
                ) : (
                  <Box className="upload_image">
                    <label htmlFor="icon-button-file">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        onChange={(e:any)=>{
                          handleImageUpload(e)
                          e.target.value="";
                        }}
                      />
                      <Button component="span" className="upload_image_btn">
                        <img
                          src="/static/images/user_placeholder.png"
                          alt=""
                        />
                        <Camera />
                      </Button>
                    </label>
                  </Box>
                )}
              </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Full name</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="name"
                variant="outlined"
                fullWidth
                placeholder="Full Name"
                value={formik.values.name}
                onChange={(e)=>{
                  if(!isEnglishString(e.target.value))
                    {
                      showError("Only alphabets allowed")
                      return
                    }
                  if(e.target.value.length<=50)
                  formik.handleChange(e);
                else
                showError("Name can't exceed 50 characters")
                }
              
              }
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={(formik.touched.name && formik.errors.name && typeof formik.errors.name === 'string') && formik.errors.name}

              />
             
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Email</Typography>
              <TextField
                hiddenLabel
                type="email"
                name="email"
                disabled
                variant="outlined"
                fullWidth 
                onMouseOver={(e:any)=>{
                  e.target.style.cursor = 'not-allowed';
                }}
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={(formik.touched.email && formik.errors.email && typeof formik.errors.email === 'string') && formik.errors.email}
             
              />
            </Grid>
            {/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Address</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="address"
                variant="outlined"
                fullWidth
                placeholder="Address"
                value={formik.values.address}
                onChange={(e)=>{
                  if(e.target.value.length<=100)
                  formik.handleChange(e);
                else
                showError("Address cant exceed 100 characters")

                }}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={(formik.touched.address && formik.errors.address && typeof formik.errors.address === 'string') && formik.errors.address}
              /> */}
              {/* {formik.touched.address && formik.errors.address && (
                <Typography variant="caption" color="error">
                  {formik.errors.address}
                </Typography>
              )} */}
            {/* </Grid> */}
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Phone number</Typography>
              <PhoneInput
country={"us"}
placeholder="0 (000) 000-000"
enableSearch={true}
inputStyle={{ width: "100%" }}
value={phoneDisplay}
// onChange={(value) => formik.setFieldValue("phone_no", value)}
onChange={(phone_no, country) =>
handleChangePhone(phone_no, country)
}
onBlur={formik.handleBlur}
isValid={!(formik.touched.phone_no && formik.errors.phone_no)}
/>
{formik.touched.phone_no && formik.errors.phone_no && (
<Typography variant="caption" color="error">
Phone number is required
</Typography>
)}

            </Grid>
          </Grid>
          <div className="form_btn">
            <Button size="large" type="submit" className="btn btn_primary">
              Save
            </Button>
          </div>
        </CardContent>
      </form>
    </Card>
  </div>

);
};
export default Profile;
