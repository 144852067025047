import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from "yup";
import { setToStorage, STORAGE_KEYS, removeFromStorage, getFromStorage } from '../../../helpers';
import { showError,showToast,showWarning } from '../../../constants/toast';
import { useAppDispatch } from '../../../hooks/store';
import { setCredentials } from '../../../reducers/authSlice';
import { CommonBody } from '../../../types/General';
import { generateEncryptedKeyBody } from '../../../utils/crypto';
import { usePostLogInMutation } from '../../../services/auth';
import LoadingBar from 'react-top-loading-bar';
import Loader from '../../../helpers/Loader';
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const[isLoading,setIsLoading]=useState(false);
  const[deviceToken,setDevicetoken]=useState<any>("");
  const ref:any = useRef(null)

  const [showPassword, setShowPassword] = useState(false);
  const[loginMutation]=usePostLogInMutation();
  // const [loginMutation, { isLoading }] = usePostLoginMutation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .required("Password is required!")
        // .min(6, "Password must be at least 6 characters")
        ,
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
    
      let body = {
        email: formik.values.email,
        password: formik.values.password,
      //   deviceType:"WEB",
      //   deviceToken:deviceToken
        // appkey: new Date().toISOString(),
      };
      console.log(body,"login_body");
      setIsLoading(true);
      ref?.current?.continuousStart()
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await loginMutation(encryptedBody).unwrap();
        // const response = await loginMutation(body).unwrap();
        console.log("gggggggggggggg",response);
        if (response?.status === 200) {
          console.log(response?.data?.token);
          showToast("Logged In Successfully."||response?.message);
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }

          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
      setIsLoading(false);
      ref?.current?.complete();
    },
  });
  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

return (
 <Box
 component="main"
 className='auth_module'
 sx={{
  alignItems:"center",
  display:"flex",
  flexGrow:1,
 }}>
  <Loader isLoad={isLoading}/>
  {/* <LoadingBar color="#7048c1" ref={ref} shadow={true}  /> */}
  <Container
  className='auth_cntnr'
  maxWidth='xs'>

  <Box
  m={0}
  className='auth_logo'
  sx={{display:"flex",justifyContent:"center"}}
  >
<figure className='logo_cntnr' style={{textAlign:"center"}}>
      <img src='/static/images/Whizzo.png' style={{padding:"2px",width:"70%",height:"70%"}} alt='Whizzo Admin Panel'/>
  
</figure>
  </Box>
<div className='auth_box'>

 <Box sx={{mb:3,textAlign:"center",color:"#1d1d1d"}}>
  <Typography variant='h5'> Log in to Whizzo Admin </Typography>
 </Box>
  {/* <form > */}
  <form onSubmit={formik.handleSubmit}>
      <FormControl sx={{mb:2,width:"100%"}}>
          <Typography className='custom_label'>Email Address</Typography>
          <TextField
          placeholder='Email Address'
          className='text_field'
          fullWidth
          hiddenLabel
          name='email'
          id='email'
          variant='outlined'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          helperText={formik.touched.email&& formik.errors.email}
          />
      </FormControl>
      <FormControl sx={{mb:2,width:"100%"}}>
          <Typography className='custom_label'>Password</Typography>
          <TextField
          placeholder='Password'
          className='text_field'
          fullWidth
          hiddenLabel
          name='password'
          id='password'
          type={showPassword?'text':'password'}
          variant='outlined'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
              endAdornment:(
                  <InputAdornment className='eye_btn' position='end'>
                      <IconButton
                      aria-label="toggle password visiblity"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'>
                          {showPassword?<Visibility/>:<VisibilityOff/>}
                      </IconButton>
                  </InputAdornment>
              )
          }}
          />
      </FormControl>
      <Box 
      sx={{display:"flex",justifyContent:"space-between", alignItems:"center"}} >
      <Box className='remember_box'>
          <FormControlLabel
          control={<Checkbox sx={{color:'white'}}/>}
          label='Remember me'
          name='remember'
          checked={formik.values.remember}
          onChange={formik.handleChange}
          />
      </Box>
      <Box 
      className='anchor'
      onClick={()=>navigate('/forgotpassword')} >
      <Typography>Forgot Password</Typography>
      </Box>
      </Box>
      <Box sx={{pt:3}}>
          <Button 
          className='btn btn-primary'
          variant='contained'
          color='primary'
          fullWidth
          size='large'
          type='submit'
          // onClick={()=>{
          // navigate("/dashboard")
          // }}
          >Sign In</Button>
      </Box>
  </form>
</div>

  </Container>
 </Box>
)
}
export default LoginPage