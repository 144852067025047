import { Button, Card, Box, CardContent, Grid, Typography, CardMedia, Input, TextField } from '@mui/material';
import { error } from 'console';
import React, { useEffect, useState } from 'react'
import EditText from '../../components/EditText';
import { showError, showToast } from '../../constants/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { CameraAlt, Cancel, TrySharp } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useAddPurposesMutation, useEditPurposeByIdMutation, useLazyGetPurposeByIdQuery } from '../../services/purpose';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import { CommonBody } from '../../types/General';
import { isEnglishString } from '../../utils/validations';
const AddPurpose = () => {
  const{id}=useParams();
  const navigate=useNavigate();
  const[image,setImage]=useState("");
  const[error,setError]=useState("");


  const [addData]=useAddPurposesMutation();
  const [getDataById]=useLazyGetPurposeByIdQuery();
  const [updateData]=useEditPurposeByIdMutation();


  const formik=useFormik({
    enableReinitialize:true,
    initialValues:{
    purposeName:"",
    purposeDetails:"",
    purposeName_ar:"",
    purposeDetails_ar:""
    },
    validationSchema:Yup.object({
      purposeName:Yup.string().required("Purpose name is required"),
      // purposeDetails:Yup.string().required("Purpose details is required"),
      purposeName_ar:Yup.string().required("Purpose name (Arabic) is required"),
      // purposeDetails_ar:Yup.string().required("Purpose details (Arabic) is required")
    }),
    onSubmit:async ()=>{
formik.setSubmitting(true);
try{
const body={
  name:formik.values.purposeName,
  detail:"removed (don't use);-)",
  name_ar:formik.values.purposeName_ar,
  detail_ar:"removed (don't use );-)",
}
const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
const response=id?(await updateData({id,body:encryptedBody}).unwrap()):(await addData(encryptedBody).unwrap());
if(response.status==201||response.status==200)
  {
    showToast("Purpose "+ (id?"updated":"added") +" successfully");
    navigate('/manage-purposes');
  console.log(response);
  }
}
catch(error:any){
  showError(error?.data?.message?.detail[0])
  console.log(error);

}
formik.setSubmitting(false);
    }
  })

  const getDataByIdList=async()=>{
    try{const response=await getDataById({id}).unwrap();
    if(response.status==200)
      {
        formik.setFieldValue('purposeName',response?.data?.name);
        // formik.setFieldValue('purposeDetails',response?.data?.detail);
        formik.setFieldValue('purposeName_ar',response?.data?.name_ar);
        // formik.setFieldValue('purposeDetails_ar',response?.data?.detail_ar);
        console.log(response);
      }}
      catch(error){
        console.log(error);
      }
  }
  useEffect(()=>{
    if(id)
      getDataByIdList();
  },[id])
  return (
    <>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{id?"Edit":"Add"} Purpose</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-purposes");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
             
                <form onSubmit={formik.handleSubmit}>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <Cancel
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            // onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAlt />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid> */}
                </Grid>
                <Grid item lg={6} md={6} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Purpose Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="purposeName"
                          variant="outlined"
                          value={formik.values.purposeName}
                          onChange={
                            (e)=>{
                      
                              if( e.target.value==" ") return;
                              if(!isEnglishString(e.target.value))
                                {
                                  return
                                }
                              if(e.target.value.length<=50)
                              formik.handleChange(e);
                            else
                            showError("Purpose name can't exceed 50 characters")
                            }}
                          onBlur={formik.handleBlur}
                          fullWidth
                          placeholder="Add Purpose"
                          helperText={formik.touched.purposeName && formik.errors.purposeName}
                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>  
                <Grid item lg={6} md={6} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Purpose Name (Arabic)
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="purposeName_ar"
                          variant="outlined"
                          value={formik.values.purposeName_ar}
                          onChange={
                            (e)=>{
                      
                              if( e.target.value==" ") return;
                              if(!isEnglishString(e.target.value))
                                {
                                  return
                                }
                              if(e.target.value.length<=50)
                              formik.handleChange(e);
                            else
                            showError("Purpose name (Arabic) can't exceed 50 characters")
                            }}
                          onBlur={formik.handleBlur}
                          fullWidth
                          placeholder="Add Purpose(Arabic)"
                          helperText={formik.touched.purposeName_ar && formik.errors.purposeName_ar}
                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
               
                    </Grid>

                    {/* <Grid item lg={4} md={4} sm={4} xs={12} sx={{marginTop:"15px"}}>
                        <Typography className="custom_label">
                          Purpose Details
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="purposeDetails"
                          variant="outlined"
                          value={formik.values.purposeDetails}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          helperText={formik.touched.purposeDetails && formik.errors.purposeDetails}
                          placeholder="Add Details"
                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{marginTop:"15px"}}>
                        <Typography className="custom_label">
                          Purpose Details (Arabic)
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="purposeDetails_ar"
                          variant="outlined"
                          value={formik.values.purposeDetails_ar}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          helperText={formik.touched.purposeDetails_ar && formik.errors.purposeDetails_ar}
                          placeholder="Add Details"
                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid> */}
                    
                  {/* <Grid item xs={12}>
                  <Typography className="dialog_title" style={{ paddingTop: '20px' }}>Description</Typography>
 
  <div style={{ width: '66%' }}>  
  { displayEditText && <EditText
      content={description}
      setContent={setDescription}
    />}
  </div>
</Grid> */}
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        // onClick={(e)=>{
                        //   e.preventDefault();
                        //   navigate('/manage-purposes');
                        // }}
                      >
                       {id?"Update":"Add"}
                      </Button>
                    </div>
                  </CardContent>
                </form>
            </Box>
          </Card>
        </div>
    </>
  );
};
export default AddPurpose