//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { AchievementResponse } from "../types/Achievement";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
};
type AchievementResponse=any;
type GetAllAchievementResponse = {
    // Achievement: AchievementResponse[];
    response_object: AchievementResponse[];
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const AchievementApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
    addAchievement:builder.mutation<
CommonResponseType & {data:GetAllAchievementResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.addAchievement;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getAchievement:builder.mutation<
CommonResponseType & {data:GetAllAchievementResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getAllAchievement;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getAchievementById:builder.query<CommonResponseType & {data:AchievementResponse},
{id:number|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.getAchievementById}${id}/`,
        method:"GET",
    })
}),

// changeAchievementStatus: builder.mutation<
// CommonResponseType & { data: AchievementResponse },
// { id: string , body: encryptedBody}
// >({
// query: ({ id }) => ({
//   url: `${END_POINTS.changeAchievementStatus}${id}/`,
//   method: "PUT",
// }),
// }),

editAchievementById: builder.mutation<
      CommonResponseType & { data: AchievementResponse },
      { id: number; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateAchievement}${id}/`,
        method: "PUT",
        body,
      }),
    }),

deleteAchievementById:builder.mutation<
CommonResponseType & {data:AchievementResponse},
{id:number}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteAchievement}${id}/`,
        method:'DELETE'
    })
}),

importPdf:builder.mutation<CommonResponseType & {data:any},{subjectId:number,file:any}>({
  query:({subjectId,file})=>{
  const form=new FormData();
  form.append("file_link",file);
  return({
    url:END_POINTS.importAbilityPdf+"/"+subjectId,
    method:"POST",
    body:form
  })
  }
})

// getAchievementCSV: builder.query<CommonResponseType & { data: string }, {
//     search:string;
//   }>({
//     query: ({search}) => {
//       let url = END_POINTS.exportAchievements;
//       const queryParams = [];
//       if (search) {
//         queryParams.push(`search=${search}`);
//       }
//       if (queryParams.length > 0) {
//         url += `?${queryParams.join('&')}`;
//       }
//      return{ 
//       url:url,
//       method: "GET",}
//     },
//   }),

})
})

export const{
    useAddAchievementMutation,
    useGetAchievementMutation,
    useLazyGetAchievementByIdQuery,
    // useChangeAchievementStatusMutation,
    useEditAchievementByIdMutation,
    useDeleteAchievementByIdMutation,
    useImportPdfMutation,
}=AchievementApi;
