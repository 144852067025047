import React, { useEffect, useRef, useState } from 'react'
import MainContainer from '../../layout/MainContainer'
import { Box, Button, Card, Tabs, TextField, Typography } from '@mui/material'
import { useAddFaqsMutation, useDeleteFaqByIdMutation, useEditFaqByIdMutation, useGetFaqsMutation } from '../../services/faq';
import { showError, showToast } from '../../constants/toast';
import { CommonBody } from '../../types/General';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import WarnModal from '../../components/modals/WarnModal';
import { handleDelete } from '../../utils/commonFunctions';
import Loader from '../../helpers/Loader';
import LoadingBar from 'react-top-loading-bar';
import { isDeleteAllowed, isEditAllowed } from '../../utils/permissonAllowed';
const ManageFaq = () => {
  const ref:any = useRef(null)

    const [value, setValue] = React.useState(0);
    const[isLoading,setIsLoading]=useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number>(-1);
    const[newAnswer,setNewAnswer]=useState("");
    const[newQuestion,setNewQuestion]=useState("");
  const[faq,setFaq]=useState<any>([]);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    const [getAllData] = useGetFaqsMutation();
    const [updateData]:any = useEditFaqByIdMutation();
    const [deleteById] = useDeleteFaqByIdMutation();
    const[addData]=useAddFaqsMutation();
    const getFaqList:any = async () => {
      ref?.current?.continuousStart()

      setIsLoading(true);
      try {
        const body={
          start:1,
          length:10,
          search:"",
        }
        console.log("TTTTTTTTTTTTTTTTTTTTTTTTTTTTTT",body);
        const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
        const response = await getAllData(encryptedBody).unwrap();
        if (response?.status === 200) {
          console.log(response);
          setFaq(response?.data?.response_object|| []);
          // setTotalCount(response?.data?.total_records);
        } else {
          setFaq([]);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
      setIsLoading(false);
      ref?.current?.complete();

    };
    const addFaq:any = async () => {
      setIsLoading(true);
      try {
        const body={
            question:newQuestion,
            answer:newAnswer
        }
        console.log(body);
        const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
        const response = await addData(encryptedBody).unwrap();
        if (response?.status === 200) {
          console.log(response);
          setNewQuestion("");
          setNewAnswer("");
          showToast("FAQ added successfully");
          // setFaq((prevData:any)=>[...prevData,body])
          getFaqList();
        } else {
          setFaq([]);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
      setIsLoading(false);
    };
const updateFaq=async(index:any)=>{
  
  
  if(faq[index].question.replaceAll("\n","").trim()=="")
    {
      showError("Faq question is required");
      return ;
    }
    if(faq[index].answer.replaceAll("\n","").trim()=="")
      {
        showError("Faq answer is required");
        return ;
      }
      setIsLoading(true);
  try {
    const body={
        question:newQuestion,
        answer:newAnswer
    }
    console.log(body);
    const encryptedBody=generateEncryptedKeyBody({question:faq[index].question,answer:faq[index].answer}) as CommonBody;
    const response = await updateData({id:faq[index].id,body:encryptedBody}).unwrap();
    if (response?.status === 200) {
      console.log(response);
      showToast("FAQ updated successfully");
      // setFaq((prevData:any)=>[...prevData,body])
      getFaqList();
    } else {
      setFaq([]);
    }
  } catch (error: any) {
    showError(error?.data?.message || "");
  }
  setIsLoading(false);
};
useEffect(()=>{
      getFaqList();
    },[])

  return (
   <> 
   <div className="main_loyout">
   <Loader isLoad={isLoading}/>
   <LoadingBar color="#7048c1" ref={ref} shadow={true}  />


   <div className="dashboard">
     <h1 className="mn_hdng">Manage FAQs</h1>
   </div>
   {isEditAllowed(11) && <Card className="cards">
   <Typography className='custom_label' style={{fontSize:"20px"}}>Add New FAQ</Typography>
      <form onSubmit={(e)=>{
        e.preventDefault();
        addFaq()
        }}>
           <Box>
            <div style={{marginBottom:"10px",marginTop:"10px"}}>
                 <Typography className='custom_label'>Question</Typography>
                 <TextField
                 hiddenLabel
                 fullWidth
                 value={newQuestion}
                 className='text_field'
                 multiline
                 minRows={1}
                 maxRows={3}
                //  onChange={(e)=>{
                //   if(e.target.value!==" ")
                //   setNewQuestion(e.target.value)}}
                  onChange={
                    (e)=>{
              
                      if( e.target.value==" ") return;
                    
                      if(e.target.value.length<=500)
                      setNewQuestion(e.target.value)
                    else
                    showError("Question can't exceed 500 characters")
                    }}

                 placeholder='Enter Question'
                 />
            </div>
            <div style={{marginBottom:"10px"}}>
                 <Typography className='custom_label'>Answer</Typography>
                 <TextField
                 fullWidth
                 hiddenLabel
                 className='text_field'
                 multiline
                 minRows={1}
                 maxRows={3}
                 value={newAnswer}
                 onChange={
                  (e)=>{
            
                    if( e.target.value==" ") return;
                  
                    if(e.target.value.length<=500)
                    setNewAnswer(e.target.value)
                  else
                  showError("Answer can't exceed 500 characters")
                  }}
                 placeholder='Enter Answer'
                 />
            </div>
                       
            <div style={{display:"flex",justifyContent:"end",padding:"10px",paddingBottom:"5px"}}> 
                  <Button
                  className='btn btn_primary'
                  type='submit'
                  disabled={!(newQuestion.length && newAnswer.length)}
                  >Add</Button>
            </div>
           </Box>
      </form>
       </Card>}
   <Card style={{backgroundColor:"#D2DCD6"}}>
   {faq?.map((data:any,mainIndex:number)=>{
    return <Card className="cards">
         <form onSubmit={(e)=>{
        e.preventDefault()
        updateFaq(mainIndex);
        }}>
           <Box>
            <div style={{marginBottom:"10px",marginTop:"10px"}}>
                 <Typography className='custom_label'>Question</Typography>
                 <TextField
                 value={data?.question}
                 onChange={
                  (e)=>{
            
                    if( e.target.value==" ") return;
                  
                    if(e.target.value.length<=500)
                      setFaq((prevData:any)=>{
                        return prevData.map((item:any,index:number)=>{
                         if(mainIndex==index)
                           {
                             return {...item,question:e.target.value}
                           }
                           return item;
                        }) 
                        })
                  else
                  showError("Question can't exceed 500 characters")
                  }}
                 hiddenLabel
                 fullWidth
                 className='text_field'
                 multiline
                 minRows={1}
                 maxRows={3}
                 disabled={!isEditAllowed(11)}
                 placeholder={'Question '+(mainIndex+1)}
                 name='question1'
                 />
            </div>
            <div style={{marginBottom:"10px"}}>
                 <Typography className='custom_label'>Answer</Typography>
                 <TextField
                 fullWidth
                 hiddenLabel
                 className='text_field'
                 multiline
                 minRows={1}
                 maxRows={3}
                 value={data?.answer}
                 disabled={!isEditAllowed(11)}

                  onChange={
                    (e)=>{
              
                      if( e.target.value==" ") return;
                    
                      if(e.target.value.length<=500)
                        setFaq((prevData:any)=>{
                          return prevData.map((item:any,index:number)=>{
                           if(mainIndex==index)
                             {
                               return {...item,answer:e.target.value}
                             }
                             return item;
                          }) 
                          })
                    else
                    showError("Answer can't exceed 500 characters")
                    }}

                 placeholder={'Answer '+(mainIndex+1)}
                 name='answer1'
                 />
            </div>
                       
            <div style={{display:"flex",justifyContent:"end",padding:"10px",paddingBottom:"5px"}}>
           {isEditAllowed(11) && <Button
                  type='submit'
                  style={{marginInline:"4px"}}
                  className='btn btn_primary'
                  >Update</Button>}
                {isDeleteAllowed(11) &&   <Button
                  onClick={()=>{
                    setSelectedId(data?.id);
                    setOpen(true);
                  }}
                  className='btn btn_primary'
                  >Remove</Button>}
            </div>
           </Box>
         </form>
       </Card>
   })
   
       }
   </Card>
 </div>
 
 <WarnModal
        setOpen={setOpen}
        open={open}
        name={"FAQ"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getFaqList)}
      />
 </>
  )
}

export default ManageFaq