import React, { useEffect, useRef, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { isDeleteAllowed, isEditAllowed } from "../../utils/permissonAllowed";
import { useDeleteNotificationByIdMutation, useGetNotificationsMutation } from "../../services/notification";
import { showError } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Pagination } from "../../components";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { NotificationType } from "../../types/notification";
import { isValidInput } from "../../utils/validations";
import Loader from "../../helpers/Loader";
import LoadingBar from "react-top-loading-bar";

const ManageNotifications = () => {
  const navigate = useNavigate();

  function createData(name: string, title: string, message: string) {
    return { name, title, message };
  }

  const rows = [createData("Abdul Hussian", "Title", "Message")];
  const ref:any = useRef(null)

  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [notification, setNotification] = useState<NotificationType[]>([]);
  const [getNotification] = useGetNotificationsMutation();
  const [deleteById] = useDeleteNotificationByIdMutation();
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getNotificationList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const body={
        start:page,
        length:10,
        search:debouncedSearchTerm.trim(),
      }
      console.log(body);
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getNotification(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response);
        setNotification(response?.data?.response_object || []);
        setTotalCount(response?.data?.total_records);
      } else {
        setNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };
 useEffect(() => {
//   console.log("TESTING")
    getNotificationList();
  }, [debouncedSearchTerm, page]);
  return (
    <>
      <div className="main_loyout">
        <Loader isLoad={isLoading}/>
        <LoadingBar ref={ref} color="#7048c1" shadow={true} />
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
searchTerm={searchTerm}
setDebouncedSearchTerm={setDebouncedSearchTerm}
value={searchTerm}
onCross={() => setSearchTerm("")}
onChange={(val: any) => {
  if (isValidInput(val.target.value)) {
    setSearchTerm(val.target.value);
  }
}}
/>
           {isEditAllowed(7) && <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-notifications/add")}
              >
                Add Notification
              </Button>
            </Box>}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Send to</TableCell>
                  <TableCell>Message</TableCell>
               {isDeleteAllowed(7) &&   <TableCell align="center">Delete</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {notification?.length ? notification.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row.created_at?.slice(0,10)}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.notification_for}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    {isDeleteAllowed(7) &&  
                    <TableCell align="center">
                    <Box className="table_actions">
                        <Tooltip title="Delete">
                          <IconButton onClick={()=>{
                            setSelectedId(row?.id);
                            setOpen(true)
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>}
                  </TableRow>
                )):<TableRow>
                  <TableCell align="center" colSpan={6}>
                    No Notifications Found
                  </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={notification}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
          <WarnModal
        setOpen={setOpen}
        open={open}
        name={"notification"}
        handleDelete={() => handleDelete(deleteById, selectedId, getNotificationList)}
      />
      </div>
    </>
  );
};

export default ManageNotifications;
