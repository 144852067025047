import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { Box, Button, FormControl, Grid, Menu, MenuItem } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLazyGetCSVQuery, useLazyGetGraphDataQuery } from "../../services/dashboard";
import { showError } from "../../constants/toast";
import LoadingBar from "react-top-loading-bar";
import Loader from "../../helpers/Loader";

const Analytics = () => {
  const [getGraph]=useLazyGetGraphDataQuery();
  const [exportCSV]=useLazyGetCSVQuery();
  const ref:any = useRef(null)
  const [userGraphType,setUserGraphType]=useState<number>(1);
  const [revenueGraphType,setRevenueGraphType]=useState(1);
  const [subscriptionGraphType,setSubscriptionGraphType]=useState(1);
  const[isLoading,setIsLoading]=useState(false);
  const [graphData,setGraphData]=useState({
    user:{
      labels: [],
      datasets: [
        {
          label: "User",
          data: [],
          borderColor: "black",
          backgroundColor: "#8A5FF7",
          color: "#FFFFF",
        },
      ],
    },
    revenue:{
      labels: [],
      datasets: [
        {
          label: "Revenue",
          data: [],
          borderColor: "black",
          backgroundColor: "#8A5FF7",
          color: "#FFFFF",
        },
      ],
    },
    subscription:{
      labels: [],
      datasets: [
        {
          label: "Subscription",
          data: [],
          borderColor: "black",
          backgroundColor: "#8A5FF7",
          color: "#FFFFF",
        },
      ],
    },
  })
const ENDPOINT_GRAPH:any={
    1:"admin/get-dashboard-user-graph-data/",
    2:"admin/get-dashboard-revenue-graph-data/",
    3:"admin/get-dashboard-subscription-graph-data/"
}
const generateFormat=(data:any,labelName:string)=>{
        const keys=Object.keys(data)
        const values=Object.values(data)
        return {
          labels: keys,
          datasets: [
            {
              label: labelName,
              data: values,
              borderColor: "black",
              backgroundColor: "#8A5FF7",
              color: "#FFFFF",
            },
          ],
        };
}
  const getUserGraphData=async(endpoint:number,interval:number)=>{
    ref?.current?.continuousStart()
    setIsLoading(true);
    try{
    const response=await getGraph({endpoint:`${ENDPOINT_GRAPH[endpoint]}?interval=${interval}`}).unwrap();
    if(response.status==200)
    {
      console.log(response?.data);
      if(endpoint==1)
      {
        setGraphData((prevData:any)=>({...prevData,user:generateFormat(response?.data,"User")}))
      }
      else if(endpoint==2)
      {
        setGraphData((prevData:any)=>({...prevData,revenue:generateFormat(response?.data,"Revenue")}))

      }
      else
      {
        setGraphData((prevData:any)=>({...prevData,subscription:generateFormat(response?.data,"Subscription")}))

      }
    }
    }
    catch(error:any)
    {
      showError(error?.data?.message||"Graph data not found")
    }
    setIsLoading(false);

    ref?.current?.complete();
  
  }
  useEffect(()=>{
  getUserGraphData(1,userGraphType)
  },[userGraphType])
  useEffect(()=>{
    getUserGraphData(2,revenueGraphType)

  },[revenueGraphType])
  useEffect(()=>{
    getUserGraphData(3,subscriptionGraphType)

  },[subscriptionGraphType])
  // const graphRevenueData = {
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   datasets: [
  //     {
  //       label: "Revenue",
  //       data: [
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "20",
  //         "25",
  //       ],
  //       borderColor: "black",
  //       backgroundColor: "#8A5FF7",
  //       color: "#FFFFF", 

  //     },
  //   ],
  // };

  // const graphUsersData = {
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   datasets: [
  //     {
  //       label: "Users",
  //       data: [
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "20",
  //         "25",
  //       ],
  //       borderColor: "black",
  //       backgroundColor: "#8A5FF7",
  //       color: "#FFFFF", 
  //     },
  //   ],
  // };

  // const graphAdvertisersData = {
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   datasets: [
  //     {
  //       label: "Subscription",
  //       data: [
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "20",
  //         "25",
  //       ],
  //       borderColor: "black",
  //       backgroundColor: "#8A5FF7",
  //       color: "#FFFFF", 
  //     },
  //   ],
  // };

  // const graphTransactionsData = {
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   datasets: [
  //     {
  //       label: "Transactions",
  //       data: [
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "20",
  //         "25",
  //       ],
  //       borderColor: "black",
  //       backgroundColor: "#8A5FF7",
  //       color: "#FFFFF",
  //     },
  //   ],
  // };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadCSV=async(type:number,interval:number)=>{
    setIsLoading(true);
    try{
    const response=await exportCSV({endpoint:`admin/users-graph-csv/?type=${type}&interval=${interval}`}).unwrap();
    if(response?.status==200)
    {
      window.open(response?.data?.file_urls||"");
    }
    }
    catch(error:any)
    {
      showError(error?.data?.message||"Download Error!!")
    }
    setIsLoading(false);
  }
  return (
    <>
    <LoadingBar color="#7048c1" ref={ref} shadow={true}  />
    <Loader isLoad={isLoading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <div className="flex">
            <h1 className="mn_hdng">Reports and Analytics</h1>
            <Box className="cards_header_right">
              <Button className="btn btn_primary"  
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
                <FileDownloadIcon /> Export CSV
              </Button>
              <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>{
          handleClose();
          downloadCSV(1,userGraphType)
          }}>User</MenuItem>
        <MenuItem onClick={()=>{
          handleClose();
          downloadCSV(2,revenueGraphType)
          }}>Revenue</MenuItem>
        <MenuItem onClick={()=>{
          handleClose();
          downloadCSV(3,subscriptionGraphType)
          }}>Subscription</MenuItem>
      </Menu>
            </Box>
          </div>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Users
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    value={userGraphType}
                    onChange={(e)=>setUserGraphType(parseInt(e.target.value))}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    {/* <option value={0}>Select</option> */}
                    <option value={1}>Day</option>
                    <option value={2}>Week</option>
                    <option value={3}>Monthly</option>
                    <option value={4}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphData?.user} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Revenue
                <FormControl>
                  <NativeSelect
                     value={revenueGraphType}
                     onChange={(e)=>setRevenueGraphType(parseInt(e.target.value))}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    {/* <option value={0}>Select</option> */}
                    <option value={1}>Day</option>
                    <option value={2}>Week</option>
                    <option value={3}>Monthly</option>
                    <option value={4}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphData?.revenue} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Subscription
                <FormControl>
                  <NativeSelect
                   value={subscriptionGraphType}
                   onChange={(e)=>setSubscriptionGraphType(parseInt(e.target.value))}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    {/* <option value={0}>Select</option> */}
                    <option value={1}>Day</option>
                    <option value={2}>Week</option>
                    <option value={3}>Monthly</option>
                    <option value={4}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphData?.subscription} />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Analytics;
