import { END_POINTS } from "../helpers/constants/urls";
import { CommonBody } from "../types/General";
// import { DashboardData } from "../types/General";

import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
  status: number;
  message: string;
};

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<
      CommonResponseType & { data: any },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.getCms}`,
        method: "GET",
      }),
    }),
    updateCms: builder.mutation<
    CommonResponseType & { data: any},
    CommonBody
  >({
    query: (body) => ({
      url: `${END_POINTS.updateCms}`,
      method: "PUT",
      body
    }),
  }),
  updateCmsContact: builder.mutation<
    CommonResponseType & { data: any},
    CommonBody
  >({
    query: (body) => ({
      url: `${END_POINTS.updateCmsContact}`,
      method: "POST",
      body
    }),
  }),
  updateCmsTerms: builder.mutation<
  CommonResponseType & { data: any},
  CommonBody
>({
  query: (body) => ({
    url: `${END_POINTS.updateCmsTerms}`,
    method: "POST",
    body
  }),
}),  updateCmsPrivacy: builder.mutation<
CommonResponseType & { data: any},
CommonBody
>({
query: (body) => ({
  url: `${END_POINTS.updateCmsPrivacy}`,
  method: "POST",
  body
}),
}),  updateCmsAbout: builder.mutation<
    CommonResponseType & { data: any},
    CommonBody
  >({
    query: (body) => ({
      url: `${END_POINTS.updateCmsAbout}`,
      method: "POST",
      body
    }),
  }),

})});
export const {
    useLazyGetCmsQuery,
    useUpdateCmsMutation,
    useUpdateCmsAboutMutation,
    useUpdateCmsContactMutation,
    useUpdateCmsTermsMutation,
    useUpdateCmsPrivacyMutation,
}=dashboardApi;