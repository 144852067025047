import { END_POINTS } from "../helpers/constants/urls";
import { CommonBody } from "../types/General";
// import { DashboardData } from "../types/General";

import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
  status: number;
  message: string;
};

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponseType & { data: any },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.dashboardData}`,
        method: "GET",
      }),
    }),
    getGraphData: builder.query<
    CommonResponseType & { data: any },
    {endpoint:string}
  >({
    query: ({endpoint}) => ({
      url: endpoint,
      method: "GET",
    }),
  }),
  getGraphCSV: builder.query<
  CommonResponseType & { data: any },
  {endpoint:string}
>({
  query: ({endpoint}) => ({
    url: endpoint,
    method: "GET",
  }),
}),
  getCSV: builder.query<CommonResponseType & { data: any }, {endpoint:string}>({
    query: ({endpoint}) => 
    ({
      url:endpoint,
      method: "GET"
    })
  }),

})});
export const {
    useLazyGetDashboardQuery,
    useLazyGetGraphDataQuery,
    useLazyGetCSVQuery,
    useLazyGetGraphCSVQuery
}=dashboardApi;