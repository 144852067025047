import { Cancel, CameraAlt, Camera } from '@mui/icons-material';
import { Button, Card, Box, CardContent, Grid, Typography, CardMedia, Input, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import * as Yup from "yup";
import { showToast, showError } from '../../constants/toast';
import { useAddTestimonialMutation, useLazyGetTestimonialByIdQuery, useEditTestimonialByIdMutation } from '../../services/testimonials';
import { CommonBody } from '../../types/General';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import { errorToast } from '../../helpers';
import { UploadMedia } from '../../utils/mediaUpload';
import PhoneInput from 'react-phone-input-2';
import Loader from '../../helpers/Loader';
import { isEnglishString } from '../../utils/validations';
const AddTestimonial = () => {
  const navigate=useNavigate();
  const{id}=useParams();
  const[image,setImage]=useState("");
  const[error,setError]=useState(false);


  const [addData]=useAddTestimonialMutation();
  const [getDataById]=useLazyGetTestimonialByIdQuery();
  const [updateData]=useEditTestimonialByIdMutation();
  const [rating,setRating]=useState(0);
  const [country_code, setPhoneCode] = useState("+91");
  const [profile_picture, setProfilePicture] = useState<any>(null);
  const [imageId, setImageId] = useState<number>(-1);
  const[phoneDisplay,setPhoneDisplay]=useState("");
  const[isLoading,setIsLoading]=useState(false);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {

        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        setImageId(-1);
        return;
      }
      const allowedExtensions = ["png","jpg","jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss",fileExtension);
      if (!allowedExtensions.includes(fileExtension||"")) {
         setIsLoading(false);
        showError("Invalid file format: only png, jpg images are allowed");
        return;
      }
      setIsLoading(true);
      const res = await UploadMedia(file);
//      console.log(res,'lklk');
      
      if (res?.status=== 200) {
        console.log(res?.data[0])
        formik.setFieldValue("profile_picture", res?.data[0]?.media_url);
        setProfilePicture(res?.data[0]?.media_url);
        console.log("jjjjjjjjjjjjjjj",res?.data[0]?.id);
        setImageId(res?.data[0]?.id)
        showToast("Image uploaded successfully")
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
    setIsLoading(false);
  };

  const handleChangePhone = (phone_no: any, country: any) => {
    formik.setFieldValue(
      "phone_no",
      phone_no.replace(country.dialCode, "")
    );
    setPhoneCode(country.dialCode);
    setPhoneDisplay(phone_no);
  };


  const formik=useFormik({
    enableReinitialize:true,
    initialValues:{
    first_name:"",
    last_name:"",
    message:"",
    first_name_ar:"",
    last_name_ar:"",
    message_ar:"",
    email:"",
    profile_picture:"",
    country_code: "",
    phone_no:""
    },
    validationSchema:Yup.object({
      first_name:Yup.string().required("First Name is required"),
      last_name:Yup.string().required("Last Name is required"),
      message:Yup.string().required("Message is required"),
      first_name_ar:Yup.string().required("First Name (Arabic) is required"),
      last_name_ar:Yup.string().required("Last Name (Arabic) is required"),
      message_ar:Yup.string().required("Message (Arabic) is required"),
      email:Yup.string().required("Email is required"),
      
      // email:Yup.string().email().required("Email is required"),
      // phone_no:Yup.string().required("Message is required"),
      
    }),
    onSubmit:async (values)=>{
formik.setSubmitting(true);
if(imageId==-1)
  {
    showError("Please add an image");
    return 
  }
try{
const body={
  first_name:values?.first_name,
  last_name:values?.last_name,
  message:values?.message,
  first_name_ar:values?.first_name_ar,
  last_name_ar:values?.last_name_ar,
  message_ar:values?.message_ar,
  rating:rating,
  email:values?.email,
  phone_no: values?.phone_no?values?.phone_no:"100000000",
  country_code: country_code?country_code:"+00",
  profile_picture: imageId
}
console.log("TESTIMONIAL BODY",body);
const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
const response=id?(await updateData({id,body:encryptedBody}).unwrap()):(await addData(encryptedBody).unwrap());
if(response.status==201||response.status==200)
  {
    showToast("Testimonial "+ (id?"updated":"added") +" successfully");
    navigate('/manage-testimonials');
  console.log(response);
  }
}
catch(error:any){
  showError(error?.data?.message||"Something went wrong")
  console.log(error);

}
formik.setSubmitting(false);
    }
  })

  const getDataByIdList=async()=>{
    try{const response=await getDataById({id}).unwrap();
    if(response.status==200)
      {
        formik.setFieldValue('first_name',response?.data?.first_name);
        formik.setFieldValue('last_name',response?.data?.last_name);
        formik.setFieldValue('message',response?.data?.message);
        formik.setFieldValue('first_name_ar',response?.data?.first_name_ar);
        formik.setFieldValue('last_name_ar',response?.data?.last_name_ar);
        formik.setFieldValue('message_ar',response?.data?.message_ar);
        formik.setFieldValue('email',response?.data?.email);
        formik.setFieldValue('phone_no',response?.data?.phone_no);
        formik.setFieldValue('country_code',response?.data?.country_code);
        formik.setFieldValue('profile_picture',response?.data?.profile_picture?.media_url);
        setImageId(response?.data?.profile_picture?.id);
        // formik.setFieldValue('purposeName',response?.data?.name);
        setRating(response?.data?.rating);
        setPhoneCode(response?.data?.country_code);
        setPhoneDisplay(response?.data?.country_code+response?.data?.phone_no);
        console.log(response);
      }}
      catch(error){
        console.log(error);
      }
  }
  useEffect(()=>{
    if(id)
      getDataByIdList();
  },[id])
  return (
    <>
        <div className="main_loyout">
          <Loader isLoad={isLoading}/>
          <div className="dashboard">
            <h1 className="mn_hdng">{id?"Edit testimonial details":"Add testimonial"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-testimonials");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
             
                <form onSubmit={formik.handleSubmit}>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Grid item xs={12}>
                <Typography className="custom_label">Image</Typography>
                {formik.values.profile_picture ? (
                  <div className="upload_image_preview">
                    <CardMedia
                      component="img"
                      image={formik.values.profile_picture}
                      alt="photo"
                    />
                    <Cancel
                      onClick={(e) => {
                        e.preventDefault();
                        setProfilePicture("");
                        setImageId(-1);
                        formik.setFieldValue("profile_picture", "");
                      }}
                    />
                  </div>
                ) : (
                  <Box className="upload_image">
                    <label htmlFor="icon-button-file">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        onChange={handleImageUpload}
                      />
                      <Button component="span" className="upload_image_btn">
                        <img
                          src="/static/images/user_placeholder.png"
                          alt=""
                        />
                        <Camera />
                      </Button>
                    </label>
                  </Box>
                )}
                {error && !formik.values.profile_picture && (
<Typography variant="caption" color="error">
Testimonial image is required
</Typography>
)}
              </Grid>
                </Grid>
                      
                <Grid item lg={6} md={6} sm={4} xs={12}>
                        <Typography className="custom_label">
                          First Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="first_name"
                          variant="outlined"
                          fullWidth
                          placeholder="Enter first name"
                          value={formik.values.first_name}
                          onChange={(e)=>{
                      
                            if( e.target.value==" ") return;
                            if(!isEnglishString(e.target.value))
                              {
                                return
                              }
                            if(e.target.value.length<=50)
                            formik.handleChange(e);
                          else
                          showError("First name can't exceed 50 characters")
                          }
                        }
                          onBlur={formik.handleBlur}
                          helperText={error&&formik.errors.first_name}

                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={4} xs={12}>
                        <Typography className="custom_label">
                          First Name (Arabic)
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="first_name_ar"
                          variant="outlined"
                          fullWidth
                          placeholder="Enter first name (Arabic)"
                          value={formik.values.first_name_ar}
                          onChange={(e)=>{
                      
                              if( e.target.value==" ") return;
                              if(!isEnglishString(e.target.value))
                                {
                                  return
                                }
                              if(e.target.value.length<=50)
                              formik.handleChange(e);
                            else
                            showError("First name (Arabic) can't exceed 50 characters")
                            }
                          }
                        
                          onBlur={formik.handleBlur}
                          helperText={error&&formik.errors.first_name_ar}

                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Last Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="last_name"
                          variant="outlined"
                          className="text_field"
                          fullWidth
                          placeholder="Enter last name"
                          value={formik.values.last_name}
                          onChange={(e)=>{
                      
                            if( e.target.value==" ") return;
                            if(!isEnglishString(e.target.value))
                              {
                                return
                              }
                            if(e.target.value.length<=50)
                            formik.handleChange(e);
                          else
                          showError("Last name can't exceed 50 characters")
                          }}
                          onBlur={formik.handleBlur}
                          helperText={error&&formik.errors.last_name}

                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Last Name (Arabic)
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="last_name_ar"
                          variant="outlined"
                          className="text_field"
                          fullWidth
                          placeholder="Enter last name (Arabic)"
                          value={formik.values.last_name_ar}
                          onChange={(e)=>{
                      
                            if( e.target.value==" ") return;
                            if(!isEnglishString(e.target.value))
                              {
                                return
                              }
                            if(e.target.value.length<=50)
                            formik.handleChange(e);
                          else
                          showError("Last name (Arabic) can't exceed 50 characters")
                          }}
                          onBlur={formik.handleBlur}
                          helperText={error&&formik.errors.last_name_ar}

                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Email
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="email"
                          className="text_field"

                          variant="outlined"
                          fullWidth
                          placeholder="Enter email"
                          value={formik.values.email}
                          onChange={(e)=>{
                      
                            if( e.target.value==" ") return;
                            if(e.target.value.length<=50)
                            formik.handleChange(e);
                          else
                          showError("First name can't exceed 50 characters")
                          }}
                          onBlur={formik.handleBlur}
                          helperText={error&&formik.errors.email}

                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
                      {/* <Grid item lg={4} md={4} sm={6} xs={12}>
              <Typography className="custom_label">Phone number</Typography>
              <PhoneInput
country={"us"}
placeholder="0 (000) 000-000"
enableSearch={true}
inputStyle={{ width: "100%" }}
value={phoneDisplay}
// onChange={(value) => formik.setFieldValue("phone_no", value)}
onChange={(phone_no, country) =>
handleChangePhone(phone_no, country)
}
onBlur={formik.handleBlur}
isValid={!(formik.touched.phone_no && formik.errors.phone_no)}
/>
{error && formik.errors.phone_no && (
<Typography variant="caption" color="error">
Phone number is required
</Typography>
)}

            </Grid> */}
                      <Grid item lg={2} md={2} sm={4} xs={12} sx={{margin:"15px"}}>
                        <Typography className="custom_label">
                          Rating
                        </Typography>
                        <Rating
  onClick={(e)=>setRating(e)}
    initialValue={rating}
    iconsCount={5}
    size={23}
    allowHover={true}
  />
                      </Grid>
                      
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12} paddingTop={"20px"}>
                        <Typography className="custom_label">
                          Message
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="message"
                          variant="outlined"
                          fullWidth
                          className="text_field"
                          multiline
                          minRows={3}
                          placeholder="Message"
                          value={formik.values.message}
                          onChange={
                      (e)=>{
                            if(e.target.value.length<=500)
                            formik.handleChange(e);
                          else
                          showError("Message can't exceed 500 characters")
                          }}
                          onBlur={formik.handleBlur}
                          helperText={error&&formik.errors.message}
                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12} paddingTop={"10px"}>
                        <Typography className="custom_label">
                          Message (Arabic)
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="message_ar"
                          variant="outlined"
                          fullWidth
                          className="text_field"
                          multiline
                          minRows={3}
                          placeholder="Message"
                          value={formik.values.message_ar}
                          onChange={
                            (e)=>{
                                  if(e.target.value.length<=500)
                                  formik.handleChange(e);
                                else
                                showError("Message (Arabic) can't exceed 500 characters")
                                }}
                          onBlur={formik.handleBlur}
                          helperText={error&&formik.errors.message_ar}
                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid>
                  {/* <Grid item xs={12}>
                  <Typography className="dialog_title" style={{ paddingTop: '20px' }}>Description</Typography>
 
  <div style={{ width: '66%' }}>  
  { displayEditText && <EditText
      content={description}
      setContent={setDescription}
    />}
  </div>
</Grid> */}
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={(e)=>{
                          setError(true);
                        }}
                      >
                       {id?"Update":"Add"}
                      </Button>
                    </div>
                  </CardContent>
                </form>
            </Box>
          </Card>
        </div>
    </>
  );
};
export default AddTestimonial