//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import { TestimonialResponse } from "../types/testimonial";
// import { TestimonialResponse } from "../types/Testimonial";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
};

type GetAllTestimonialsResponse = {
    // Testimonial: TestimonialResponse[];
    response_object: TestimonialResponse[];
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const TestimonialApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
    addTestimonial:builder.mutation<
CommonResponseType & {data:GetAllTestimonialsResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.addTestimonial;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getTestimonials:builder.mutation<
CommonResponseType & {data:GetAllTestimonialsResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getTestimonials;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getTestimonialById:builder.query<CommonResponseType & {data:TestimonialResponse},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.getTestimonialById}${id}/`,
        method:"GET",
    })
}),

changeTestimonialStatus: builder.mutation<
CommonResponseType & { data: TestimonialResponse },
{ id: string , body: encryptedBody}
>({
query: ({ id,body }) => ({
  url: `${END_POINTS.changeTestimonialStatus}${id}/`,
  method: "PUT",
  body
}),
}),

editTestimonialById: builder.mutation<
      CommonResponseType & { data: TestimonialResponse },
      { id: string; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateTestimonials}${id}/`,
        method: "PUT",
        body,
      }),
    }),

deleteTestimonialById:builder.mutation<
CommonResponseType & {data:TestimonialResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteTestimonial}${id}/`,
        method:'DELETE'
    })
}),
// getTestimonialCSV: builder.query<CommonResponseType & { data: string }, {
//     search:string;
//   }>({
//     query: ({search}) => {
//       let url = END_POINTS.exportTestimonials;
//       const queryParams = [];
//       if (search) {
//         queryParams.push(`search=${search}`);
//       }
//       if (queryParams.length > 0) {
//         url += `?${queryParams.join('&')}`;
//       }
//      return{ 
//       url:url,
//       method: "GET",}
//     },
//   }),

})
})

export const{
    useAddTestimonialMutation,
    useGetTestimonialsMutation,
    useLazyGetTestimonialByIdQuery,
    useChangeTestimonialStatusMutation,
    useEditTestimonialByIdMutation,
    useDeleteTestimonialByIdMutation,
    // useLazyGetTestimonialCSVQuery,
}=TestimonialApi;
