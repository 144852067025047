//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { AbilityResponse } from "../types/Ability";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
};
type AbilityResponse=any;
type GetAllAbilityResponse = {
    // Ability: AbilityResponse[];
    response_object: AbilityResponse[];
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const AbilityApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
    addAbility:builder.mutation<
CommonResponseType & {data:GetAllAbilityResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.addAbility;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getAbility:builder.mutation<
CommonResponseType & {data:GetAllAbilityResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getAllAbility;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getAbilityById:builder.query<CommonResponseType & {data:AbilityResponse},
{id:number|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.getAbilityById}${id}/`,
        method:"GET",
    })
}),

// changeAbilityStatus: builder.mutation<
// CommonResponseType & { data: AbilityResponse },
// { id: string , body: encryptedBody}
// >({
// query: ({ id }) => ({
//   url: `${END_POINTS.changeAbilityStatus}${id}/`,
//   method: "PUT",
// }),
// }),

editAbilityById: builder.mutation<
      CommonResponseType & { data: AbilityResponse },
      { id: number; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateAbility}${id}/`,
        method: "PUT",
        body,
      }),
    }),

deleteAbilityById:builder.mutation<
CommonResponseType & {data:AbilityResponse},
{id:number}
>({
    query:({id})=>({
        url:`${END_POINTS.deleteAbility}${id}/`,
        method:'DELETE'
    })
}),

importPdf:builder.mutation<CommonResponseType & {data:any},{file:any}>({
  query:({file})=>{
  const form=new FormData();
  form.append("file_link",file);
  return({
    url:END_POINTS.importAbilityPdf,
    method:"POST",
    body:form,
  })
  }
})
// getAbilityCSV: builder.query<CommonResponseType & { data: string }, {
//     search:string;
//   }>({
//     query: ({search}) => {
//       let url = END_POINTS.exportAbilitys;
//       const queryParams = [];
//       if (search) {
//         queryParams.push(`search=${search}`);
//       }
//       if (queryParams.length > 0) {
//         url += `?${queryParams.join('&')}`;
//       }
//      return{ 
//       url:url,
//       method: "GET",}
//     },
//   }),

})
})

export const{
    useAddAbilityMutation,
    useGetAbilityMutation,
    useLazyGetAbilityByIdQuery,
    // useChangeAbilityStatusMutation,
    useEditAbilityByIdMutation,
    useDeleteAbilityByIdMutation,
    useImportPdfMutation,
}=AbilityApi;
