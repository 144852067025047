import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import { useLazyGetUsersByIdQuery } from "../../../services/user";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// function CustomTabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const UserDetails = () => {
  const navigate = useNavigate();
  const{id}=useParams()||0;
  const[getDataById]=useLazyGetUsersByIdQuery();
  const[userDetailsData,setUserDetailsData]=useState<any>({});
  const getDataByIdList=async()=>{
    try{const response=await getDataById({id}).unwrap();
    if(response.status==200)
      {
       setUserDetailsData(response?.data);
       console.log(response?.data);
      }}
      catch(error){
        console.log(error);
      }
  }
  useEffect(()=>{
    if(id)
      getDataByIdList();
  },[id])
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-users");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              {/* <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="User Details" {...a11yProps(0)} />
                <Tab label="Activity History" {...a11yProps(1)} /> */}
                {/* <Tab
                  label="Active and Completed Challenges"
                  {...a11yProps(2)}
                />
                <Tab label="Posts and Stories" {...a11yProps(3)} /> */}
              {/* </Tabs> */}
            </Box>
            {/* <CustomTabPanel value={value} index={0}> */}
            <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img src={userDetailsData?.profile_picture?.media_url?userDetailsData?.profile_picture?.media_url:"/static/images/user_placeholder.png"}alt="" />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Customer Id</Typography>
                  <Typography component="p">{userDetailsData?.id}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">First Name</Typography>
                  <Typography component="p">{userDetailsData?.first_name?userDetailsData?.first_name:"-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Last Name</Typography>
                  <Typography component="p">{userDetailsData?.last_name?userDetailsData?.last_name:"-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Purpose</Typography>
                  <Typography component="p">{userDetailsData?.purpose?.name?userDetailsData?.purpose?.name:"-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p">{userDetailsData?.phone_no?(userDetailsData?.country_code+userDetailsData?.phone_no):"-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p">{userDetailsData?.email?userDetailsData?.email:"-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Account Status</Typography>
                  <Typography component="p">{userDetailsData?.is_active?"Active":"Inactive"}</Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Role</Typography>
                  <Typography component="p">Business Owner</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Organization Name</Typography>
                  <Typography component="p">Apptunix</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    Position within the Company
                  </Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Date of Birth</Typography>
                  <Typography component="p">25/12/1999</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Business Email</Typography>
                  <Typography component="p">abdul@yopmail.com</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Website</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Tax Number</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Address</Typography>
                  <Typography component="p">Mohali,Punjab</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Zip code</Typography>
                  <Typography component="p">116059</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Credit Score</Typography>
                  <Typography component="p">500</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography component="h5">Bio</Typography>
                  <Typography component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
            {/* </CustomTabPanel> */}
             
          </Box>
        </Card>
      </div>
    </>
  );
};

export default UserDetails;
