import { styled } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import LineChart from "../../components/LineChart";
import PaidIcon from "@mui/icons-material/Paid";
import { useNavigate } from "react-router-dom";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import MainContainer from "../../layout/MainContainer";
import { useLazyGetDashboardQuery, useLazyGetGraphDataQuery } from "../../services/dashboard";
import { showError } from "../../constants/toast";
import { useEffect, useRef, useState } from "react";
import { DashboardData } from "../../types/dashboard";
import Loader from "../../helpers/Loader";
import LoadingBar from "react-top-loading-bar";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const DashboardPage = () => {
const [getGraph]=useLazyGetGraphDataQuery();
const [userGraphType,setUserGraphType]=useState<number>(1);
const [revenueGraphType,setRevenueGraphType]=useState(1);
const [subscriptionGraphType,setSubscriptionGraphType]=useState(1);
const [graphData,setGraphData]=useState({
  user:{
    labels: [],
    datasets: [
      {
        label: "User",
        data: [],
        borderColor: "black",
        backgroundColor: "#8A5FF7",
        color: "#FFFFF",
      },
    ],
  },
  revenue:{
    labels: [],
    datasets: [
      {
        label: "Revenue",
        data: [],
        borderColor: "black",
        backgroundColor: "#8A5FF7",
        color: "#FFFFF",
      },
    ],
  },
  subscription:{
    labels: [],
    datasets: [
      {
        label: "Subscription",
        data: [],
        borderColor: "black",
        backgroundColor: "#8A5FF7",
        color: "#FFFFF",
      },
    ],
  },
})
const ENDPOINT_GRAPH:any={
  1:"admin/get-dashboard-user-graph-data/",
  2:"admin/get-dashboard-revenue-graph-data/",
  3:"admin/get-dashboard-subscription-graph-data/"
}
const generateFormat=(data:any,labelName:string)=>{
      const keys=Object.keys(data)
      const values=Object.values(data)
      return {
        labels: keys,
        datasets: [
          {
            label: labelName,
            data: values,
            borderColor: "black",
            backgroundColor: "#8A5FF7",
            color: "#FFFFF",
          },
        ],
      };
}
const getUserGraphData=async(endpoint:number,interval:number)=>{
  try{
  const response=await getGraph({endpoint:`${ENDPOINT_GRAPH[endpoint]}?interval=${interval}`}).unwrap();
  if(response.status==200)
  {
    console.log(response?.data);
    if(endpoint==1)
    {
      setGraphData((prevData:any)=>({...prevData,user:generateFormat(response?.data,"User")}))
    }
    else if(endpoint==2)
    {
      setGraphData((prevData:any)=>({...prevData,revenue:generateFormat(response?.data,"Revenue")}))

    }
    else
    {
      setGraphData((prevData:any)=>({...prevData,subscription:generateFormat(response?.data,"Subscription")}))

    }
  }
  }
  catch(error:any)
  {
    showError(error?.data?.message||"Graph data not found")
  }
}
useEffect(()=>{
getUserGraphData(1,userGraphType)
},[userGraphType])

useEffect(()=>{
  getUserGraphData(2,revenueGraphType)

},[revenueGraphType])
  const graphOrderData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Users",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "black",
        backgroundColor: "#8A5FF7",
        color: "#FFFFF", 
      },
    ],
  };
  const graphRevenueData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Revenue",
        data: [
          "10000",
          "20000",
          "25000",
          "15000",
          "50000",
          "10000",
          "20000",
          "25000",
          "15000",
          "50000",
          "20000",
          "25000",
        ],
        borderColor: "black",
        backgroundColor: "#8A5FF7",
      },
    ],
  };
  // const graphDownloadsData = {
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   datasets: [
  //     {
  //       label: "Downloads",
  //       data: [
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "10",
  //         "20",
  //         "25",
  //         "15",
  //         "50",
  //         "20",
  //         "25",
  //       ],
  //       borderColor: "#029be0",
  //       backgroundColor: "#029be0",
  //     },
  //   ],
  // };
const[dashboardDataState,setDashboardDataState]=useState<DashboardData>({
    "total user": 0,
    "total subscription": 0,
    "total revenue": 0,
  
})
const ref:any = useRef(null)

const navigate = useNavigate();
const[isLoading,setIsLoading]=useState(false);
const [dashboardData]=useLazyGetDashboardQuery();
const getDashboardData=async()=>{
  ref?.current?.continuousStart()
setIsLoading(true);
  try{
    const response=await dashboardData({}).unwrap();
    if(response.status==200)
      {
        setDashboardDataState(response?.data);
        console.log(response?.data);
      }
  }
  catch(error:any){
    console.log(error);
    showError(error?.data?.message);
  }
setIsLoading(false);

  ref?.current?.complete();

}
useEffect(()=>{
getDashboardData();
},[])
  return (
   <>
      <div className="main_loyout">
      <LoadingBar color="#7048c1" ref={ref} shadow={true}  />
<Loader isLoad={isLoading}/>
        <div className="dashboard">
          <h1 className="mn_hdng">Dashboard</h1>
        </div>
  
        <Grid container spacing={2} className="dashGrid" style={{justifyContent:"space-between"}}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Item
              className="cards dashGrid_item"
              onClick={() => navigate("/manage-users")}
            >
              <PeopleIcon className="svg_icn" />
              <div>
                <h3>Total Users</h3>
                <h4 className="mn_hdng">{dashboardDataState?.["total user"]}</h4>
              </div>
            </Item>
          </Grid>
  
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Item
              className="cards dashGrid_item"
              onClick={() => navigate("/manage-subscription")}
            >
              <SubscriptionsIcon className="svg_icn" />
              <div>
                <h3>Total Subscriptions</h3>
                <h4 className="mn_hdng">{dashboardDataState?.["total subscription"]}</h4>
              </div>
            </Item>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Item
              className="cards dashGrid_item"
              onClick={() => navigate("/payment")}
            >
              <PaidIcon className="svg_icn" />
              <div>
                <h3>Total Revenue</h3>
                <h4 className="mn_hdng">{dashboardDataState?.["total revenue"]}&#36;</h4>
              </div>
            </Item>
          </Grid>
          
 
        </Grid>
        
  
        <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Item className="cards dashGraph_item">
              <h2 className="mn_hdng">Total Users</h2>
              <LineChart data={graphData?.user} />
            </Item>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Item className="cards dashGraph_item">
              <h2 className="mn_hdng">Total Revenue</h2>
              <LineChart data={graphData?.revenue} />
            </Item>
          </Grid>
          {/* <Grid item lg={6} md={6} sm={6} xs={12}>
            <Item className="cards dashGraph_item">
              <h2 className="mn_hdng">Total Downloads</h2>
              <LineChart data={graphDownloadsData} />
            </Item>
          </Grid> */}
        </Grid>
      </div>
   </>
  );
};

export default DashboardPage