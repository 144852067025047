import { Button, Card, Box, CardContent, Grid, Typography, CardMedia, Input, TextField, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { error } from 'console';
import React, { useEffect, useRef, useState } from 'react'
import EditText from '../../components/EditText';
import { showError, showToast } from '../../constants/toast';
import { useNavigate } from 'react-router-dom';
import { CameraAlt, Cancel, Delete } from '@mui/icons-material';
import { useAddSubjectsMutation, useDeleteSubjectByIdMutation, useEditSubjectByIdMutation, useGetSubjectsMutation } from '../../services/subject';
import { CommonBody } from '../../types/General';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import { Subject, SubjectData } from '../../types/subject';
import { Pagination } from '../../components';
import WarnModal from '../../components/modals/WarnModal';
import { handleDelete } from '../../utils/commonFunctions';
import LoadingBar from 'react-top-loading-bar';
import Loader from '../../helpers/Loader';
import { isEnglishString } from '../../utils/validations';

const AddSubject = () => {
  const navigate=useNavigate();
  const[image,setImage]=useState("");
  const[error,setError]=useState("");
  const [getSubject] = useGetSubjectsMutation();
  const [updateSubjectStatus]:any = useEditSubjectByIdMutation();
  const [deleteById] = useDeleteSubjectByIdMutation();
  const[addSubject]=useAddSubjectsMutation();
  const[isLoading,setIsLoading]=useState(false);
  const[subject,setSubject]=useState<SubjectData>([]);
  const[deleteOpen,setDeleteOpen]=useState(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const[subjectName,setSubjectName]=useState("");
  const ref:any = useRef(null)

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const getSubjectList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const body={
        start:page,
        length:10,
        search:debouncedSearchTerm.trim(),
      }
      console.log(body);
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getSubject(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response);
        setSubject(response?.data?.response_object || []);
        setTotalCount(response?.data?.total_records||1)
      } else {
        setSubject([]);
      }
    } catch (error: any) {
      console.log(error);
      
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };

 const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        is_active:!subject[index]?.is_active
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateSubjectStatus({ id: `${subject[index].id}` , body:encryptedData}).unwrap();
      // showToast(response?.message || "User Updated Successfully");
      showToast(subject[index]?.is_active?"Subject disabled successfully":"Subject enabled successfully");
      setSubject(prevData=>{
        let temp=[...prevData];
        temp[index]={...temp[index],is_active:!temp[index].is_active};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }

  const addSubjectData=async(e:any)=>{
    e.preventDefault();
    if(!subjectName)
      {
        showError("Subject name is required")
        return;
      }
    try{
      const body={
        subject_name:subjectName
      }
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response=await addSubject(encryptedBody).unwrap();
      if(response.status==200)
        {
          showToast("Subject added successfully");
          setSubjectName("");
          getSubjectList();
        console.log(response);
        }
      }
      catch(error:any){
        showError(error?.data?.message)
        // console.log("MOHIT",error);
      
      }
  }
 useEffect(() => {
//   console.log("TESTING")
    getSubjectList();
  }, [page]);
  const subjectData=[
    "SCIENCE",
    "ENGLISH",
    "MATHS"
  ];
  return (
    <>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Subject</h1>
            <Loader isLoad={isLoading}/>
            <LoadingBar color="#7048c1" ref={ref} shadow={true}  />

            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-achievement");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
             
                <form onSubmit={addSubjectData}>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <Cancel
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            // onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAlt />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid> */}
                </Grid>
                      
                <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Subject Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="category"
                          variant="outlined"
                          fullWidth
                          placeholder="Add Subject"
                          value={subjectName}
                          onChange={
                            (e)=>{
                      
                              if( e.target.value==" ") return;
                              if(!isEnglishString(e.target.value))
                                {
                                  return
                                }
                              if(e.target.value.length<=50)
                                setSubjectName(e.target.value)

                            else
                            showError("Subject name can't exceed 50 characters")
                            }}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid item lg={4} md={4} sm={4} xs={12} sx={{marginTop:"15px"}}>
                        <Typography className="custom_label">
                          Subject Details
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="category"
                          variant="outlined"
                          fullWidth
                          placeholder="Subject Details"
                          // value={method}
                          // onChange={(e)=>{
                          //   if(e.target.value.length<=40)
                          //   setMethod(e.target.value)
                          //   if(e.target.value.length>40)
                          //   showError("Method name can't exceed 40 characters")
                          // }}
                        />
                      </Grid> */}
                  {/* <Grid item xs={12}>
                  <Typography className="dialog_title" style={{ paddingTop: '20px' }}>Description</Typography>
 
  <div style={{ width: '66%' }}>  
  { displayEditText && <EditText
      content={description}
      setContent={setDescription}
    />}
  </div>
</Grid> */}
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                      >
                       Add Subject
                      </Button>
                      <Grid item xs={12}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="mn_hdng">S.No.</TableCell>
                          <TableCell className="mn_hdng">Subject Name</TableCell>
                          <TableCell className="mn_hdng">Status</TableCell>
                          <TableCell className="mn_hdng">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      {subject?.length ? (
                        subject?.map((row:Subject, i:any) => (
                          <TableBody key={i}>
                            <TableRow>
                              <TableCell className="mn_hdng">{(page-1)*10+1+i}</TableCell>
                              <TableCell>{row.subject_name}</TableCell>
                              <TableCell>
                                <Switch
                                size='small'
                                  checked={row.is_active}
                                  onChange={()=>handleStatusChange(i)}

                                />
                              </TableCell>
                              <TableCell>
                              <IconButton onClick={()=>{
                            setDeleteOpen(true);
                            setSelectedId(row?.id)}}>
                          <Delete />
                        </IconButton>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      ) : (
                        <TableBody
                          sx={{
                            padding: "20px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <TableRow // sx={{ padding: "20px", textAlign: "center" }}
                          >
                            <TableCell
                              colSpan={5}
                              sx={{ padding: "20px", textAlign: "center" }}
                            >
                              No Subjects Added
                            </TableCell>{" "}
                            {/* No Roles Added */}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
                    </div>
                  </CardContent>
                </form>
            </Box>
          </Card>
          <Pagination
          module={subject}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        <WarnModal
        setOpen={setDeleteOpen}
        open={deleteOpen}
        name={"subject"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getSubjectList)}
      />
        </div>
    </>
  );
};
export default AddSubject