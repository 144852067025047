import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
// import Loader from "../../helpers/Loader";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
// import { showError, showToast } from "../../constants/toast";
// import useAuth from "../../hooks/useAuth";
// import { useLazyGetSubscriptionQuery, useEditSubscriptionByIdMutation, useDeleteSubscriptionByIdMutation } from "../../services/subscription";
// import { generateEncryptedKeyBody } from "../../utils/crypto";
// import users from "../users";
import { Pagination } from "../../components";
import { isDeleteAllowed, isEditAllowed } from "../../utils/permissonAllowed";
// import { isValidInput } from "../../utils/validations";
// import { handleDelete } from "../../utils/commonFunctions";
// import { SubscriptionResponse } from "../../types/subscription";

const ManageSubscription = () => {
  // const user = useAuth();
  const navigate = useNavigate();
//   const[isLoading,setIsLoading]=useState(false);

//   const [open, setOpen] = useState<boolean>(false);
//   const [selectedId, setSelectedId] = useState<string>("");
//   const [searchTerm, setSearchTerm] = useState<string>("");
//   const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
//   const [subscriptions, setSubscriptions] = useState<any>([]);
// //   [{
// //     _id: "23232",
// //     name: "John Doe", // Optional
// //     phone: "+1 123-456-7890",
// //     image: "https://example.com/profile_image.jpg",
// //     isBlocked: false,
// //     countryCode: "US",
// //     email: "john.doe@example.com",
// //     userName: "johndoe", // Optional
// //     address: "123 Main St, City, State, Zip"
// // }]
//   const [hidePermission, setHidePermission] = useState<
//     Permissions | null | undefined
//   >(null);

//   const [getSubscriptions] = useLazyGetSubscriptionQuery();
//   const [updateUserStatus]:any = useEditSubscriptionByIdMutation();
//   const [deleteById] = useDeleteSubscriptionByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

//   // const checkPermission = () => {
//   //   const permission = userData?.permission;
//   //   if (permission?.length) {
//   //     let idx = -1;
//   //     idx = permission?.findIndex(
//   //       (ele: Permissions) => ele?.label === "Manage Users"
//   //     );
//   //     if (idx > -1) {
//   //       setHidePermission(permission[idx]);
//   //     } else {
//   //       navigate(-1);
//   //     }
//   //   }
//   // };

//   const getSubscriptionsList = async () => {
//     setIsLoading(true);
//     try {
//       const response = await getSubscriptions({
//         page: page,
//         // limit: 10,
//         query: debouncedSearchTerm.trim(),
//       }).unwrap();
//       if (response?.statusCode === 200) {
//         setSubscriptions(response?.data?.subscription || []);
//         setTotalCount(response?.data?.count);
//       } else {
//         setSubscriptions([]);
//       }
//     } catch (error: any) {
//       showError(error?.data?.message || "");
//     }
//     setIsLoading(false);
//   };

//   const handleStatusChange=async(index:number)=>{
//     try {
//       const body = {
//         isBlocked:!subscriptions[index]?.isBlocked
//       };
//       console.log(body);
//       const encryptedData=generateEncryptedKeyBody(body);
//       const response = await updateUserStatus({ id: `${subscriptions[index]._id}` , body:encryptedData}).unwrap();
//       showToast(response?.message || "User Updated Successfully");
//       setSubscriptions((prevData:any)=>{
//         let temp=[...prevData];
//         temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
//         return temp;
//       });
//     } catch (error: any) {
//       showError(error?.data?.message || "");
// //      console.log(error);
//     }
//   }
//   useEffect(() => {
// //   console.log("TESTING")
//     getSubscriptionsList();
//   }, [debouncedSearchTerm, page]);
//   console.log(subscriptions);
//   // useEffect(() => {
//   //   if (userData && userData?.permission?.length) {
//   //     checkPermission();
//   //   }
//   // }, [userData]);





  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const rows = [
   
    createData(
      "John Doe",
      "JohnDoe",
      "+971056734321",
      "johndoe@gmail.com",
      "Yearly",
      "$29.99/yr",
      "10",
      "$299.90"
    ),
    createData(
      "Jane Smith",
      "JaneSmith",
      "+971056734321",
      "janesmith@gmail.com",
      "Monthly",
      "$2.99/mo",
      "10",
      "$49.90"
    ),
  ];

  return (

      <div className="main_loyout">
             {/* <Loader isLoad={isLoading}/> */}
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Subscription</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            {/* <SearchBar
             searchTerm={searchTerm}
             setDebouncedSearchTerm={setDebouncedSearchTerm}
             value={searchTerm}
             onCross={() => setSearchTerm("")}
             onChange={(val: any) => {
               if (isValidInput(val.target.value)) {
                 setSearchTerm(val.target.value);
               }
             }}
           /> */}
           <SearchBar
             searchTerm={""}
             setDebouncedSearchTerm={()=>{}}
             value={""}
             onCross={() => {}}
             onChange={()=>{}}
           />
           {isEditAllowed(5) &&  <Box className="cards_header_right">
              {/* {(user?.role==1)||(user?.permissions?.[5]?.isEdit)? */}
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subscription/add")}
              >
                Add Subscription
              </Button>
              {/* :null} */}
            </Box>}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Plan type</TableCell>
                  <TableCell>Price</TableCell>
                  
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length?(rows.map((row:any, i:number) => (
                  <TableRow key={row.planName}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row?.planName}</TableCell>
                    <TableCell>
                      <Typography variant="h6" fontSize={14}>
                        {row?.planPrice}
                      </Typography>
                    </TableCell>
                     
                    <TableCell>
                      <Switch {...label} 
                      defaultChecked
                      disabled={isEditAllowed(5)?false:true}
                            // checked={!row?.isBlocked} 
                            // disabled={!(user?.role==1)||(user?.role==2) && (!user?.permissions?.[5]?.isEdit)}
                            // onChange={()=>handleStatusChange(i)}
                            size="small" />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate("/manage-subscription/details/id")}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        {/* {(user?.role==1)||(user?.permissions?.[5]?.isEdit)? */}
                       {isEditAllowed(5) && <IconButton
                          onClick={() =>
                            navigate("/manage-subscription/edit/id"
                            // , {state: { type: "edit" },}
                          )
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>}
                         {/* :null} */}
                        {/* {(user?.role==1)||(user?.permissions?.[5]?.isDelete)?<IconButton
                        onClick={() => handleDelete(deleteById, row._id, getSubscriptionsList)}
                        >
                          <DeleteIcon />
                        </IconButton>:null} */}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))):<TableRow>
                <TableCell colSpan={5} sx={{textAlign:"center"}}>
                  No Subscription Plan Found</TableCell></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {/* <Pagination
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        /> */}
      </div>

  );
};

export default ManageSubscription;
