import React, { FormEvent, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Select,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { franc } from 'franc';
import { Add, Delete, ModeEdit, PictureAsPdf, PlusOne, Remove, Visibility } from "@mui/icons-material";
import { setOptions } from "react-chartjs-2/dist/utils";
import { useAddAbilityMutation, useDeleteAbilityByIdMutation, useEditAbilityByIdMutation,useGetAbilityMutation, useImportPdfMutation } from "../../services/ability";
import { showToast, showError } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Ability, AbilityData } from "../../types/ability";
import WarnModal from "../../components/modals/WarnModal";
import Pagination from "../../components/Pagination";
import { handleDelete } from "../../utils/commonFunctions";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import Loader from "../../helpers/Loader";
import LoadingBar from "react-top-loading-bar";
import { isDeleteAllowed, isEditAllowed } from "../../utils/permissonAllowed";


const ManageAbility = () => {

    const[selectedOption,setSelectedOption]=useState<any>("");
    const[viewQuestionModal,setViewQuestionModal]=useState<any>(false);
    const[editQuestionModal,setEditQuestionModal]=useState<any>(false);
    const[addQuestionDisplay,setAddQuestionDisplay]=useState<any>(false);
    const[selectedIndex,setSelectedIndex]=useState(0);
  const [value, setValue] = useState<number>(0);
  const [question, setQuestion] = useState("");
  const[option,setOption]=useState("");
  const[newOptionIndex,setNewOptionIndex]=useState<any>([]);
  const[newOptions,setNewOptions]=useState<any>([]);
  const[newAnswer,setNewAnswer]=useState("");
  const[newQuestion,setNewQuestion]=useState("");

  const[addData]=useAddAbilityMutation();
  const[getAllData]=useGetAbilityMutation();
  const[deleteData]=useDeleteAbilityByIdMutation();
  const[updateData]=useEditAbilityByIdMutation();

  const[isLoading,setIsLoading]=useState(false)
 const[deleteOpen,setDeleteOpen]=useState(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [is_arabic,setis_arabic]=useState(false);
  const ref:any = useRef(null)

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const[tempQuestion,setTempQuestion]=useState<Ability>({
    answer_option:[],
    corect_answer:"",
    is_active:false,
    is_mcq:false,
    question:"",
    id:0,
    created_at:"",
    is_arabic:false
  })
  useEffect(()=>{
    setNewAnswer("");
    setSelectedOption("")
  },[newOptions])
  const [questions,setQuestions]=useState<any>([
    {
        question:"Question 1",
        option:[
            "Option 1",
            "Option 2",
            "Option 3",
        ],
        answer:'Option 3'
    },
    {
        question:"Question 2",
        option:[
            "Option 1",
            "Option 2",
        ],
        answer:'Option 2'
    },
  ]);
  useEffect(()=>{
resetQuestionAddSection();
  },[addQuestionDisplay])
  const addUpdateNewAbility=async(id:number)=>{
    const optionObject:any={};
    let flag=0;
    if(!id){
      for(let key of newOptions)
    {
      optionObject[`${key.trim()}`]=optionObject[`${key.trim()}`]+1||1
      if(optionObject[`${key.trim()}`]>1) flag=1;
    }}
    else{
    for(let key of tempQuestion?.answer_option)
      {
        optionObject[`${key.trim()}`]=optionObject[`${key.trim()}`]+1||1
        if(optionObject[`${key.trim()}`]>1) flag=1;
      }}
    if(flag)
    {
      showError("Options should be unique")
      return;
    }
    try{
        const body={
            question:id?tempQuestion?.question:newQuestion,
            answer_option:id?tempQuestion?.answer_option?.filter((data:string)=>data?.trim()?.length):newOptions?.filter((data:string)=>data?.trim()?.length),
            corect_answer:id?tempQuestion?.corect_answer:newAnswer||selectedOption,
            is_mcq:Boolean(id?tempQuestion?.answer_option?.length:newOptions?.length),
            is_arabic:id?tempQuestion?.is_arabic:is_arabic,
        }
       if(id)
       {
        if(tempQuestion?.question?.trim()=="")
          {
            showError("Question is required")
            return ;
          }
          if(tempQuestion?.corect_answer?.trim()=="")
            {
              showError("Answer is required")
              return
            }
       }
       else
       {
        if(newQuestion?.trim()=="")
          {
            showError("Question is required")
            return ;
          }
          if(newAnswer?.trim()=="" && selectedOption?.trim()=="")
            {
              showError("Answer is required")
              return
            }
       }

        console.log("setAbility BODY",body);
        const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
        const response=id?(await updateData({id,body:encryptedBody}).unwrap()):(await addData(encryptedBody).unwrap());
        if(response.status==201||response.status==200)
          {
            if(id)
                {
                    setEditQuestionModal(false);
                    setAbility(prev=>(prev.map((data:Ability,index:number)=>{
                        if(index==selectedIndex)
                            {
                                return tempQuestion;
                            }
                            return data;
                    })))
                }
                else
                {
                  setAddQuestionDisplay(false);
                  getAbilityList();
                                setNewQuestion("");
                                setNewAnswer("");
                                setOption("");
                                setNewOptions([])
                                setSelectedOption("");
                }
            showToast("Ability "+ (id?"updated":"added") +" successfully");
          console.log(response);
          }
        }
        catch(error:any){
          showError(error?.data?.message)
          console.log(error);
        
        }
  }
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        is_active:!ability[index]?.is_active
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateData({ id: ability[index].id , body:encryptedData}).unwrap();
      // showToast(response?.message || "User Updated Successfully");
      showToast(ability[index]?.is_active?"Ability disabled successfully ":"Ability enabled successfully ");
      setAbility(prevData=>{
        let temp=[...prevData];
        temp[index]={...temp[index],is_active:!temp[index].is_active};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  const[ability,setAbility]=useState<AbilityData>([]);
  const getAbilityList = async () => {
    setIsLoading(true);
    ref?.current?.continuousStart()

    try {
      const body={
        start:page,
        length:10,
        search:debouncedSearchTerm.trim(),
      }
      console.log(body);
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response = await getAllData(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response);
        setAbility(response?.data?.response_object || []);
        setTotalCount(response?.data?.total_records);
      } else {
        setAbility([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
    ref?.current?.complete();

  };
  useEffect(()=>{
    getAbilityList();
  },[debouncedSearchTerm,page])
  useEffect(()=>{
    const temp=[];
    for(let key of questions)
    {
        temp.push("");        
    }
setNewOptionIndex(temp);
  },[questions])
//   const [faqs, setFaqs] = useState<any>([]);
  const [hidePermission, setHidePermission] = useState({
      is_add_edit: true,
      is_view: true,
      is_delete: true,
  });
const[newDialogOption,setDialogOption]=useState("");
  useEffect(() => {
      // Fetch FAQs or perform any necessary initializations
  }, []);

  const addQuestion = () => {
      // Filter out empty options before adding to FAQs
    //   const nonEmptyOptions = options.filter(option => option !== "");
    //   const newFaqs = [...faqs, { question, options: nonEmptyOptions }];
    //   setFaqs(newFaqs);
    //   setQuestion("");
    //   setOptions([""]);
  };
  
  const handleAddOption = (index: number) => {
    //   const newOptions = [...options];
    //   newOptions.splice(index + 1, 0, "");
    //   setOptions(newOptions);
  };

  const handleRemoveOption = (index: number) => {
    //   const newOptions = [...options];
    //   newOptions.splice(index, 1);
    //   setOptions(newOptions);
  };

  const handleUpdateQuestion = (index: number) => {
    const updatedFaqs = questions.filter((item:any, i:any) => i !== index);
    setQuestions(updatedFaqs);
};
  const handleRemoveQuestion = (index: number) => {
      const updatedFaqs = questions.filter((item:any, i:any) => i !== index);
      setQuestions(updatedFaqs);
  };

  const handleQuestionChange = (value: string) => {
      setQuestion(value);
  };

  function resetQuestionAddSection(){
    setNewQuestion("");
    setOption("");
    setNewOptions([])
    setSelectedOption("");
  }
const detectLanguage = (text:string) => {
    // Detect the language code using franc
    const langCode = franc(text);
    return langCode;
  };

  const [uploadPdf]=useImportPdfMutation();
  const handleFileUpload=async(e: React.ChangeEvent<HTMLInputElement>)=>{
    const files=e.target.files;
    e.target.files=null;
    const file=files?.length?files[0]:null;
    if(!file)
    {
      showError("Please upload file again")
      return;
    }
    const allowedExtensions = ["pdf"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    console.log("sssssssssssssssssssss",fileExtension);
    if (!allowedExtensions.includes(fileExtension||"")) {
       setIsLoading(false);
      showError("Invalid file format: only pdf is allowed");
      return;
    }
    setIsLoading(true)

    try{
    const response:any=await uploadPdf({file}).unwrap();

      const lang=detectLanguage(response?.data?.[0]?.question);
      const isArabic=!(lang.trim().toLowerCase()=="eng")
    if(response?.status==200)
    {
      Array.isArray(response?.data) && response?.data?.map(async (data:any)=>{
        const body={
          question:data?.question,
          answer_option:data?.answer_option?.length>1?data?.answer_option:[],
          corect_answer:data?.answer_option?.length>1?data?.correct_answer?data?.correct_answer:"":data?.answer_option?.[0],
          is_mcq:Boolean(data?.answer_option?.length>1),
          is_arabic:isArabic,
      }
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response:any=await addData(encryptedBody).unwrap();
      if(response.status==201||response.status==200)
        {
        console.log(response);
        }

      })
      showToast("Abilities added successfully");
      console.log(response);
    }}
    catch(error:any)
    {
      showError(error?.data?.message||"File upload failed")
    }
      setTimeout(()=>{

        getAbilityList();
        setIsLoading(false);

      },1000)
    // if(response?.sta)
  }
  const handleOptionChange = (index: number, value: string) => {
    //   const newOptions = [...options];
    //   newOptions[index] = value;
    //   setOptions(newOptions);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
      <div className="main_loyout">
          <Loader isLoad={isLoading}/>
          <LoadingBar color="#7048c1" ref={ref} shadow={true}  />

        <div className="dashboard">
          <h1 className="mn_hdng">Manage Abilities</h1>
        </div>
        <div>
          <Card className="cards">
          <Box className="cards_header" marginBottom={"10px"}>
                <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
           {isEditAllowed(3) &&  <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={()=>setAddQuestionDisplay(!addQuestionDisplay)}>
                {addQuestionDisplay?<Remove/>:<Add />} {addQuestionDisplay?"Remove Question":"Add Question"}
              </Button>
              <input type="file" 
              style={{display:"none"}} 
              id="pdf"
              accept="application/pdf"
              onChange={(e)=>{
                handleFileUpload(e)
                e.target.value="";
              }}
            
              />
          <label htmlFor="pdf">
              <Button 
              className="btn btn_primary"     
              component="span" //add span is compulsory when using other component in file upload
              startIcon={ <PictureAsPdf />}
              >
                  Import Questions
                </Button>
  
          </label>
            </Box>}
          </Box>


            
           
           {addQuestionDisplay && <Grid item xs={12}>
                <Box className="faq_box">
            
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                        <Typography className="custom_label">Question</Typography>
                        <TextField
                            hiddenLabel
                            type="text"
                            name="name"
                            variant="outlined"
                            value={newQuestion}
                            onChange={(e) => {
                              if(e.target.value==" ") return;
                              if(e.target.value.length<=150)
                              setNewQuestion(e.target.value)
                              if(e.target.value.length>150)
                              showError("Question length can't exceed 150 characters")
                            
                            
                            }}
                            placeholder="Question"
                        />
                        
                        {newOptions.map((option:any, index:number) => (
                            <Box key={index} sx={{ display: "flex" }}>
                                <TextField
                                    hiddenLabel
                                    type="text"
                                    name="option"
                                    variant="outlined"
                                    fullWidth
                                    value={option}
                                    onChange={(e)=>{
                                   
                                      if(e.target.value==" ") return;
                                      if(e.target.value.length<=100)
                                        setNewOptions((prevData:any)=>{
                                          return prevData.map((data:any,indexx:number)=>{
                                              if(index==indexx)
                                                  {
                                                      return e.target.value
                                                  }
                                                  return data;
                                          })
                                      })
                                        if(e.target.value.length>100)
                                        showError("Option length can't exceed 100 characters")
                                      
                                      
                                       
                                    }}
                                    placeholder={`Option ${index + 1}`}
                                    sx={{ mt: 1, width: "100%" }}
                                />
                
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setNewOptions((prevData:any)=>{
                                                return prevData.filter((_:any,id:number)=>id!=index)
                                            })
                                        }}
                                        style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    >
                                        <Remove />
                                    </Button>
                          
                             
                            </Box>
                        ))}
                      { newOptions?.length<10 && <Box sx={{ display: "flex" }}>
                                <TextField
                                    hiddenLabel
                                    type="text"
                                    name="option"
                                    variant="outlined"
                                    value={option}
                                    onChange={(e) => {


                                      // if(!e.target.value?.trim()?.length)
                                      if(newAnswer)
                                      setNewAnswer("");
                                      if(e.target.value==" ") return;
                                      if(e.target.value.length<=100)
                                        setOption(e.target.value)
                                        if(e.target.value.length>100)
                                        showError("Option length can't exceed 100 characters")
                                      
                                      
                                      
                                      }}
                                    placeholder={`Option ${newOptions?.length+1}`}
                                    sx={{ mt: 1, width: "100%" }}
                                />
                                <Button
                                        variant="contained"
                                        disabled={(!option.trim())||((newOptions?.some((data:string)=>data.trim()==option.trim())))}
                                        onClick={() => {
                                            setNewOptions((prevData:any)=>{
                                                return [...prevData,option.trim()]
                                            })
                                            setOption("");
                                        }}
                                        style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    >
                                        <Add />
                                    </Button>
                             
                            </Box>}
                    </FormControl>
                          { newOptions?.length ?
                          
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography className="custom_label">
                            Choose Answer
                          </Typography>
                          <FormControl sx={{ width: "100%",marginBottom:"10px" }}>
                            <Select
                                sx={{
                                  backgroundColor:"white"
                                }}
                              fullWidth
                              // className="select_div"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedOption||""}
                              // multiple
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              displayEmpty
                              onChange={(e)=>setSelectedOption(e.target.value)}
                            >
                              <MenuItem value="" disabled>
                                Select
                              </MenuItem>
                              {newOptions?.filter((data:string)=>data?.trim()?.length)?.map((data:any)=>{
                                 return <MenuItem value={data}>{data?.length>30?data?.slice(0,30)+"...":data}</MenuItem>

                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                          :
                       <FormControl sx={{ mb: 2, width: "100%" }}>
                              <Typography className="custom_label">Answer</Typography>
                            <TextField
                                hiddenLabel
                                type="text"
                                name="name"
                                variant="outlined"
                                value={newAnswer}
                                onChange={(e) =>{ 
                                  if(e.target.value==" ") return;
                                  if(e.target.value.length<=150)
                                    setNewAnswer(e.target.value)
                                    if(e.target.value.length>150)
                                    showError("Answer length can't exceed 150 characters")
                                
                                }}
                                placeholder="Answer"
                            />
                         </FormControl>

                        }
                         <Typography className="custom_label"style={{display:"flex",alignItems:"center"}}>
                <div>English</div>
                  <div className="checkbox-wrapper-25">
                    <input type="checkbox" checked={is_arabic} onClick={()=>setis_arabic(!is_arabic)}/>
                  </div>
                <div>Arabic</div>

             </Typography>
                    <Box className="faq_btn">
                        <Button
                            variant="contained"
                            disabled={!newQuestion?.trim()}
                            onClick={(e)=>{
                                addUpdateNewAbility(0);
                                setQuestions((prevData:any)=>{
                                 return  [...prevData,{question:newQuestion,option:newOptions,answer:newAnswer}]
                                })
                                
                            }}
                        >
                            Add Question
                        </Button>
                    </Box>
                </Box>
            </Grid>}
            {/* {questions.map((item:any, index:any) => (
                <Grid key={index} item xs={12}>
                    <Box className="faq_box">
                        <FormControl sx={{ mb: 2, width: "100%" }}>
                            <Typography className="custom_label">Question</Typography>
                            <TextField
                                hiddenLabel
                                type="text"
                                name="name"
                                variant="outlined"
                                value={item.question}
                                placeholder="Question"
                                onChange={(e)=>{
                                    setQuestions((prevData:any)=>{
                                        return prevData.map((data:any,indexx:number)=>{
                                           if(indexx==index)
                                           {
                                               return {...data,question:e.target.value}
                                           }
                                           return data;
                                        })
                                       });
                                }}
                            />
                          
                            {questions?.[index]?.option.map((item:any, i:any) => (
                            <Box key={i} sx={{ display: "flex" }}>
                                 
                          
                                <TextField
                                    hiddenLabel
                                    type="text"
                                    name="option"
                                    variant="outlined"
                                    value={item}
                                    onChange={(e) => {
                                      setQuestions((prevData:any)=>{
                                        return prevData.map((data:any,indexx:number)=>{
                                            if(indexx==index)
                                            {
                                                return {...data,option:data.option.map((optionData:any,optionIndex:number)=>{
                                                    if(optionIndex==i)
                                                        {
                                                            return e.target.value;
                                                        }
                                                    return optionData;
                                                })}
                                            }
                                            return data;
                                        })
                                      })
                                    }}
                                    placeholder={`Option ${i + 1}`}
                                    sx={{ mt: 1, width: "65%" }}
                                />
                            
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setQuestions((prevdata:any)=>{
                                                return prevdata.map((data:any,indexx:number)=>{
                                                    if(index==indexx)
                                                    {
                                                        return {...data,option:data.option.filter((_:any,id:number)=>id!=i)}
                                                    }
                                                    return data;
                                                })
                                            })
                                        }}
                                        style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    >
                                        <Remove />
                                    </Button>
                                
                              
                            </Box>
                        ))}
                          <Box key={index} sx={{ display: "flex" }}>
                             <TextField
                                    hiddenLabel
                                    type="text"
                                    name="option"
                                    variant="outlined"
                                    value={newOptionIndex?.[index]}
                                    onChange={(e) => setNewOptionIndex((prevData:any)=>{
                                        return prevData.map((data:any,indexx:number)=>{
                                            if(index===indexx)
                                            {
                                                return e.target.value;
                                            }
                                            return data;
                                        })
                                    })}
                                    placeholder={`Option ${questions?.[index]?.option?.length+1}`}
                                    sx={{ mt: 1, width: "65%" }}
                                />
                                  
                                    <Button
                                        variant="contained"
                                        onClick={() => setQuestions((prevData:any)=>{
                                            return prevData.map((data:any,indexx:any)=>{
                                                if(index==indexx)
                                                {
                                                    return {...data,option:[...data.option,newOptionIndex[indexx]]}
                                                }
                                                return data;
                                            })
                                        })}
                                        style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    >
                                        <Add />
                                    </Button>
                                    </Box>
                        </FormControl>
                        <Box className="faq_btn">
                           
                        </Box>
                        <Box className="faq_btn">
                        <Button
                                sx={{marginInline:"5px"}}
                                variant="contained"
                                // onClick={() => handleRemoveQuestion(index)}
                            >
                                Update Question
                            </Button>
                            <Button
                                sx={{marginInline:"5px"}}
                                variant="contained"
                                onClick={() => handleUpdateQuestion(index)}
                            >
                                Remove Question
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            ))} */}
        <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  {/* <TableCell>Picture</TableCell> */}
                  {/* <TableCell>Customer Id</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Phone No.</TableCell>
                  <TableCell>Email</TableCell> */}
                  <TableCell>Question</TableCell>
                  <TableCell>Is MCQ ?</TableCell>
                  <TableCell>Status</TableCell>

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ability?.length ? ability.map((row:Ability, i:number) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                        {/* {(page-1)*10+i + 1} */}
                        {(page-1)*10+1+i}
                        </TableCell>
                    {/* <TableCell><img style={{width:"70px",height:"70px",borderRadius:"50%"}} src={row.picture}/></TableCell> */}

                    {/* <TableCell>{row.customerId}</TableCell>
                    <TableCell>{row.firstname}</TableCell>
                    <TableCell>{row.lastname}</TableCell>

                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell> */}
                    <TableCell>{row.question}</TableCell>
                    <TableCell>{row.answer_option?.length?"YES":"NO"}</TableCell>

                    <TableCell>
                      <Switch 
                      checked={row.is_active} 
                    // defaultChecked
                      size="small" 
                      disabled={isEditAllowed(3) ?false:true}
                      onChange={()=>handleStatusChange(i)}
                      />
                    </TableCell>

                    <TableCell>
                      <Box className="table_actions">
                        <Tooltip title="View">
                          <IconButton onClick={()=>{
                              setSelectedIndex(i);
                              setViewQuestionModal(true);
                          }}>                          
                              <Visibility />
                          </IconButton>
                        </Tooltip>
                      {isEditAllowed(3) &&  
                     <Tooltip title="Edit">
                        <IconButton onClick={()=>{
                              setSelectedIndex(i);
                              setDialogOption("")
                              if(row?.answer_option)
                              setTempQuestion(row);
                              else
                              setTempQuestion({...row,answer_option:[]});
  
                              setEditQuestionModal(true);
                          }}
                          >
                            <ModeEdit />
                          </IconButton>
                     </Tooltip>
                        }
                       {isDeleteAllowed(3) && 
                     <Tooltip title="Delete">
                         <IconButton onClick={()=>{
                              setDeleteOpen(true);
                              setSelectedId(row?.id)}}>
                            <Delete />
                          </IconButton>
                     </Tooltip>
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                )):<TableRow>
                <TableCell align={"center"}colSpan={7}>
                  No Ability Found
                  </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          </Card>
          <Pagination
          module={ability}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        </div>
        <Dialog open={viewQuestionModal} onClose={()=>{setViewQuestionModal(false)}}>
                <DialogContent sx={{margin:"10px",minWidth:"530px"}}>
                     <Typography className="custom_label">Created At : {ability?.[selectedIndex]?.created_at?.slice(0,10)}</Typography>
                     <Typography className="custom_label">Status :{ability?.[selectedIndex]?.is_active ?"Active":"Inactive"}</Typography>
                    <Typography className="custom_label" style={{fontSize:"20px"}}>Question Details : </Typography>
                    <Typography className="custom_label">Question </Typography>
                    <Typography className="custom_label" border={"1px solid black"} paddingBlock={"10px"} paddingLeft={"5px"}>{ability?.[selectedIndex]?.question} </Typography>
                    {ability?.[selectedIndex]?.answer_option?.length ? <Typography className="custom_label">Options</Typography>:null}
                    {ability?.[selectedIndex]?.answer_option?.length ? ability?.[selectedIndex]?.answer_option?.map((data:any,index:number)=>{
                       return  <Typography className="custom_label" border={"1px solid black"} paddingBlock={"10px"} paddingInline={"5px"}>{"Option no "+(index+1)+" : "+data}</Typography>
                    }):null}
                    <Typography className="custom_label">Answer</Typography>
                    <Typography className="custom_label" border={"1px solid black"} paddingBlock={"10px"} paddingLeft={"5px"}>{ability?.[selectedIndex]?.corect_answer}</Typography>

                </DialogContent>
        </Dialog>

        <Dialog open={editQuestionModal} onClose={()=>{setEditQuestionModal(false)}}>
                <DialogContent sx={{margin:"10px",minWidth:"530px"}}>
                <Grid  item xs={12}>
                    <Box className="faq_box">
                        <FormControl sx={{ mb: 2, width: "100%" }}>
                            <Typography className="custom_label">Question</Typography>
                            <TextField
                                hiddenLabel
                                type="text"
                                name="name"
                                variant="outlined"
                                value={tempQuestion?.question}
                                placeholder="Question"
                                onChange={(e)=>{
                                  if(e.target.value==" ") return;
                                  if(e.target.value.length<=150)
                                    setTempQuestion((prevData:Ability)=>{
                                      return {...prevData,question:e.target.value}
                                  })
                                    if(e.target.value.length>150)
                                    showError("Question length can't exceed 150 characters")
                                  
                                  
                                    
                                }}
                            />
                          
                            {Array.isArray(tempQuestion?.answer_option) && tempQuestion?.answer_option.map((item:any, i:number) => (
                            <Box key={i} sx={{ display: "flex" }}>
                                 
                          
                                <TextField
                                    hiddenLabel
                                    type="text"
                                    name="option"
                                    variant="outlined"
                                    value={item}
                                    onChange={(e) => {
                                      
                                      // if(!e.target.value?.trim()?.length)
                                      if(tempQuestion?.corect_answer)
                                        setTempQuestion((prevData:any)=>({...prevData,corect_answer:""}))
                                      if(e.target.value==" ") return;
                                      if(e.target.value.length<=100)
                                        setTempQuestion(prevData=>(
                                          {...prevData,
                                              answer_option:prevData?.answer_option?.map((optionData:string,optionIndex:number)=>{
                                          if(optionIndex==i)
                                              {
                                                  return e.target.value;
                                              }
                                              return optionData
                                      })
                                  }))
                                        if(e.target.value.length>100)
                                        showError("Option length can't exceed 100 characters")
                                      
                                      
                                      
                                    //   setQuestions((prevData:any)=>{
                                    //     return prevData.map((data:any,indexx:number)=>{
                                    //         if(indexx==selectedIndex)
                                    //         {
                                    //             return {...data,option:data.option.map((optionData:any,optionIndex:number)=>{
                                    //                 if(optionIndex==i)
                                    //                     {
                                    //                         return e.target.value;
                                    //                     }
                                    //                 return optionData;
                                    //             })}
                                    //         }
                                    //         return data;
                                    //     })
                                    //   })
                                    }}
                                    placeholder={`Option ${i + 1}`}
                                    sx={{ mt: 1, width: "100%" }}
                                />
                            
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setTempQuestion((prevdata:Ability)=>{
                                                        return {...prevdata,corect_answer:"",answer_option:prevdata.answer_option.filter((_:any,id:number)=>id!=i)}
                                               
                                            })
                                            
                                        }
                                      
                                      }
                                        style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    >
                                        <Remove />
                                    </Button>
                                
                              
                            </Box>
                        ))}
                        {tempQuestion?.answer_option?.length<10 &&  <Box  sx={{ display: "flex" }}>
                             <TextField
                                    hiddenLabel
                                    type="text"
                                    name="option"
                                    variant="outlined"
                                    value={newDialogOption}
                                    onChange={(e) =>{
                                      if(e.target.value==" ") return;
                                      if(e.target.value.length<=150)
                                        setDialogOption(e.target.value)
                                        if(e.target.value.length>150)
                                        showError("Option length can't exceed 100 characters")
                                      
                                      
                                    
                                    
                                    }}
                                    placeholder={`Option ${tempQuestion?.answer_option?.length+1}`}
                                    sx={{ mt: 1, width: "100%" }}
                                />
                                  
                                    <Button
                                        variant="contained"
                                        disabled={(!newDialogOption.trim())||((tempQuestion?.answer_option?.some((data:string)=>data.trim()==newDialogOption.trim())))}
                                        onClick={() => {
                                            const temp=newDialogOption;
                                            setTempQuestion((prevData:Ability)=>{
                                                    return {...prevData,answer_option:[...prevData.answer_option,temp]}
                                        })
                                        setDialogOption("");
                                        setTempQuestion(prevData=>{
                                          return {...prevData,
                                            corect_answer:""
                                          }
                                        })
                                    }}
                                        style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    >
                                        <Add />
                                    </Button>
                                    </Box>}
                        </FormControl>
                        {tempQuestion?.answer_option?.length
                        ?
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Choose Answer
                        </Typography>
                        <FormControl sx={{ width: "100%",marginBottom:"10px" }}>
                          <Select
                              sx={{
                                backgroundColor:"white"
                              }}
                            fullWidth
                            // className="select_div"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={tempQuestion?.corect_answer||""}
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            // defaultValue=""
                            onChange={(e)=> setTempQuestion(prevData=>({...prevData,corect_answer:e.target.value}))} 
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            {tempQuestion?.answer_option?.filter((data:string)=>data?.trim()?.length)?.map((data:any)=>{
                               return <MenuItem value={data}>{data?.length>30?data.slice(0,30)+"...":data}</MenuItem>

                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                        :
                        <FormControl sx={{ mb: 2, width: "100%" }}>
                            <Typography className="custom_label">Answer</Typography>
                            <TextField
                                hiddenLabel
                                type="text"
                                name="name"
                                variant="outlined"
                                value={tempQuestion?.corect_answer}
                                placeholder="Answer"
                                onChange={(e)=>{
                                  
                                  if(e.target.value==" ") return;
                                  if(e.target.value.length<=150)
                                    setTempQuestion((prev:Ability)=>{
                                      return {
                                          ...prev,
                                          corect_answer:e.target.value
                                      }
                                  })
                                    if(e.target.value.length>150)
                                    showError("Answer length can't exceed 150 characters")
                                }
                              }
                            />
                        </FormControl>}
                        <Typography className="custom_label"style={{display:"flex",alignItems:"center"}}>
                <div>English</div>
                  <div className="checkbox-wrapper-25">
                    <input type="checkbox" checked={tempQuestion?.is_arabic} onClick={()=>setTempQuestion(prevData=>({...prevData,is_arabic:!tempQuestion?.is_arabic}))}/>
                  </div>
                <div>Arabic</div>

             </Typography>
                        <Box className="faq_btn">
                           
                        </Box>
                        <Box className="faq_btn">
                        <Button
                        disabled={!tempQuestion?.question?.trim()}
                                sx={{marginInline:"5px"}}
                                variant="contained"
                                onClick={() =>{ 
                                    
                                    addUpdateNewAbility(tempQuestion?.id)}}
                            >
                                Update Question
                            </Button>
                            {/* <Button
                                sx={{marginInline:"5px"}}
                                variant="contained"
                                onClick={() => }
                            >
                                Remove Question
                            </Button> */}
                        </Box>
                    </Box>
                </Grid>
                </DialogContent>
        </Dialog>
      
        <WarnModal
        setOpen={setDeleteOpen}
        open={deleteOpen}
        name={"ability"|| ""}
        handleDelete={() => handleDelete(deleteData, selectedId, getAbilityList)}
      />
      </div>
  );
};

export default ManageAbility;
