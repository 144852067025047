//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import { PurposeResponse } from "../types/purpose";
// import { PurposeResponse } from "../types/Purpose";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    status: number;
    message: string;
};

type GetAllPurposesResponse = {
    // Purpose: PurposeResponse[];
    response_object: PurposeResponse[];
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const PurposeApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
    addPurposes:builder.mutation<
CommonResponseType & {data:GetAllPurposesResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.addPurpose;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getPurposes:builder.mutation<
CommonResponseType & {data:GetAllPurposesResponse},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.getPurposes;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
getPurposeById:builder.query<CommonResponseType & {data:PurposeResponse},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.getPurposeById}${id}/`,
        method:"GET",
    })
}),

changePurposeStatus: builder.mutation<
CommonResponseType & { data: PurposeResponse },
{ id: string , body: encryptedBody}
>({
query: ({ id ,body}) => ({
  url: `${END_POINTS.changePurposeStatus}${id}/`,
  method: "PUT",
  body
}),
}),

editPurposeById: builder.mutation<
      CommonResponseType & { data: PurposeResponse },
      { id: string; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updatePurposes}${id}/`,
        method: "PUT",
        body,
      }),
    }),

deletePurposeById:builder.mutation<
CommonResponseType & {data:PurposeResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.deletePurpose}${id}/`,
        method:'DELETE'
    })
}),
// getPurposeCSV: builder.query<CommonResponseType & { data: string }, {
//     search:string;
//   }>({
//     query: ({search}) => {
//       let url = END_POINTS.exportPurposes;
//       const queryParams = [];
//       if (search) {
//         queryParams.push(`search=${search}`);
//       }
//       if (queryParams.length > 0) {
//         url += `?${queryParams.join('&')}`;
//       }
//      return{ 
//       url:url,
//       method: "GET",}
//     },
//   }),

})
})

export const{
    useAddPurposesMutation,
    useGetPurposesMutation,
    useLazyGetPurposeByIdQuery,
    useChangePurposeStatusMutation,
    useEditPurposeByIdMutation,
    useDeletePurposeByIdMutation,
    // useLazyGetPurposeCSVQuery,
}=PurposeApi;
